import { useEffect, useRef, useState } from "react";
import { connect, useSelector } from "react-redux";

// unidades
import { fetchList } from "../../../services/stores/examStore/async/fetchListThunk";
import { useAppSelector } from "../../../services/stores/hooks/useAppSelector";
import { useAppDispatch } from "../../../services/stores/hooks/useAppDispatch";
import {
  Form,
  FormControl as Input,
  InputGroup,
  Button,
  Spinner,
} from "react-bootstrap";
import { Attention } from "../../../component/base/Attention";
import { Close, PrintRounded, SaveRounded } from "@material-ui/icons";
import {
  DatePicker,
  TimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import { RiArrowGoBackLine } from "react-icons/ri";
import DefaultButton from "../../../component/base/DefaultButton";
import { save } from "../../../services/stores/layoutExamStore/async/saveThunk";
import { useNavigate, useParams } from "react-router-dom";
import {
  setLoading,
  setModel,
  setErrorMessage,
  clearState,
  setId,
  setLoadingScreen,
  setState,
} from "../../../services/stores/layoutExamStore/actions";
import { setState as setStateQuestion } from "../../../services/stores/questionStore/actions";

import { update } from "../../../services/stores/layoutExamStore/async/updateThunk";
import { fetchId } from "../../../services/stores/layoutExamStore/async/fetchIdThunk";
import { TableDrag } from "../TableDrag";
import { fetchListFiltered } from "../../../services/stores/layoutExamStore/async/fetchListFilteredThunk";
import { QuestionModalView } from "../../QuestionModalView";
import { ExamModalTag } from "../../ExamModalTag";
import { uuid } from "uuidv4";
// @ts-ignore
import * as html2pdf from "html2pdf.js";
import jsPDF from "jspdf";
import styled, { createGlobalStyle } from "styled-components";
import { ImageLoaderInput } from "../../../component/base/ImageLoaderInput";
import { API_URI, STORAGE_URL } from "../../../constants/consts";
import {
  MdArrowDownward,
  MdArrowDropDown,
  MdArrowDropUp,
  MdArrowUpward,
} from "react-icons/md";
import { fetchIdNoAuth } from "../../../services/stores/layoutExamStore/async/fetchIdNoAuthThunk";
import { setBlank } from "../../../services/stores/auth/actions";
import JoditEditor from "jodit-react";
import Arrow from "../../../assets/images/arrow.png";
import { List } from "../../../component/list/list";
import { Searchbar } from "../../../component/list/searchbar";
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import TriangleImage from "../../../assets/images/triangle.png";
// @ts-ignore
import QRCode from "qrcode";
// @ts-ignore
import seedrandom from "seedrandom";
import { fetchDefault } from "../../../services/stores/layoutExamStore/async/fetchDefaultIdThunk";
import { UserRole } from "../../../constants/enum/UserRole";

const StyleQuestion = createGlobalStyle`
   p {
  letter-spacing: 0.2px;
  margin-bottom: 0px;
   }
 `;

export function LayoutsExamsForm(props: any) {
  const {
    error_message,
    loading_page,
    class_student,
    rules_answer,
    loadings_images,
    showTextConfig,
    students,
    showAnswerConfig,
    showStudents,
    showPageConfig,
    loading_pdf,
    generate_pdf_image,
    pages,
    pages_new,
    offsetHeight,
    examSelect,
    model,
    loading_save,
    loading_screen,
    questionsArray,
    loading_input,
    disciplineSelect,
    questionContent,
    showAutoCompleteQuestion,
    loading_search,
    showModalTag,
    questions,
    search,
  } = useAppSelector((store: any) => store.layouts);
  const { data } = useAppSelector((store: any) => store.exams);
  const { blank, token } = useAppSelector((store: any) => store.auth);
  const { me } = useAppSelector((store) => store.auth);

  const dispatch = useAppDispatch();
  const exams = data;
  const [show_warning, setShowWarning] = useState(false);
  // const [loading_screen, setLoadingScreen] = useState(false);
  const timeout_: any = useRef();
  const timeout_search: any = useRef();
  const editor_rules_answer = useRef<any>();

  const [edit, setEdit] = useState(false);

  const inverval_load: any = useRef<any>();
  const timeout: any = useRef<any>();

  const history = useNavigate();
  const params = useParams();

  const saveContent = () => {
    if (props.justView) {
      return undefined;
    }
    let model_: any = { ...model };
    model_.rules_answer = rules_answer;
    if(edit==false){
      model_.application_id = params.test_id;
    }
    model_.questions = questionsArray.map((item: any, id: any) => item._id);
    console.log("entrou");
    dispatch(setModel(model_));
    if (edit == true) {
      dispatch(update()).then((result) => {
        console.log(result);
        if (result?.payload?.status == "success") {
          history( "/tests/view/"+model_.application_id+"?tab=3");
        }
      });
    } else {
      dispatch(save()).then((result) => {
        console.log(result);
        if (result?.payload?.status == "success") {
          history("/tests/view/"+params.test_id+"?tab=3");
        }
      });
    }
  };

  useEffect(() => {
    console.log(props);
    if (props.justView) {
      history("/layouts_exams/view/" + params.id + "/" + params.class_id);
      if (blank == false) {
        dispatch(setBlank(true));
        return undefined;
      }
    }
    load_configs();

    // const exam_id = history.pa
    dispatch(setLoadingScreen(true));
    if (params.id != undefined) {
      if (!props.justView) {
        setEdit(true);
      }
      dispatch(setId(params.id));
    }
    if (props.justView) {
      dispatch(fetchIdNoAuth({ force: true, class_id: params.class_id })).then(
        (data: any) => {
          let examSelect: any = { ...data.payload.exam };
          let questoes_selecionadas: any = {};
          examSelect.questions = [...examSelect.questions];
          for (let i = 0; i < examSelect.questions.length; i++) {
            examSelect.questions[i] = { ...examSelect.questions[i] };
            examSelect.questions[i].question = examSelect.questions[i].question
              .replace(/<p><br><\/p>/gi, "", "")
              .replace(/<p><br\/><\/p>/gi, "");
            examSelect.questions[i].questions = [
              ...examSelect.questions[i].questions,
            ];

            for (let j = 0; j < examSelect.questions[i].questions.length; j++) {
              examSelect.questions[i].questions[j] = {
                ...examSelect.questions[i].questions[j],
              };
              examSelect.questions[i].questions[j].question =
                examSelect.questions[i].questions[j].question
                  .replace(/<p><br><\/p>/gi, "", "")
                  .replace(/<p><br\/><\/p>/gi, "");
              examSelect.questions[i].questions[j].alternatives = [
                ...examSelect.questions[i].questions[j].alternatives,
              ];
              for (
                let k = 0;
                k < examSelect.questions[i].questions[j].alternatives.length;
                k++
              ) {
                examSelect.questions[i].questions[j].alternatives[k] = {
                  ...examSelect.questions[i].questions[j].alternatives[k],
                };
                examSelect.questions[i].questions[j].alternatives[
                  k
                ].alternative = examSelect.questions[i].questions[
                  j
                ].alternatives[k].alternative
                  .replace(/<p><br><\/p>/gi, "", "")
                  .replace(/<p><br\/><\/p>/gi, "");
              }
            }
            examSelect.questions[i].alternatives = [
              ...examSelect.questions[i].alternatives,
            ];
            for (
              let k = 0;
              k < examSelect.questions[i].alternatives.length;
              k++
            ) {
              examSelect.questions[i].alternatives[k] = {
                ...examSelect.questions[i].alternatives[k],
              };
              examSelect.questions[i].alternatives[k].alternative =
                examSelect.questions[i].alternatives[k].alternative
                  .replace(/<p><br><\/p>/gi, "", "")
                  .replace(/<p><br\/><\/p>/gi, "");
            }

            questoes_selecionadas["q_" + examSelect.questions[i]._id] =
              examSelect.questions[i];
          }
          console.log(data.payload);

          let class_student: any = {
            ...data.payload.registrations.filter(
              (item: any) => item._id == params.class_id
            )[0],
          };
          console.log(class_student);

          var rng = seedrandom(class_student.student._id);

          let array_seeds = [];
          let questoes: any = [];
          if (data.payload.random_question == false) {
            //nao randomiza questoes
            if (data.payload.random_alternatives == false) {
              //nao randomiza alternativas
              questoes = [...examSelect.questions];
            } else {
              //randomiza alternativas
              for (let i = 0; i < examSelect.questions.length; i++) {
                let questao: any = { ...examSelect.questions[i] };
                if (questao.type == "objective") {
                  let alternativas: any = [];
                  let rng_alt = seedrandom(
                    class_student.student._id + "_" + questao._id
                  );
                  questao.alternatives.map((item: any) => {
                    item = { ...item };
                    item.order = rng_alt();
                    alternativas.push(item);
                  });
                  questao.alternatives = alternativas.sort(
                    (a: any, b: any) => a.order - b.order
                  );
                } else if (questao.type == "set") {
                  let questoes: any = [];
                  questao.questions.map((item2: any) => {
                    item2 = { ...item2 };
                    let alternativas: any = [];
                    let rng_alt = seedrandom(
                      class_student.student._id + "_" + item2._id
                    );
                    item2.alternatives.map((item: any) => {
                      item = { ...item };
                      item.order = rng_alt();
                      alternativas.push(item);
                    });
                    item2.alternatives = alternativas.sort(
                      (a: any, b: any) => a.order - b.order
                    );
                    questoes.push(item2);
                  });
                  questao.questions = questoes;
                }

                questoes.push(questao);
              }
            }
          } else {
            if (data.payload.select_from_group == false) {
              //usa todas questoes selecionadas
              if (data.payload.random_per_category == false) {
                //randomiza seguindo a ordem das disciplinas
                if (data.payload.random_alternatives == false) {
                  //nao randomiza alternativas
                  let questions_discipline: any = {};
                  for (let i = 0; i < examSelect.questions.length; i++) {
                    let questao: any = { ...examSelect.questions[i] };
                    questao.order = rng();
                    if (
                      questions_discipline[
                      examSelect.questions[i].discipline_id
                      ] == undefined
                    ) {
                      questions_discipline[
                        examSelect.questions[i].discipline_id
                      ] = [];
                    }
                    if (questao.type == "set") {
                      let questoes: any = [];
                      questao.questions.map((item2: any) => {
                        item2 = { ...item2 };
                        item2.order = rng();
                        questoes.push(item2);
                      });
                      questao.questions = questoes.sort(
                        (a: any, b: any) => a.order - b.order
                      );
                    }
                    questions_discipline[
                      examSelect.questions[i].discipline_id
                    ].push(questao);
                  }
                  let selected_disciplines = [
                    ...data.payload.selected_disciplines,
                  ];
                  selected_disciplines
                    .sort((a: any, b: any) => a.order - b.order)
                    .map((item3: any) => {
                      if (questions_discipline[item3._id] != undefined) {
                        questions_discipline[item3._id] = questions_discipline[
                          item3._id
                        ].sort((a: any, b: any) => a.order - b.order);
                        questoes.push(...questions_discipline[item3._id]);
                      }
                    });
                  // questoes = questoes.sort((a: any, b: any) => a.order - b.order)
                } else {
                  //randomiza alternativas
                  let questions_discipline: any = {};
                  for (let i = 0; i < examSelect.questions.length; i++) {
                    let questao: any = { ...examSelect.questions[i] };
                    questao.order = rng();
                    if (
                      questions_discipline[
                      examSelect.questions[i].discipline_id
                      ] == undefined
                    ) {
                      questions_discipline[
                        examSelect.questions[i].discipline_id
                      ] = [];
                    }
                    if (questao.type == "set") {
                      let questoes: any = [];
                      questao.questions.map((item2: any) => {
                        item2 = { ...item2 };
                        item2.order = rng();
                        let alternativas: any = [];
                        let rng_alt = seedrandom(
                          class_student.student._id + "_" + item2._id
                        );
                        item2.alternatives.map((item: any) => {
                          item = { ...item };
                          item.order = rng_alt();
                          alternativas.push(item);
                        });
                        item2.alternatives = alternativas.sort(
                          (a: any, b: any) => a.order - b.order
                        );
                        questoes.push(item2);
                      });
                      questao.questions = questoes.sort(
                        (a: any, b: any) => a.order - b.order
                      );
                    } else if (questao.type == "objective") {
                      let alternativas: any = [];
                      let rng_alt = seedrandom(
                        class_student.student._id + "_" + questao._id
                      );
                      questao.alternatives.map((item: any) => {
                        item = { ...item };
                        item.order = rng_alt();
                        alternativas.push(item);
                      });
                      questao.alternatives = alternativas.sort(
                        (a: any, b: any) => a.order - b.order
                      );
                    }
                    questions_discipline[
                      examSelect.questions[i].discipline_id
                    ].push(questao);
                  }
                  let selected_disciplines = [
                    ...data.payload.selected_disciplines,
                  ];
                  selected_disciplines
                    .sort((a: any, b: any) => a.order - b.order)
                    .map((item3: any) => {
                      if (questions_discipline[item3._id] != undefined) {
                        questions_discipline[item3._id] = questions_discipline[
                          item3._id
                        ].sort((a: any, b: any) => a.order - b.order);
                        questoes.push(...questions_discipline[item3._id]);
                      }
                    });
                }
              } else {
                //randomiza independente da disciplina
                if (data.payload.random_alternatives == false) {
                  //nao randomiza alternativas
                  for (let i = 0; i < examSelect.questions.length; i++) {
                    let questao: any = { ...examSelect.questions[i] };
                    questao.order = rng();
                    if (questao.type == "set") {
                      let questoes: any = [];
                      questao.questions.map((item2: any) => {
                        item2 = { ...item2 };
                        item2.order = rng();
                        questoes.push(item2);
                      });
                      questao.questions = questoes.sort(
                        (a: any, b: any) => a.order - b.order
                      );
                    }
                    questoes.push(questao);
                  }
                  questoes = questoes.sort(
                    (a: any, b: any) => a.order - b.order
                  );
                } else {
                  //randomiza alternativas
                  for (let i = 0; i < examSelect.questions.length; i++) {
                    let questao: any = { ...examSelect.questions[i] };
                    questao.order = rng();

                    if (questao.type == "objective") {
                      let alternativas: any = [];
                      let rng_alt = seedrandom(
                        class_student.student._id + "_" + questao._id
                      );
                      questao.alternatives.map((item: any) => {
                        item = { ...item };
                        item.order = rng_alt();
                        alternativas.push(item);
                      });
                      questao.alternatives = alternativas.sort(
                        (a: any, b: any) => a.order - b.order
                      );
                    } else if (questao.type == "set") {
                      let questoes: any = [];
                      questao.questions.map((item2: any) => {
                        item2 = { ...item2 };
                        item2.order = rng();

                        let alternativas: any = [];
                        let rng_alt = seedrandom(
                          class_student.student._id + "_" + item2._id
                        );
                        item2.alternatives.map((item: any) => {
                          item = { ...item };
                          item.order = rng_alt();
                          alternativas.push(item);
                        });
                        item2.alternatives = alternativas.sort(
                          (a: any, b: any) => a.order - b.order
                        );
                        questoes.push(item2);
                      });
                      questao.questions = questoes.sort(
                        (a: any, b: any) => a.order - b.order
                      );
                    }

                    questoes.push(questao);
                  }
                  questoes = questoes.sort(
                    (a: any, b: any) => a.order - b.order
                  );
                }
              }
            } else {
              //seleciona um numero de questoes do conjunto maior
              if (data.payload.random_per_category == false) {
                //randomiza seguindo a ordem das disciplinas
                if (data.payload.random_alternatives == false) {
                  //nao randomiza alternativas
                  let questions_discipline: any = {};
                  let selected_disciplines = [
                    ...data.payload.selected_disciplines,
                  ].sort((a: any, b: any) => a.order - b.order);
                  let disciplines: any = {};

                  selected_disciplines.map((item3: any) => {
                    disciplines[item3._id] = parseInt(item3.number);
                    questions_discipline[item3._id] = [];
                  });

                  let questoes_sorted = [];
                  for (let i = 0; i < examSelect.questions.length; i++) {
                    let questao: any = { ...examSelect.questions[i] };
                    questao.order = rng();
                    if (questao.type == "set") {
                      let questoes: any = [];
                      questao.questions.map((item2: any) => {
                        item2 = { ...item2 };
                        item2.order = rng();
                        questoes.push(item2);
                      });
                      questao.questions = questoes.sort(
                        (a: any, b: any) => a.order - b.order
                      );
                    } else {
                    }
                    questoes_sorted.push(questao);
                  }
                  questoes_sorted = questoes_sorted.sort(
                    (a: any, b: any) => a.order - b.order
                  );

                  // for (let i = 0; i < examSelect.questions.length; i++) {
                  //     if (questions_discipline[examSelect.questions[i].discipline_id] == undefined) {
                  //         questions_discipline[examSelect.questions[i].discipline_id] = [];
                  //     }
                  //     questions_discipline[examSelect.questions[i].discipline_id].push(examSelect.questions[i]);
                  // }

                  for (let i = 0; i < questoes_sorted.length; i++) {
                    let questao: any = { ...questoes_sorted[i] };
                    let number_questions = questions_discipline[
                      questao.discipline_id
                    ]
                      .map((item: any, id: any) => {
                        if (item.type == "set") {
                          return item.questions.length;
                        } else {
                          return 1;
                        }
                      })
                      .reduce((a: any, b: any) => a + b, 0);
                    if (
                      disciplines[questao.discipline_id] != undefined &&
                      disciplines[questao.discipline_id] > number_questions
                    ) {
                      if (questao.type == "set") {
                        let questoes: any = [];

                        questao.questions.map((item2: any) => {
                          item2 = { ...item2 };
                          if (
                            disciplines[questao.discipline_id] >
                            number_questions + questoes.length
                          ) {
                            questoes.push(item2);
                          } else {
                          }
                        });
                        questao.questions = questoes.sort(
                          (a: any, b: any) => a.order - b.order
                        );
                      } else {
                      }
                      questions_discipline[questao.discipline_id].push(questao);
                    }
                  }

                  selected_disciplines
                    .sort((a: any, b: any) => a.order - b.order)
                    .map((item3: any) => {
                      if (disciplines[item3._id] != undefined) {
                        questions_discipline[item3._id].map((item: any) => {
                          questoes.push(item);
                        });
                      }
                    });
                  // questoes = questoes.sort((a: any, b: any) => a.order - b.order)
                } else {
                  //randomiza alternativas
                  let questions_discipline: any = {};
                  let selected_disciplines = [
                    ...data.payload.selected_disciplines,
                  ].sort((a: any, b: any) => a.order - b.order);
                  let disciplines: any = {};

                  selected_disciplines.map((item3: any) => {
                    disciplines[item3._id] = parseInt(item3.number);
                    questions_discipline[item3._id] = [];
                  });

                  let questoes_sorted = [];
                  for (let i = 0; i < examSelect.questions.length; i++) {
                    let questao: any = { ...examSelect.questions[i] };
                    questao.order = rng();
                    if (questao.type == "set") {
                      let questoes: any = [];
                      questao.questions.map((item2: any) => {
                        item2 = { ...item2 };
                        item2.order = rng();
                        let alternativas: any = [];
                        let rng_alt = seedrandom(
                          class_student.student._id + "_" + item2._id
                        );
                        item2.alternatives.map((item: any) => {
                          item = { ...item };
                          item.order = rng_alt();
                          alternativas.push(item);
                        });
                        item2.alternatives = alternativas.sort(
                          (a: any, b: any) => a.order - b.order
                        );
                        questoes.push(item2);
                      });
                      questao.questions = questoes.sort(
                        (a: any, b: any) => a.order - b.order
                      );
                    } else if (questao.type == "objective") {
                      let alternativas: any = [];
                      let rng_alt = seedrandom(
                        class_student.student._id + "_" + questao._id
                      );
                      questao.alternatives.map((item: any) => {
                        item = { ...item };
                        item.order = rng_alt();
                        alternativas.push(item);
                      });
                      questao.alternatives = alternativas.sort(
                        (a: any, b: any) => a.order - b.order
                      );
                    }
                    questoes_sorted.push(questao);
                  }
                  questoes_sorted = questoes_sorted.sort(
                    (a: any, b: any) => a.order - b.order
                  );

                  // for (let i = 0; i < examSelect.questions.length; i++) {
                  //     if (questions_discipline[examSelect.questions[i].discipline_id] == undefined) {
                  //         questions_discipline[examSelect.questions[i].discipline_id] = [];
                  //     }
                  //     questions_discipline[examSelect.questions[i].discipline_id].push(examSelect.questions[i]);
                  // }

                  for (let i = 0; i < questoes_sorted.length; i++) {
                    let questao: any = { ...questoes_sorted[i] };
                    let number_questions = questions_discipline[
                      questao.discipline_id
                    ]
                      .map((item: any, id: any) => {
                        if (item.type == "set") {
                          return item.questions.length;
                        } else {
                          return 1;
                        }
                      })
                      .reduce((a: any, b: any) => a + b, 0);
                    if (
                      disciplines[questao.discipline_id] != undefined &&
                      disciplines[questao.discipline_id] > number_questions
                    ) {
                      if (questao.type == "set") {
                        let questoes: any = [];

                        questao.questions.map((item2: any) => {
                          item2 = { ...item2 };
                          if (
                            disciplines[questao.discipline_id] >
                            number_questions + questoes.length
                          ) {
                            questoes.push(item2);
                          } else {
                          }
                        });
                        questao.questions = questoes.sort(
                          (a: any, b: any) => a.order - b.order
                        );
                      } else {
                      }
                      questions_discipline[questao.discipline_id].push(questao);
                    }
                  }

                  selected_disciplines
                    .sort((a: any, b: any) => a.order - b.order)
                    .map((item3: any) => {
                      if (disciplines[item3._id] != undefined) {
                        questions_discipline[item3._id].map((item: any) => {
                          questoes.push(item);
                        });
                      }
                    });
                  // questoes = questoes.sort((a: any, b: any) => a.order - b.order)
                }
              } else {
                //randomiza independente da disciplina
                if (data.payload.random_alternatives == false) {
                  //nao randomiza alternativas
                  let questoes_sorted = [];
                  for (let i = 0; i < examSelect.questions.length; i++) {
                    let questao: any = { ...examSelect.questions[i] };
                    questao.order = rng();
                    if (questao.type == "set") {
                      let questoes: any = [];
                      questao.questions.map((item2: any) => {
                        item2 = { ...item2 };
                        item2.order = rng();
                        questoes.push(item2);
                      });
                      questao.questions = questoes.sort(
                        (a: any, b: any) => a.order - b.order
                      );
                    } else if (questao.type == "objective") {
                    }
                    questoes_sorted.push(questao);
                  }
                  questoes_sorted = questoes_sorted.sort(
                    (a: any, b: any) => a.order - b.order
                  );

                  for (let i = 0; i < questoes_sorted.length; i++) {
                    let number_questions = questoes
                      .map((item: any, id: any) => {
                        if (item.type == "set") {
                          return item.questions.length;
                        } else {
                          return 1;
                        }
                      })
                      .reduce((a: any, b: any) => a + b, 0);

                    if (
                      number_questions < data.payload.number_select_questions
                    ) {
                      let questao: any = { ...questoes_sorted[i] };
                      questao.order = rng();
                      if (questao.type == "set") {
                        let questoes: any = [];
                        questao.questions.map((item2: any) => {
                          if (
                            number_questions <
                            data.payload.number_select_questions +
                            questoes.length
                          ) {
                            item2 = { ...item2 };
                            item2.order = rng();
                            questoes.push(item2);
                          }
                        });
                        questao.questions = questoes.sort(
                          (a: any, b: any) => a.order - b.order
                        );
                      }
                      questoes.push(questao);
                    }
                  }
                  questoes = questoes.sort(
                    (a: any, b: any) => a.order - b.order
                  );
                } else {
                  //randomiza alternativas
                  let questoes_sorted = [];
                  for (let i = 0; i < examSelect.questions.length; i++) {
                    let questao: any = { ...examSelect.questions[i] };
                    questao.order = rng();
                    if (questao.type == "set") {
                      let questoes: any = [];
                      questao.questions.map((item2: any) => {
                        item2 = { ...item2 };
                        item2.order = rng();
                        let alternativas: any = [];
                        let rng_alt = seedrandom(
                          class_student.student._id + "_" + item2._id
                        );
                        item2.alternatives.map((item: any) => {
                          item = { ...item };
                          item.order = rng_alt();
                          alternativas.push(item);
                        });
                        item2.alternatives = alternativas.sort(
                          (a: any, b: any) => a.order - b.order
                        );
                        questoes.push(item2);
                      });
                      questao.questions = questoes.sort(
                        (a: any, b: any) => a.order - b.order
                      );
                    } else if (questao.type == "objective") {
                      let alternativas: any = [];
                      let rng_alt = seedrandom(
                        class_student.student._id + "_" + questao._id
                      );
                      questao.alternatives.map((item: any) => {
                        item = { ...item };
                        item.order = rng_alt();
                        alternativas.push(item);
                      });
                      questao.alternatives = alternativas.sort(
                        (a: any, b: any) => a.order - b.order
                      );
                    }
                    questoes_sorted.push(questao);
                  }
                  questoes_sorted = questoes_sorted.sort(
                    (a: any, b: any) => a.order - b.order
                  );

                  for (let i = 0; i < questoes_sorted.length; i++) {
                    let number_questions = questoes
                      .map((item: any, id: any) => {
                        if (item.type == "set") {
                          return item.questions.length;
                        } else {
                          return 1;
                        }
                      })
                      .reduce((a: any, b: any) => a + b, 0);

                    if (
                      number_questions < data.payload.number_select_questions
                    ) {
                      let questao: any = { ...questoes_sorted[i] };
                      questao.order = rng();
                      if (questao.type == "set") {
                        let questoes: any = [];
                        questao.questions.map((item2: any) => {
                          if (
                            number_questions <
                            data.payload.number_select_questions +
                            questoes.length
                          ) {
                            item2 = { ...item2 };
                            item2.order = rng();
                            let alternativas: any = [];
                            let rng_alt = seedrandom(
                              class_student.student._id + "_" + item2._id
                            );
                            item2.alternatives.map((item: any) => {
                              item = { ...item };
                              item.order = rng_alt();
                              alternativas.push(item);
                            });
                            item2.alternatives = alternativas.sort(
                              (a: any, b: any) => a.order - b.order
                            );
                            questoes.push(item2);
                          }
                        });
                        questao.questions = questoes.sort(
                          (a: any, b: any) => a.order - b.order
                        );
                      } else if (questao.type == "objective") {
                        let alternativas: any = [];
                        let rng_alt = seedrandom(
                          class_student.student._id + "_" + questao._id
                        );
                        questao.alternatives.map((item: any) => {
                          item = { ...item };
                          item.order = rng_alt();
                          alternativas.push(item);
                        });
                        questao.alternatives = alternativas.sort(
                          (a: any, b: any) => a.order - b.order
                        );
                      }
                      questoes.push(questao);
                    }
                  }
                  questoes = questoes.sort(
                    (a: any, b: any) => a.order - b.order
                  );
                }
              }
            }
          }

          examSelect.questions = [...questoes];
          // console.log(examSelect.questions.sort((a:any,b:any)=>a.order-b.order))
          let model_ = {
            ...model,
            ...data.payload,
            exam_id: data.payload.exam._id,
            exam: examSelect,
          };
          QRCode.toDataURL(
            class_student._id +
            "_" +
            class_student.student._id +
            "_" +
            data.payload.exam._id +
            "_" +
            data.payload._id
          )
            .then((url: any) => {
              console.log(url);
              class_student.qrcode = url;
              console.log(data.payload.exam);
              dispatch(
                setState({
                  questions: questoes_selecionadas,
                  examSelect: examSelect,
                  class_student,
                  model: model_,
                })
              );
            })
            .catch((err: any) => {
              console.error(err);
            });
        }
      );
    } else {
      dispatch(fetchList({ force: true,test_id:params.test_id })).then((data) => {
        console.log(data);

        if (params.id == undefined) {
          if (params.default_id != undefined) {
            dispatch(fetchDefault({ id: params.default_id })).then(
              (default_: any) => {
                console.log("default", default_);
                dispatch(setLoadingScreen(false));
              }
            );
          } else {
            dispatch(setLoadingScreen(false));
          }
        } else {
          dispatch(fetchId({ force: true })).then((data: any) => {
            let examSelect = { ...data.payload.exam };
            let questoes_selecionadas: any = {};
            examSelect.questions = [...examSelect.questions];
            for (let i = 0; i < examSelect.questions.length; i++) {
              examSelect.questions[i] = { ...examSelect.questions[i] };
              examSelect.questions[i].question = examSelect.questions[
                i
              ].question
                .replace(/<p><br><\/p>/gi, "", "")
                .replace(/<p><br\/><\/p>/gi, "");
              examSelect.questions[i].questions = [
                ...examSelect.questions[i].questions,
              ];

              for (
                let j = 0;
                j < examSelect.questions[i].questions.length;
                j++
              ) {
                examSelect.questions[i].questions[j] = {
                  ...examSelect.questions[i].questions[j],
                };
                examSelect.questions[i].questions[j].question =
                  examSelect.questions[i].questions[j].question
                    .replace(/<p><br><\/p>/gi, "", "")
                    .replace(/<p><br\/><\/p>/gi, "");
                examSelect.questions[i].questions[j].alternatives = [
                  ...examSelect.questions[i].questions[j].alternatives,
                ];
                for (
                  let k = 0;
                  k < examSelect.questions[i].questions[j].alternatives.length;
                  k++
                ) {
                  examSelect.questions[i].questions[j].alternatives[k] = {
                    ...examSelect.questions[i].questions[j].alternatives[k],
                  };
                  examSelect.questions[i].questions[j].alternatives[
                    k
                  ].alternative = examSelect.questions[i].questions[
                    j
                  ].alternatives[k].alternative
                    .replace(/<p><br><\/p>/gi, "", "")
                    .replace(/<p><br\/><\/p>/gi, "");
                }
              }
              examSelect.questions[i].alternatives = [
                ...examSelect.questions[i].alternatives,
              ];
              for (
                let k = 0;
                k < examSelect.questions[i].alternatives.length;
                k++
              ) {
                examSelect.questions[i].alternatives[k] = {
                  ...examSelect.questions[i].alternatives[k],
                };
                examSelect.questions[i].alternatives[k].alternative =
                  examSelect.questions[i].alternatives[k].alternative
                    .replace(/<p><br><\/p>/gi, "", "")
                    .replace(/<p><br\/><\/p>/gi, "");
              }

              questoes_selecionadas["q_" + examSelect.questions[i]._id] =
                examSelect.questions[i];
            }

            console.log(data.payload.exam);

            dispatch(
              setState({
                examSelect: examSelect,
                questions: questoes_selecionadas,
              })
            );
          });
        }
      });
    }

    // if (params.id != undefined) {
    //     dispatch(setId(params.id))
    //     setEdit(true);
    //     dispatch(fetchId({ force: true }));

    // }
    clearInterval(inverval_load.current);

    return () => {
      clearInterval(inverval_load.current);
      dispatch(clearState());
    };
  }, [blank]);

  const parseHtmlString = (yourHtmlString: string) => {
    var element = document.createElement("div");
    element.innerHTML = yourHtmlString;
    let srcs = [];
    var imgSrcUrls = element.getElementsByTagName("img");
    for (var i = 0; i < imgSrcUrls.length; i++) {
      var urlValue = imgSrcUrls[i].getAttribute("src");

      srcs.push(urlValue);
    }
    return srcs;
  };

  const readInitialImages = () => {
    let outputFormat = "image/png";
    console.log(examSelect);
    if (examSelect._id == "") {
      dispatch(setState({ loading_page: false }));

      return;
    }

    dispatch(setState({ loading_page: true }));

    let questoes = examSelect.questions;
    let finish: any = {};
    for (let i = 0; i < questoes.length; i++) {
      let questao = questoes[i];
      if (questao.type == "text") {
        let srcs = parseHtmlString(questao.question);
        for (let k = 0; k < srcs.length; k++) {
          let r = k;
          finish["q_" + r + "_" + questao._id] = false;
          let src = srcs[k];
          let img: any = new Image();
          img.crossOrigin = "Anonymous";
          img.onload = function () {
            let obj: any = this;
            var canvas: any = document.createElement("CANVAS");
            var ctx = canvas.getContext("2d");
            var dataURL;
            canvas.height = obj.naturalHeight;
            canvas.width = obj.naturalWidth;
            ctx.drawImage(this, 0, 0);
            dataURL = canvas.toDataURL(outputFormat);
            questoes[i].question = questoes[i].question.replace(src, dataURL);
            finish["q_" + r + "_" + questao._id] = true;
          };
          img.src = src;
        }
      } else if (questao.type == "objective") {
        let srcs = parseHtmlString(questao.question);
        for (let k = 0; k < srcs.length; k++) {
          let r = k;
          finish["q_" + r + "_" + questao._id] = false;
          let src = srcs[k];
          let img: any = new Image();
          img.crossOrigin = "Anonymous";
          img.onload = function () {
            let obj: any = this;
            var canvas: any = document.createElement("CANVAS");
            var ctx = canvas.getContext("2d");
            var dataURL;
            canvas.height = obj.naturalHeight;
            canvas.width = obj.naturalWidth;
            ctx.drawImage(this, 0, 0);
            dataURL = canvas.toDataURL(outputFormat);
            questoes[i].question = questoes[i].question.replace(src, dataURL);
            finish["q_" + r + "_" + questao._id] = true;
          };
          img.src = src;
        }

        for (let j = 0; j < questao.alternatives.length; j++) {
          let alternativa = questao.alternatives[j];
          let srcs = parseHtmlString(alternativa.alternative);
          for (let k = 0; k < srcs.length; k++) {
            let r = k;
            finish["a_" + r + "_" + alternativa._id] = false;

            let src = srcs[k];
            let img: any = new Image();
            img.crossOrigin = "Anonymous";
            img.onload = function () {
              let obj: any = this;

              var canvas: any = document.createElement("CANVAS");
              var ctx = canvas.getContext("2d");
              var dataURL;
              canvas.height = obj.naturalHeight;
              canvas.width = obj.naturalWidth;
              ctx.drawImage(this, 0, 0);
              dataURL = canvas.toDataURL(outputFormat);
              questoes[i].alternatives[j].alternative = questoes[
                i
              ].alternatives[j].alternative.replace(src, dataURL);
              finish["a_" + r + "_" + alternativa._id] = true;
            };
            img.src = src;
          }
        }
      } else if (questao.type == "set") {
        for (let y = 0; y < questao.questions.length; y++) {
          let questao2 = questao.questions[y];
          let srcs = parseHtmlString(questao2.question);
          for (let k = 0; k < srcs.length; k++) {
            let src = srcs[k];
            let r = k;
            finish["q_" + r + "_" + questao2._id] = false;

            let img: any = new Image();
            img.crossOrigin = "Anonymous";
            img.onload = function () {
              let obj: any = this;

              var canvas: any = document.createElement("CANVAS");
              var ctx = canvas.getContext("2d");
              var dataURL;
              canvas.height = obj.naturalHeight;
              canvas.width = obj.naturalWidth;
              ctx.drawImage(this, 0, 0);
              dataURL = canvas.toDataURL(outputFormat);
              questoes[i].questions[y].question = questoes[i].questions[
                y
              ].question.replace(src, dataURL);
              finish["q_" + r + "_" + questao2._id] = true;
            };
            img.src = src;
          }

          for (let j = 0; j < questao2.alternatives.length; j++) {
            let alternativa = questao2.alternatives[j];
            let srcs = parseHtmlString(alternativa.alternative);

            for (let k = 0; k < srcs.length; k++) {
              let r = k;
              finish["a_" + r + "_" + alternativa._id] = false;

              let src = srcs[k];
              let img: any = new Image();
              img.crossOrigin = "Anonymous";
              img.onload = function () {
                let obj: any = this;

                var canvas: any = document.createElement("CANVAS");
                var ctx = canvas.getContext("2d");
                var dataURL;
                canvas.height = obj.naturalHeight;
                canvas.width = obj.naturalWidth;
                ctx.drawImage(this, 0, 0);
                dataURL = canvas.toDataURL(outputFormat);
                questoes[i].questions[y].alternatives[j].alternative = questoes[
                  i
                ].questions[y].alternatives[j].alternative.replace(
                  src,
                  dataURL
                );
                finish["a_" + r + "_" + alternativa._id] = true;
              };
              img.src = src;
            }
          }
        }
      }
    }

    console.log("executou");
    console.log(finish);
    let counter = 0;

    let interval = setInterval(() => {
      inverval_load.current = interval;
      console.log("executando interval");
      if (!Object.values(finish).includes(false)) {
        console.log("executando interval entrou if");

        try {
          clearInterval(interval);
          clearInterval(inverval_load.current);

          let prova: any = { ...examSelect };
          prova.questions = [...questoes];
          dispatch(setState({ examSelect: prova }));
        } catch (e) {
          clearInterval(interval);

          clearInterval(inverval_load.current);
          console.log(e);
        }
      } else {
        console.log("executando interval entrou else");

        dispatch(setState({ loading_page: false }));
        counter++;
        if (counter > 3) {
          clearInterval(interval);
          clearInterval(inverval_load.current);
        }
      }
    }, 1000);
  };

  const setPages = () => {
    try {
      // let width_one_column = (668 - (this.state.marginInternaDireita - 40) - (this.state.marginInternaEsquerda - 40));
      // let width_two_column = 326 - ((this.state.marginInternaDireita - 40) + (this.state.marginInternaEsquerda - 40) / 2);
      let width_one_column =
        771 - model.marginInternalRight - model.marginInternalLeft;
      let width_two_column =
        (755 - model.marginInternalRight - model.marginInternalLeft) / 2;

      dispatch(setState({ loading_page: true }));
      // let height_page = this.state.numero_colunas == 1 ? (960 - (this.state.marginInternaSuperior - 10) - (this.state.marginInternaInferior - 40)) : (((950 - (this.state.marginInternaSuperior - 10) - (this.state.marginInternaInferior - 40)) * 2));
      let height_page =
        model.numberColumns == 1
          ? 1103 - model.marginInternalUp - model.marginInternalDown - 67
          : (1103 - model.marginInternalUp - model.marginInternalDown - 67) * 2;

      // let height_page = 1200;
      console.log("Height page: ", height_page);

      let default_margin = 0;
      let pages: any = [];
      let questoes = examSelect.questions;
      let page: any = null;
      let imgs = document.body.getElementsByTagName("img");
      for (let i = 0; i < imgs.length; i++) {
        imgs[i].style.maxWidth = "100%";
        imgs[i].style.maxHeight = "100%";
      }

      for (let i = 0; i < questoes.length; i++) {
        let questao = questoes[i];
        if (page == null) {
          page = {
            ids: [],
            elements: [],
            height: 0,
            heights: [],
          };
        }
        //checa categoria
        if (
          document.getElementById("categoria_" + questao._id) != null &&
          page.height +
          Math.ceil(
            document.getElementById("categoria_" + questao._id)!
              .offsetHeight + default_margin
          ) <
          height_page
        ) {
          page.height +=
            Math.ceil(
              document.getElementById("categoria_" + questao._id)!.offsetHeight
            ) + default_margin;
          let obj: any = document.getElementById("categoria_" + questao._id);
          page.elements.push(obj!.cloneNode(true));
          page.heights.push(Math.ceil(obj!.offsetHeight) + default_margin);
          page.ids.push("categoria_" + questao._id);
        } else if (
          document.getElementById("categoria_" + questao._id) != null
        ) {
          pages.push(page);
          page = {
            ids: [],
            elements: [],
            height: 0,
            heights: [],
          };
          if (
            document.getElementById("categoria_" + questao._id) != null &&
            page.height +
            Math.ceil(
              document.getElementById("categoria_" + questao._id)!
                .offsetHeight
            ) +
            default_margin <
            height_page
          ) {
            let obj: any = document.getElementById("categoria_" + questao._id);
            page.height += Math.ceil(obj!.offsetHeight) + default_margin;

            page.elements.push(obj!.cloneNode(true));
            page.ids.push("categoria_" + questao._id);
            page.heights.push(Math.ceil(obj!.offsetHeight) + default_margin);
          }
        }

        if (
          page.height +
          Math.ceil(
            document.getElementById("enunciado_" + questao._id)!.offsetHeight
          ) +
          default_margin <
          height_page
        ) {
          let obj: any = document.getElementById("enunciado_" + questao._id);

          page.height += Math.ceil(obj!.offsetHeight) + default_margin;
          let element2: any = obj!.cloneNode(true);
          element2.firstChild.style.maxWidth =
            model.numberColumns == 1
              ? width_one_column + "px"
              : width_two_column + "px";
          page.elements.push(element2);
          page.ids.push("enunciado_" + questao._id);
          page.heights.push(Math.ceil(obj!.offsetHeight) + default_margin);
        } else {
          pages.push(page);
          page = {
            ids: [],
            elements: [],
            height: 0,
            heights: [],
          };
          if (
            page.height +
            Math.ceil(
              document.getElementById("enunciado_" + questao._id)!
                .offsetHeight
            ) +
            default_margin <
            height_page
          ) {
            let obj: any = document.getElementById("enunciado_" + questao._id);
            page.height += Math.ceil(obj!.offsetHeight) + default_margin;
            let element2: any = obj!.cloneNode(true);
            element2.firstChild.style.maxWidth =
              model.numberColumns == 1
                ? width_one_column + "px"
                : width_two_column + "px";
            page.elements.push(element2);
            page.ids.push("enunciado_" + questao._id);
            page.heights.push(Math.ceil(obj!.offsetHeight) + default_margin);
          }
        }
        if (questao.type == "text") {
          if (
            document.getElementById("dividor_" + questao._id) != null &&
            page.height +
            document.getElementById("dividor_" + questao._id)!.offsetHeight +
            default_margin <
            height_page
          ) {
            let obj: any = document.getElementById("dividor_" + questao._id);

            page.height += Math.ceil(obj!.offsetHeight) + default_margin;
            page.elements.push(obj!.cloneNode(true));
            page.ids.push("dividor_" + questao._id);
            page.heights.push(Math.ceil(obj!.offsetHeight) + default_margin);
          } else if (
            document.getElementById("dividor_" + questao._id) != null
          ) {
            pages.push(page);
            page = {
              ids: [],
              elements: [],
              height: 0,
              heights: [],
            };
            if (
              document.getElementById("dividor_" + questao._id) != null &&
              page.height +
              document.getElementById("dividor_" + questao._id)!
                .offsetHeight +
              default_margin <
              height_page
            ) {
              let obj: any = document.getElementById("dividor_" + questao._id);

              page.height += Math.ceil(obj!.offsetHeight) + default_margin;
              page.elements.push(obj!.cloneNode(true));
              page.ids.push("dividor_" + questao._id);
              page.heights.push(Math.ceil(obj!.offsetHeight) + default_margin);
            }
          }
        } else if (questao.type == "objective") {
          for (let j = 0; j < questao.alternatives.length; j++) {
            let alternativa = questao.alternatives[j];
            if (
              page.height +
              Math.ceil(
                document.getElementById("alternativa_" + alternativa._id)!
                  .offsetHeight
              ) +
              default_margin <
              height_page
            ) {
              let obj: any = document.getElementById(
                "alternativa_" + alternativa._id
              );

              page.height += Math.ceil(obj!.offsetHeight) + default_margin;
              let element2: any = obj!.cloneNode(true);
              element2.firstChild.style.maxWidth =
                model.numberColumns == 1
                  ? width_one_column + "px"
                  : width_two_column + "px";

              page.elements.push(element2);
              page.ids.push("alternativa_" + alternativa._id);
              page.heights.push(Math.ceil(obj!.offsetHeight) + default_margin);
            } else {
              pages.push(page);
              page = {
                ids: [],
                elements: [],
                height: 0,
                heights: [],
              };
              if (
                page.height +
                document.getElementById("alternativa_" + alternativa._id)!
                  .offsetHeight +
                default_margin <
                height_page
              ) {
                let obj: any = document.getElementById(
                  "alternativa_" + alternativa._id
                );

                page.height += Math.ceil(obj!.offsetHeight) + default_margin;
                let element2 = obj!.cloneNode(true);
                element2.firstChild.style.maxWidth =
                  model.numberColumns == 1
                    ? width_one_column + "px"
                    : width_two_column + "px";
                page.elements.push(element2);
                page.ids.push("alternativa_" + alternativa._id);
                page.heights.push(
                  Math.ceil(obj!.offsetHeight) + default_margin
                );
              }
            }
          }

          if (
            document.getElementById("dividor_" + questao._id) != null &&
            page.height +
            document.getElementById("dividor_" + questao._id)!.offsetHeight +
            default_margin <
            height_page
          ) {
            let obj: any = document.getElementById("dividor_" + questao._id);

            page.height += Math.ceil(obj!.offsetHeight) + default_margin;
            page.elements.push(obj!.cloneNode(true));
            page.ids.push("dividor_" + questao._id);
            page.heights.push(Math.ceil(obj!.offsetHeight) + default_margin);
          } else if (
            document.getElementById("dividor_" + questao._id) != null
          ) {
            pages.push(page);
            page = {
              ids: [],
              elements: [],
              height: 0,
              heights: [],
            };
            if (
              document.getElementById("dividor_" + questao._id) != null &&
              page.height +
              document.getElementById("dividor_" + questao._id)!
                .offsetHeight +
              default_margin <
              height_page
            ) {
              let obj: any = document.getElementById("dividor_" + questao._id);

              page.height += Math.ceil(obj!.offsetHeight) + default_margin;
              page.elements.push(obj!.cloneNode(true));
              page.ids.push("dividor_" + questao._id);
              page.heights.push(Math.ceil(obj!.offsetHeight) + default_margin);
            }
          }
        } else if (questao.type == "set") {
          for (let k = 0; k < questao.questions.length; k++) {
            let questao_child = questao.questions[k];
            if (
              page.height +
              document.getElementById("enunciado_" + questao_child._id)!
                .offsetHeight +
              default_margin <
              height_page
            ) {
              let obj: any = document.getElementById(
                "enunciado_" + questao_child._id
              );

              page.height += Math.ceil(obj!.offsetHeight) + default_margin;
              let element2: any = obj!.cloneNode(true);
              element2.firstChild.style.maxWidth =
                model.numberColumns == 1
                  ? width_one_column + "px"
                  : width_two_column + "px";
              page.elements.push(element2);
              page.ids.push("enunciado_" + questao_child._id);
              page.heights.push(Math.ceil(obj!.offsetHeight) + default_margin);
            } else {
              pages.push(page);
              page = {
                ids: [],
                elements: [],
                height: 0,
                heights: [],
              };
              if (
                page.height +
                document.getElementById("enunciado_" + questao_child._id)!
                  .offsetHeight +
                default_margin <
                height_page
              ) {
                let obj: any = document.getElementById(
                  "enunciado_" + questao_child._id
                );

                page.height += Math.ceil(obj!.offsetHeight) + default_margin;
                let element2: any = obj!.cloneNode(true);
                element2.firstChild.style.maxWidth =
                  model.numberColumns == 1
                    ? width_one_column + "px"
                    : width_two_column + "px";
                page.elements.push(element2);
                page.ids.push("enunciado_" + questao_child._id);
                page.heights.push(
                  Math.ceil(obj!.offsetHeight) + default_margin
                );
              }
            }

            for (let j = 0; j < questao_child.alternatives.length; j++) {
              let alternativa = questao_child.alternatives[j];
              if (
                page.height +
                document.getElementById("alternativa_" + alternativa._id)!
                  .offsetHeight +
                default_margin <
                height_page
              ) {
                let obj: any = document.getElementById(
                  "alternativa_" + alternativa._id
                );

                page.height += Math.ceil(obj!.offsetHeight) + default_margin;

                let element2: any = obj!.cloneNode(true);
                element2.firstChild.style.maxWidth =
                  model.numberColumns == 1
                    ? width_one_column + "px"
                    : width_two_column + "px";

                page.elements.push(element2);
                page.ids.push("alternativa_" + alternativa._id);
                page.heights.push(
                  Math.ceil(obj!.offsetHeight) + default_margin
                );
              } else {
                pages.push(page);
                page = {
                  ids: [],
                  elements: [],
                  height: 0,
                  heights: [],
                };
                if (
                  page.height +
                  document.getElementById("alternativa_" + alternativa._id)!
                    .offsetHeight +
                  default_margin <
                  height_page
                ) {
                  let obj: any = document.getElementById(
                    "alternativa_" + alternativa._id
                  );

                  page.height += Math.ceil(obj!.offsetHeight) + default_margin;
                  let element2: any = obj!.cloneNode(true);
                  element2.firstChild.style.maxWidth =
                    model.numberColumns == 1
                      ? width_one_column + "px"
                      : width_two_column + "px";

                  page.elements.push(element2);
                  page.ids.push("alternativa_" + alternativa._id);
                  page.heights.push(
                    Math.ceil(obj!.offsetHeight) + default_margin
                  );
                }
              }
            }

            if (
              document.getElementById("dividor_" + questao_child._id) != null &&
              page.height +
              document.getElementById("dividor_" + questao_child._id)!
                .offsetHeight +
              default_margin <
              height_page
            ) {
              let obj: any = document.getElementById(
                "dividor_" + questao_child._id
              );

              page.height += Math.ceil(obj!.offsetHeight) + default_margin;
              page.elements.push(obj!.cloneNode(true));
              page.ids.push("dividor_" + questao_child._id);
              page.heights.push(Math.ceil(obj!.offsetHeight) + default_margin);
            } else if (
              document.getElementById("dividor_" + questao_child._id) != null
            ) {
              pages.push(page);
              page = {
                ids: [],
                elements: [],
                height: 0,
                heights: [],
              };
              if (
                document.getElementById("dividor_" + questao_child._id) !=
                null &&
                page.height +
                document.getElementById("dividor_" + questao_child._id)!
                  .offsetHeight +
                default_margin <
                height_page
              ) {
                let obj: any = document.getElementById(
                  "dividor_" + questao_child._id
                );

                page.height += Math.ceil(obj!.offsetHeight) + default_margin;
                page.elements.push(obj!.cloneNode(true));
                page.ids.push("dividor_" + questao_child._id);
                page.heights.push(
                  Math.ceil(obj!.offsetHeight) + default_margin
                );
              }
            }
          }
        }
      }
      if (page != null && page.height != 0) {
        pages.push(page);
      }

      console.log(pages);
      console.log(pages_all);
      console.log("iniciou");
      pages_all = [];
      let length_elements: any = [];
      dispatch(setState({ pages, pages_new: [] }));
    } catch (e) {
      console.log(e);

      readInitialImages();

      let model_: any = { ...model };
      console.log(examSelect);
      try {
        let examSelect1: any = { ...examSelect };
        let questoes_selecionadas: any = {};
        let disciplines: any = [];
        model_.selected_disciplines = [];
        for (let i = 0; i < examSelect1.questions.length; i++) {
          questoes_selecionadas["q_" + examSelect1.questions[i]._id] =
            examSelect1.questions[i];
          if (!disciplines.includes(examSelect1.questions[i].discipline_id)) {
            disciplines.push(examSelect1.questions[i].discipline_id);
            model_.selected_disciplines.push({
              _id: examSelect1.questions[i].discipline._id,
              name: examSelect1.questions[i].discipline.name,
              order: model_.selected_disciplines.length,
              number: 1,
            });
          }
        }
        model_.exam_id = examSelect1._id;
        model_.answer_page_visible = examSelect1.type == "text" ? false : true;
        model_.numberColumns =
          examSelect1.type == "text" ? 1 : model_.numberColumns;

        dispatch(
          setState({
            model: model_,
            examSelect: examSelect1,
            questions: questoes_selecionadas,
          })
        );
      } catch (e) { }
    }
  };

  useEffect(() => {
    console.log("atualizou");
    if (Object.values(questions).length == 0) {
      return;
    }
    if (
      pages_new.length == 0 &&
      pages.length != 0 &&
      examSelect._id != "" &&
      examSelect.questions.length > 0
    ) {
      let imgs = document.body.getElementsByTagName("img");
      for (let i = 0; i < imgs.length; i++) {
        imgs[i].style.maxWidth = "100%";
        imgs[i].style.maxHeight = "100%";
      }
      console.log("executando rnder page");

      timeout.current = setTimeout(() => {
        try {
          let width_one_column =
            771 - model.marginInternalRight - model.marginInternalLeft;
          let width_two_column =
            (755 - model.marginInternalRight - model.marginInternalLeft) / 2;
          // let height_page = this.state.numero_colunas == 1 ? (960 - (this.state.marginInternaSuperior - 10) - (this.state.marginInternaInferior - 40)) : (((950 - (this.state.marginInternaSuperior - 10) - (this.state.marginInternaInferior - 40)) * 2));
          let height_page =
            model.numberColumns == 1
              ? 1103 - model.marginInternalUp - model.marginInternalDown - 67
              : (1103 -
                model.marginInternalUp -
                model.marginInternalDown -
                67) *
              2;

          let pages: any = [];
          let default_margin = 0;
          let line_height = 0;

          let page: any = null;
          let heights = [];
          for (let i = 0; i < pages_all.length; i++) {
            let element = pages_all[i];
            if (page == null) {
              page = {
                ids: [],
                elements: [],
                height: 0,
                heights: [],
              };
            }
            //checa categoria
            if (
              page.height < height_page / 2 &&
              document.getElementById(element)!.innerHTML!.includes("<img ") &&
              page.height +
              document.getElementById(element)!.offsetHeight +
              default_margin >
              height_page / 2 &&
              model.numberColumns != 1
            ) {
              let div_element: any = document.createElement("div");
              console.log("passou do limite");
              console.log(document.getElementById(element));
              div_element.style.height =
                height_page / 2 - page.height - 1 + "px";
              // div_element.style.background='yellow';
              div_element.style.maxWidth =
                model.numberColumns == 1
                  ? width_one_column + "px"
                  : width_two_column + "px";

              let id = parseInt((Math.random() * 100000).toString()).toString();
              let new_div: any = div_element.cloneNode();
              new_div.id = id;

              page.height += height_page / 2 - page.height;
              heights.push(height_page / 2 - page.height);
              page.elements.push(new_div);
              page.ids.push(id);
              page.heights.push(height_page / 2 - page.height);
              console.log(id);
            }
            if (
              page.height < height_page / 2 &&
              page.height + line_height + default_margin > height_page / 2 &&
              model.numberColumns != 1
            ) {
              let div_element = document.createElement("div");
              console.log("passou do limite2");
              console.log(document.getElementById(element));
              div_element.style.height =
                height_page / 2 - page.height - 1 + "px";
              // div_element.style.background='yellow';
              div_element.style.maxWidth =
                model.numberColumns == 1
                  ? width_one_column + "px"
                  : width_two_column + "px";

              let id = parseInt((Math.random() * 100000).toString()).toString();
              let new_div: any = div_element.cloneNode();
              new_div.id = id;

              page.height += height_page / 2 - page.height;
              heights.push(height_page / 2 - page.height);
              page.elements.push(new_div);
              page.ids.push(id);
              page.heights.push(height_page / 2 - page.height);

              console.log(id);
            }
            // if (
            //     document.getElementById(element)!.classList!.toString()!.includes('categoria') &&
            //     pages_all[i + 1] != undefined &&
            //     ((
            //         page.height + document.getElementById(element)!.offsetHeight + document.getElementById(pages_all[i + 1])!.offsetHeight + default_margin > (height_page / 2)
            //     ) && page.height < height_page / 2)

            //     && model.numberColumns != 1) {
            //     let div_element = document.createElement('div');
            //     console.log('passou do limite3');
            //     console.log(document.getElementById(element))
            //     console.log({...page})
            //     console.log(document.getElementById(element)!.offsetHeight)
            //     console.log(document.getElementById(pages_all[i + 1])!.offsetHeight)
            //     console.log([...pages_all])
            //     console.log(height_page)

            //     div_element.style.height = ((height_page / 2) - page.height - 1) + 'px';
            //     // div_element.style.background='yellow';
            //     div_element.style.maxWidth = model.numberColumns == 1 ? (width_one_column + 'px') : (width_two_column + 'px');

            //     let id = parseInt((Math.random() * 100000).toString()).toString();
            //     let new_div: any = div_element.cloneNode();
            //     new_div.id = id;

            //     page.height += (height_page / 2) - page.height;
            //     heights.push((height_page / 2) - page.height)
            //     page.elements.push(new_div)
            //     page.ids.push(id);
            //     page.heights.push((height_page / 2) - page.height)

            //     console.log(id);
            // }

            // if (
            //     document.getElementById(element)!.classList!.toString()!.includes('categoria') &&
            //     pages_all[i + 1] != undefined &&
            //     (page.height + document.getElementById(element)!.offsetHeight + document.getElementById(pages_all[i + 1])!.offsetHeight + default_margin > height_page)

            //     && model.numberColumns != 1) {

            //     let div_element = document.createElement('div');
            //     console.log('passou do limite4');
            //     console.log(document.getElementById(element))
            //     div_element.style.height = ((height_page) - page.height - 1) + 'px';
            //     // div_element.style.background='yellow';
            //     div_element.style.maxWidth = model.numberColumns == 1 ? (width_one_column + 'px') : (width_two_column + 'px');

            //     let id = parseInt((Math.random() * 100000).toString()).toString();
            //     let new_div: any = div_element.cloneNode();
            //     new_div.id = id;

            //     page.height += (height_page) - page.height;
            //     heights.push((height_page) - page.height)
            //     page.elements.push(new_div)
            //     page.ids.push(id);
            //     page.heights.push((height_page) - page.height)

            //     console.log(id);
            //     pages.push(page);
            //     page = {
            //         ids: [],
            //         elements: [],
            //         height: 0,
            //         heights: []

            //     }
            // }
            if (
              page.height +
              document.getElementById(element)!.offsetHeight +
              default_margin <
              height_page
            ) {
              let obj: any = document.getElementById(element);
              page.height += obj!.offsetHeight + default_margin;
              heights.push(obj!.offsetHeight);
              let element2: any = obj!.cloneNode(true);
              element2.style.maxWidth =
                model.numberColumns == 1
                  ? width_one_column + "px"
                  : width_two_column + "px";
              page.elements.push(element2);
              page.ids.push(element);
              page.heights.push(obj!.offsetHeight + default_margin);

              // if (pages.length == 7) {
              //     length_elements.push(document.getElementById(element).offsetHeight)
              // }
            } else {
              pages.push(page);
              page = {
                ids: [],
                elements: [],
                height: 0,
                heights: [],
              };
              let obj: any = document.getElementById(element);

              if (
                page.height + obj!.offsetHeight + default_margin <
                height_page
              ) {
                heights.push(Math.round(obj!.offsetHeight));

                page.height += obj!.offsetHeight + default_margin;
                let element2 = obj!.cloneNode(true);
                element2.style.maxWidth =
                  model.numberColumns == 1
                    ? width_one_column + "px"
                    : width_two_column + "px";
                page.elements.push(element2);
                page.ids.push(element);
                page.heights.push(
                  Math.round(obj!.offsetHeight) + default_margin
                );
                // if (pages.length == 7) {
                //     length_elements.push(document.getElementById(element).offsetHeight)
                // }
              }
            }
          }
          if (page != null && page.height != 0) {
            pages.push(page);
          }
          console.log(heights);
          dispatch(
            setState({ pages_new: pages, pages: [], loading_page: false })
          );
        } catch (e) {
          console.log(e);
        }
      }, 1000);
    } else if (loading_page == true) {
      dispatch(setState({ pages_new: [], pages: [], loading_page: false }));
    }
  }, [pages, pages_new]);

  const generatePDF = async () => {
    dispatch(setState({ loading_pdf: true }));

    if (generate_pdf_image == false) {
      var doc = new jsPDF("p", "pt", [784, 1113]);

      await doc.html(
        document.getElementById("pdf_diagramado_2")!.parentElement ?? ""
      );
      doc.save("diagramacao_" + model.name + ".pdf");
      dispatch(setState({ loading_pdf: false }));
      return;
    } else {
      var doc = new jsPDF("p", "pt", "a4");

      for (let page = 0; page < pages_new.length + 1; page++) {
        const element = document.getElementById("page_" + page);
        // Choose the element and save the PDF for our user.
        let canvas = await html2pdf()
          .from(element)
          .set({
            // margin:       [10, 10, 10, 10],
            filename: "samplepdf.pdf",
            pagebreak: { mode: "css", after: ".nextpage1" },
            jsPDF: { unit: "pt", format: "a4", orientation: "portrait" },
            image: { type: "jpeg", quality: 0.5 },
            html2canvas: {
              dpi: 96,
              scale: 4,
              letterRendering: true,
              useCORS: true,
            },
          })
          .toImg()
          .get("canvas");
        let height_page = canvas.height;
        // var canvas1 = document.createElement('canvas');
        // var ctx1 = canvas1.getContext('2d');
        // canvas1.width = canvas.width;
        // canvas1.height = height_page;
        // let cropX = 0;
        // let cropY = page * height_page;
        // let cropWidth = canvas.width;
        // let cropHeight = height_page;
        // // use the extended from of drawImage to draw the
        // // cropped area to the temp canvas
        // ctx1.drawImage(canvas, cropX, cropY, cropWidth, cropHeight, 0, 0, cropWidth, cropHeight);
        // return the .toDataURL of the temp canvas
        console.log("executou");
        console.log(canvas);
        try {
          let image = canvas.toDataURL("image/jpeg", 0.5);
          console.log(image);

          const pageWidth = doc.internal.pageSize.getWidth();
          const pageHeight = doc.internal.pageSize.getHeight();

          const widthRatio = pageWidth / canvas.width;
          const heightRatio = pageHeight / height_page;
          const ratio = widthRatio > heightRatio ? heightRatio : widthRatio;

          const canvasWidth = canvas.width * ratio;
          const canvasHeight = height_page * ratio;

          const marginX = (pageWidth + 30 - canvasWidth) / 2;
          const marginY = (pageHeight - canvasHeight) / 2;
          console.log(marginX);
          console.log(marginY);
          console.log(pageWidth);
          console.log(pageHeight);
          console.log(canvasWidth);
          console.log(canvasHeight);

          doc.addImage(image, "JPEG", marginX, 15, canvasWidth, canvasHeight);
          if (page + 1 < pages_new.length + 1) {
            doc.addPage();
            doc.setPage(page + 2);
          }
          console.log("executou2");
        } catch (e) {
          console.log(e);
        }
      }
      dispatch(setState({ loading_pdf: false }));

      doc.save("diagramacao_" + model.name + ".pdf");
    }

    // var doc = new jsPDF("p", "pt",'a4');

    // var width = doc.internal.pageSize.getWidth();
    // var height = doc.internal.pageSize.getHeight();
    // doc.html(document.getElementById('pdf_diagramado_2').innerHTML,{
    //     x:0,y:0,
    //     width:794,
    //     height:1123,
    //     callback: function (doc) {
    //         doc.save();
    //       }

    // })
    // console.log(width,height);
    // document.getElementById('iframe').srcdoc=document.getElementById('pdf_diagramado_2').innerHTML;
  };
  const configs_array = useRef<any>();
  const config_rules_answer = useRef<any>();

  const load_configs = () => {
    configs_array.current = [];
    configs_array.current.push(config_rules_answer);
    console.log(token);
    for (let i = 0; i < 1; i++) {
      configs_array.current[i] = {
        height: 300,
        allowResizeTags: ["img", "iframe", "table", "jodit"],
        resizer: {
          // useAspectRatio: false, // don't save,
          // useAspectRatio: ['img'], // save only for images (default value)
          // useAspectRatio: true, // save for all
          showSize: true,
          useAspectRatio: true,
        },
        tabIndex: 1,
        askBeforePasteHTML: false,
        askBeforePasteFromWord: false,
        defaultActionOnPaste: "insert_only_text",
        defaultActionOnPasteFromWord: "insert_only_text",
        pasteHTMLActionList: [
          { value: "insert_only_text", text: "Insert only Text" },
        ],
        pasteFromWordActionList: [
          { value: "insert_only_text", text: "Insert only Text" },
        ],
        readonly: loadings_images[i],
        language: "pt_br",
        toolbarButtonSize: "large",
        buttons:
          "source,bold,italic,underline,strikethrough,eraser,superscript,subscript,ul,ol,indent,outdent,left,center,right,justify,font,fontsize,paragraph,classSpan,image,brush,,undo,redo,hr,link,symbol,table",
        uploader: {
          url: API_URI + "/questions/file", //your upload api url
          insertImageAsBase64URI: false, //not inster base64
          imagesExtensions: ["jpg", "png", "jpeg", "gif"],
          headers: { accept: `application/json`, Authorization: token },
          filesVariableName: function (t: any) {
            return "file";
          }, //"files",
          withCredentials: false,
          pathVariableName: "path",
          //   format: 'json',
          method: "POST",
          prepareData: (formdata: any) => {
            console.log(formdata);
            let loadings_ = [...loadings_images];
            loadings_[i] = true;
            dispatch(setState({ loadings_images: loadings_ }));
            return formdata;
          },
          isSuccess: (e: any) => {
            console.log(e);

            return "Upload realizado com sucesso";
          },
          getMessage: (e: any) => {
            console.log(e);

            return e;
          },
          process: (resp: any) => {
            //success callback transfrom data to defaultHandlerSuccess use.it's up to you.
            let files: any[] = [];
            console.log(resp);
            files.unshift(STORAGE_URL + "/" + resp.data);
            let loadings_ = [...loadings_images];
            loadings_[i] = false;
            dispatch(setState({ loadings_images: loadings_ }));

            return {
              files: files,
            };
          },
          error: (elem: any, e: any) => {
            // elem.j.e.fire('errorMessage', e.message, 'error', 4000);

            console.log(e);
            console.log(elem);
            console.log("Bearer " + localStorage.getItem("token"));
          },
          defaultHandlerSuccess: function (resp: any, elem: any) {
            // `this` is the editor.
            let t: any = this;
            if (resp.files && resp.files.length) {
              resp.files.forEach((filename: any, index: any) => {
                //edetor insertimg function
                t.selection.insertImage(filename);
              });
            }

            // debugger;
          },
          defaultHandlerError: (elem: any, e: any) => {
            // elem.j.e.fire('errorMessage', e.message);
            console.log(e);
            console.log(elem);
            // this.setState({ loading_image_enunciado: false });
            let loadings_ = [...loadings_images];
            loadings_[i] = false;
            dispatch(setState({ loadings_images: loadings_ }));
          },
          //   contentType: function (e) {
          //     return (

          //       'application/x-www-form-urlencoded; charset=UTF-8'
          //     );
          //   },
        },
      };
    }
  };

  const changeFontSizeQuestoes = (questao: any) => {
    let model_: any = { ...model };
    if (model_.fontSizeQuestions == "default") {
      return questao;
    } else {
      return questao.replace(/font-size/g, "");
    }
  };

  const changeFontSize = (questao: any) => {
    let model_: any = { ...model };
    return questao.replace(/font-size/g, "");
  };

  const changeFontSizeAlts = (questao: any) => {
    let model_: any = { ...model };
    if (model_.fontSizeAlternatives == "default") {
      return questao;
    } else {
      return questao.replace(/font-size/g, "");
    }
  };
  useEffect(() => {
    if (
      loading_page == false &&
      examSelect?.questions?.length > 0 &&
      model.exam_id != ""
    ) {
      console.log("executando initial images");
      console.log("executando initial images2");

      readInitialImages();
    }
  }, [questions]);

  useEffect(() => {
    if (Object.values(questions).length == 0) {
      return;
    }
    setTimeout(() => {
      console.log(examSelect);
      if (examSelect?.questions?.length > 0 && model.exam_id != "") {
        console.log("executando set pages");
        console.log("executando set pages 1");

        setPages();
      }
    }, 300);
  }, [
    examSelect._id,
    model.fontSizeRules,
    model.numberColumns,
    model.numberColumnsRules,
    model.fontSizeAlternatives,
    model.fontSizeQuestions,
    model.showDiscipline,
    model.marginInternalUp,
    model.marginInternalDown,
    model.marginInternalLeft,
    model.marginInternalRight,
    model.border_visible,
  ]);

  useEffect(() => {
    // parseQuestoesDragDrop()

    if (
      document.getElementById("header_page") != null &&
      offsetHeight != document.getElementById("header_page")!.offsetHeight
    ) {
      console.log("executando offeset");
      dispatch(
        setState({
          offsetHeight: document.getElementById("header_page")!.offsetHeight,
        })
      );
    }
  });
  let height_sample = 1103;
  let width_sample = 777;

  let number_questao = 0;
  let width_one_column = 771;
  // let width_two_column = 326;
  let width_two_column =
    (755 - model.marginInternalRight - model.marginInternalLeft) / 2;

  let pages_all: any = [];
  let questions_parsed: any = [];
  let questions_piece: any = [];
  // let number_questions_column = Math.ceil(examSelect.questions.map((item:any, id:any) => {
  //     if (item.type == 'set') {
  //         return item.questions.length;
  //     }
  //     else {
  //         return 1;
  //     }
  // }).reduce((a:any, b:any) => a + b, 0)/6)
  let number_questions_column = 20;
  examSelect.questions.map((item: any, id: any) => {
    if (item.type == "set") {
      item.questions.map((item2: any, id2: any) => {
        if (questions_piece.length == number_questions_column) {
          questions_parsed.push([...questions_piece]);
          questions_piece = [];
        }
        questions_piece.push(1);
      });
    } else {
      if (questions_piece.length == number_questions_column) {
        questions_parsed.push([...questions_piece]);
        questions_piece = [];
      }
      questions_piece.push(1);
    }
  });
  questions_parsed.push([...questions_piece]);

  console.log(questions_parsed);

  console.log(model);
  return (
    <div className={!props.justView ? "row" : ""}>
      {/* {this.state.redirect == true && <Redirect to={`/processos/show/${this.props.match.params.id}?tab=2`} />} */}
      <StyleQuestion />

      <div className={!props.justView ? "col-12" : ""}>
        <div className={!props.justView ? "padding_0" : ''} style={!props.justView ?{paddingTop:'3rem',paddingBottom:'3rem',paddingRight:'1rem',paddingLeft:'1rem'}:{}}>
          <div
            className={!props.justView ? "card-body" : ""}
            id="card"
            style={{ overflow: "visible" }}
          >
            <div className="hidethis">
              <div style={{ padding: props.justView ? 10 : 0 }}>
                <div hidden id="text_enunciado hidethis"></div>
                <h4
                  className="mt-0 header-title hidethis"
                  style={{ fontSize: "1.5rem", color: "black" }}
                >
                  {props.justView
                    ? "Visualizar"
                    : edit == true
                      ? "Editar"
                      : "Cadastrar"}{" "}
                  diagramação
                </h4>
                <p
                  className="hidethis"
                  style={{ fontSize: "1rem", color: "black" }}
                >
                  {props.justView
                    ? "Visualize uma diagramação existente de uma prova"
                    : edit == true
                      ? "Edite uma diagramação existente de uma prova"
                      : "Cadastre uma nova diagramação de uma prova"}
                </p>
                <br className="hidethis" />
                <QuestionModalView />

                {props.justView && (
                  <div className="d-flex justify-content-end">
                    <DefaultButton
                      type="button"
                      bg="info"
                      style={{ marginRight: 10 }}
                      onClick={() => {
                        // this.generatePDF();
                        window.print();
                        return undefined;
                      }}
                      text="Imprimir"
                      disabled={loading_pdf == true}
                      loadingtext="Imprindo..."
                      loading={loading_pdf}
                    />
                  </div>
                )}
              </div>

              {/* <ModalQuestao showModal={this.state.showModal} setShowModal={(e) => this.setState({ showModal: e })} questao={this.state.questaoSelect} /> */}
            </div>

            {/* <hr /> */}
            {loading_screen == false && (
              <div>
                {error_message != null && (
                  <div
                    style={{ display: "flex", marginBottom: 15, width: "100%" }}
                  >
                    <div
                      className="alert alert-danger alert-dismissible fade show mb-0 w-100"
                      role="alert"
                    >
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="alert"
                        aria-label="Close"
                        onClick={() => {
                          dispatch(setErrorMessage(null));
                          return undefined;
                        }}
                      ></button>

                      <p style={{ marginBottom: 0, whiteSpace: "pre-line" }}>
                        {error_message.join("\n")}
                      </p>
                    </div>
                    <br />
                  </div>
                )}
                {!props.justView && (
                  <InputGroup className="hidethis">
                    <div className="hidethis" style={{ width: "100%" }}>
                      <div className="flex fdcolumn flex1 margin-bottom hidethis">
                        <Form.Label className="hidethis">
                          Selecione uma prova
                        </Form.Label>

                        <select
                          required
                          disabled={loading_page}
                          className="form-select hidethis"
                          onChange={(e) => {
                            let model_: any = { ...model };

                            if (e.target.value != "") {
                              let examSelect: any = {
                                ...exams.filter(
                                  (item: any, id: any) =>
                                    item._id == e.target.value
                                )[0],
                              };
                              let questoes_selecionadas: any = {};
                              let disciplines: any = [];
                              model_.selected_disciplines = [];
                              examSelect.questions = [...examSelect.questions];
                              for (
                                let i = 0;
                                i < examSelect.questions.length;
                                i++
                              ) {
                                examSelect.questions[i] = {
                                  ...examSelect.questions[i],
                                };
                                examSelect.questions[i].question =
                                  examSelect.questions[i].question
                                    .replace(/<p><br><\/p>/gi, "", "")
                                    .replace(/<p><br\/><\/p>/gi, "");
                                examSelect.questions[i].questions = [
                                  ...examSelect.questions[i].questions,
                                ];

                                for (
                                  let j = 0;
                                  j < examSelect.questions[i].questions.length;
                                  j++
                                ) {
                                  examSelect.questions[i].questions[j] = {
                                    ...examSelect.questions[i].questions[j],
                                  };
                                  examSelect.questions[i].questions[
                                    j
                                  ].question = examSelect.questions[
                                    i
                                  ].questions[j].question
                                    .replace(/<p><br><\/p>/gi, "", "")
                                    .replace(/<p><br\/><\/p>/gi, "");
                                  examSelect.questions[i].questions[
                                    j
                                  ].alternatives = [
                                      ...examSelect.questions[i].questions[j]
                                        .alternatives,
                                    ];
                                  for (
                                    let k = 0;
                                    k <
                                    examSelect.questions[i].questions[j]
                                      .alternatives.length;
                                    k++
                                  ) {
                                    examSelect.questions[i].questions[
                                      j
                                    ].alternatives[k] = {
                                      ...examSelect.questions[i].questions[j]
                                        .alternatives[k],
                                    };
                                    examSelect.questions[i].questions[
                                      j
                                    ].alternatives[k].alternative =
                                      examSelect.questions[i].questions[
                                        j
                                      ].alternatives[k].alternative
                                        .replace(/<p><br><\/p>/gi, "", "")
                                        .replace(/<p><br\/><\/p>/gi, "");
                                  }
                                }
                                examSelect.questions[i].alternatives = [
                                  ...examSelect.questions[i].alternatives,
                                ];
                                for (
                                  let k = 0;
                                  k <
                                  examSelect.questions[i].alternatives.length;
                                  k++
                                ) {
                                  examSelect.questions[i].alternatives[k] = {
                                    ...examSelect.questions[i].alternatives[k],
                                  };
                                  examSelect.questions[i].alternatives[
                                    k
                                  ].alternative = examSelect.questions[
                                    i
                                  ].alternatives[k].alternative
                                    .replace(/<p><br><\/p>/gi, "", "")
                                    .replace(/<p><br\/><\/p>/gi, "");
                                }

                                questoes_selecionadas[
                                  "q_" + examSelect.questions[i]._id
                                ] = examSelect.questions[i];
                                if (
                                  !disciplines.includes(
                                    examSelect.questions[i].discipline_id
                                  )
                                ) {
                                  disciplines.push(
                                    examSelect.questions[i].discipline_id
                                  );
                                  model_.selected_disciplines.push({
                                    _id: examSelect.questions[i].discipline._id,
                                    name: examSelect.questions[i].discipline
                                      .name,
                                    order: model_.selected_disciplines.length,
                                    number: 1,
                                  });
                                }
                              }

                              model_.exam_id = e.target.value;
                              model_.answer_page_visible =
                                examSelect.type == "text" ? false : true;
                              model_.numberColumns =
                                examSelect.type == "text"
                                  ? 1
                                  : model_.numberColumns;

                              dispatch(
                                setState({
                                  model: model_,
                                  examSelect: examSelect,
                                  questions: questoes_selecionadas,
                                })
                              );
                            } else {
                              model_.exam_id = "";

                              dispatch(
                                setState({
                                  model: model_,
                                  examSelect: { id: "" },
                                  questionContent: "",
                                  questionsArray: [],
                                  pages: [],
                                  pages_new: [],
                                })
                              );
                            }
                          }}
                          value={examSelect._id}
                        >
                          <option className="hidethis" value="">
                            Selecione uma prova
                          </option>
                          {exams &&
                            exams.length != 0 &&
                            exams.map((value: any, index: any) => {
                              console.log(value);
                              return (
                                value &&
                                value["questions"] && (
                                  <option
                                    key={index}
                                    value={value["_id"]}
                                    className="hidethis"
                                  >
                                    {value["name"] +
                                      " - " +
                                      value["questions"]
                                        .map((item: any, id: any) => {
                                          if (item.type == "set") {
                                            return item.questions.length;
                                          } else {
                                            return 1;
                                          }
                                        })
                                        .reduce((a: any, b: any) => a + b, 0) +
                                      " " +
                                      (value["questions"].length == 1
                                        ? "questão"
                                        : "questões")}
                                  </option>
                                )
                              );
                            })}
                        </select>
                      </div>
                    </div>
                  </InputGroup>
                )}
                <Form
                  onSubmit={(e) => {
                    e.preventDefault();
                    saveContent();
                  }}
                  style={{ position: "relative" }}
                >
                  {!props.justView && (
                    <div className="hidethis">
                      <div>
                        <Form.Label
                          style={{
                            fontSize: 18,
                            marginBottom: 0,
                            fontWeight: 600,
                            textDecoration: "underline",
                          }}
                          className="config_layout"
                          onClick={() => {
                            dispatch(
                              setState({ showTextConfig: !showTextConfig })
                            );
                          }}
                        >
                          Configurações de texto (
                          {showTextConfig == false ? (
                            <span>
                              <MdArrowUpward /> Mostrar
                            </span>
                          ) : (
                            <>
                              <MdArrowDownward /> Esconder
                            </>
                          )}
                          ){" "}
                        </Form.Label>
                      </div>
                      {showTextConfig == true && (
                        <>
                          <br />

                          <InputGroup>
                            <div className="flex fdcolumn flex1 margin-bottom">
                              <Form.Label>
                                Código da prova (Ex: Prova A) <Attention />
                              </Form.Label>
                              <Input
                                placeholder="Código da prova (Ex: Prova A)"
                                value={model.codeExam}
                                onChange={(e) => {
                                  let model_: any = { ...model };
                                  model_.codeExam = e.target.value;
                                  dispatch(setModel(model_));
                                }}
                                disabled={loading_page}
                              // title="Coloque o nome completo do usuário"
                              />
                            </div>
                          </InputGroup>
                          <InputGroup>
                            <div className="flex fdcolumn flex1 margin-bottom">
                              <Form.Label>Título (Ex: Instituição) </Form.Label>
                              <Input
                                placeholder="Título. Ex: Escola X"
                                value={model.title}
                                onChange={(e) => {
                                  let model_: any = { ...model };
                                  model_.title = e.target.value;
                                  dispatch(setModel(model_));
                                }}
                                disabled={loading_page}
                              // title="Coloque o nome completo do usuário"
                              />
                            </div>
                          </InputGroup>
                          <InputGroup>
                            <div className="flex fdcolumn flex1 margin-bottom">
                              <Form.Label>Subtítulo (Ex: Local) </Form.Label>
                              <Input
                                placeholder="Subtítulo. Ex: Estado de São Paulo"
                                value={model.subtitle}
                                onChange={(e) => {
                                  let model_: any = { ...model };
                                  model_.subtitle = e.target.value;
                                  dispatch(setModel(model_));
                                }}
                                disabled={loading_page}
                              // title="Coloque o nome completo do usuário"
                              />
                            </div>
                          </InputGroup>
                          {/* <InputGroup>

                                <div className="flex fdcolumn flex1 margin-bottom">
                                    <Form.Label>PDF Image <Attention /></Form.Label>
                                    <FormCheck
                                        type='checkbox'
                                        disabled={this.state.loading_page}
                                        checked={this.state.generate_pdf_image}
                                        onChange={(e) => this.setState({ generate_pdf_image: e.target.checked }, () => {
                                            this.setPages();
                                        })}
                                        
                                    // title="Coloque o nome completo do usuário"
                                    />
                                </div>
                            </InputGroup> */}

                          <InputGroup>
                            <div className="flex fdcolumn flex1 margin-bottom">
                              <Form.Label>
                                Nome da prova <Attention />
                              </Form.Label>
                              <Input
                                placeholder="Nome da prova"
                                value={model.name}
                                onChange={(e) => {
                                  let model_: any = { ...model };
                                  model_.name = e.target.value;
                                  dispatch(setModel(model_));
                                }}
                                disabled={loading_page}
                              // title="Coloque o nome completo do usuário"
                              />
                            </div>
                          </InputGroup>

                          <InputGroup>
                            <div className="flex fdcolumn flex1 margin-bottom">
                              <Form.Label>
                                Título campo 1 (Ex: Cargo){" "}
                              </Form.Label>
                              <Input
                                placeholder="Título campo 1"
                                value={model.title_field_1}
                                onChange={(e) => {
                                  let model_: any = { ...model };
                                  model_.title_field_1 = e.target.value;
                                  dispatch(setModel(model_));
                                }}
                                disabled={loading_page}
                              // title="Coloque o nome completo do usuário"
                              />
                            </div>
                          </InputGroup>

                          <InputGroup>
                            <div className="flex fdcolumn flex1 margin-bottom">
                              <Form.Label>
                                Campo 1 (Ex: Cargo de Poílicia){" "}
                              </Form.Label>
                              <Input
                                placeholder="Campo 1"
                                value={model.field_1}
                                onChange={(e) => {
                                  let model_: any = { ...model };
                                  model_.field_1 = e.target.value;
                                  dispatch(setModel(model_));
                                }}
                                disabled={loading_page}
                              // title="Coloque o nome completo do usuário"
                              />
                            </div>
                          </InputGroup>

                          <InputGroup>
                            <div className="flex fdcolumn flex1 margin-bottom">
                              <Form.Label>
                                Título campo 2 (Ex: Turno){" "}
                              </Form.Label>
                              <Input
                                placeholder="Título campo 2"
                                value={model.title_field_2}
                                onChange={(e) => {
                                  let model_: any = { ...model };
                                  model_.title_field_2 = e.target.value;
                                  dispatch(setModel(model_));
                                }}
                                disabled={loading_page}
                              // title="Coloque o nome completo do usuário"
                              />
                            </div>
                          </InputGroup>

                          <InputGroup>
                            <div className="flex fdcolumn flex1 margin-bottom">
                              <Form.Label>Campo 2 (Ex: Vespertino) </Form.Label>
                              <Input
                                placeholder="Campo 2"
                                value={model.field_2}
                                onChange={(e) => {
                                  let model_: any = { ...model };
                                  model_.field_2 = e.target.value;
                                  dispatch(setModel(model_));
                                }}
                                disabled={loading_page}
                              // title="Coloque o nome completo do usuário"
                              />
                            </div>
                          </InputGroup>

                          <InputGroup>
                            <div className="flex fdcolumn flex1 margin-bottom">
                              <Form.Label>Título Cabeçalho</Form.Label>
                              <Input
                                placeholder="Título do Cabeçalho"
                                value={model.title_header}
                                onChange={(e) => {
                                  let model_: any = { ...model };
                                  model_.title_header = e.target.value;
                                  dispatch(setModel(model_));
                                }}
                                disabled={loading_page}
                              // title="Coloque o nome completo do usuário"
                              />
                            </div>
                          </InputGroup>

                          <InputGroup>
                            <div className="flex fdcolumn flex1 margin-bottom">
                              <Form.Label>Subtítulo Cabeçalho </Form.Label>
                              <Input
                                placeholder="Subtítulo do Cabeçalho"
                                value={model.subtitle_header}
                                onChange={(e) => {
                                  let model_: any = { ...model };
                                  model_.subtitle_header = e.target.value;
                                  dispatch(setModel(model_));
                                }}
                                disabled={loading_page}
                              // title="Coloque o nome completo do usuário"
                              />
                            </div>
                          </InputGroup>

                          <InputGroup>
                            <div className="flex fdcolumn flex1 margin-bottom">
                              <Form.Label>Instruções </Form.Label>

                              {/* <JoditEditor
                                        ref={this.editor}
                                        value={this.state.instrucoes}
                                        config={this.config}
                                        height={400}
                                        tabIndex={1} // tabIndex of textarea
                                        onBlur={newContent => this.setState({ instrucoes: newContent })} // preferred to use only this option to update the content for performance reasons
                                    // onChange={newContent => {this.setState({enunciado:newContent}) }}
                                    /> */}
                              <Input
                                placeholder="Instruções"
                                value={model.rules}
                                onChange={(e) => {
                                  let model_: any = { ...model };
                                  model_.rules = e.target.value;
                                  dispatch(setModel(model_));
                                }}
                                as="textarea"
                                style={{ minHeight: 200 }}
                                disabled={loading_page}
                              // title="Coloque o nome completo do usuário"
                              />
                            </div>
                          </InputGroup>
                        </>
                      )}

                      <div>
                        <hr />
                        <Form.Label
                          style={{
                            fontSize: 18,
                            marginBottom: 0,
                            fontWeight: 600,
                            textDecoration: "underline",
                          }}
                          className="config_layout"
                          onClick={() => {
                            dispatch(
                              setState({ showPageConfig: !showPageConfig })
                            );
                          }}
                        >
                          Configurações da prova (
                          {showPageConfig == false ? (
                            <>
                              <MdArrowUpward /> Mostrar
                            </>
                          ) : (
                            <>
                              <MdArrowDownward /> Esconder
                            </>
                          )}
                          ){" "}
                        </Form.Label>
                      </div>
                      {showPageConfig == true && (
                        <>
                          <br />
                          <Form.Label>Logo Superior </Form.Label>
                          <ImageLoaderInput
                            disabled={loading_page}
                            path={model.logo_path}
                            width_="100%"
                            height_="200px"
                            ImageChange={(image: any) => {
                              let model_: any = { ...model };
                              model_.logo = image;
                              dispatch(setModel(model_));
                            }}
                          />

                          <Form.Label>Logo Inferior </Form.Label>
                          <ImageLoaderInput
                            disabled={loading_page}
                            path={model.logo_path_bottom}
                            width_="100%"
                            height_="200px"
                            ImageChange={(image: any) => {
                              let model_: any = { ...model };
                              model_.logo_bottom = image;
                              dispatch(setModel(model_));
                            }}
                          />
                          <div className="row">
                            <div className="col-12 col-md-6 margin-bottom">
                              <Form.Label>
                                Tamanho da fonte das instruções
                              </Form.Label>

                              <select
                                disabled={loading_page}
                                className="form-select "
                                onChange={(e) => {
                                  let model_: any = { ...model };
                                  model_.fontSizeRules = parseInt(
                                    e.target.value
                                  );
                                  dispatch(setModel(model_));
                                }}
                                value={model.fontSizeRules}
                              >
                                {new Array(21).fill(0).map((value, index) => (
                                  <option key={index} value={index + 9}>
                                    {index + 9}px
                                  </option>
                                ))}
                              </select>
                            </div>
                            <div className="col-12 col-md-6 margin-bottom">
                              <Form.Label>
                                Número de colunas das instruções
                              </Form.Label>

                              <select
                                disabled={loading_page}
                                className="form-select "
                                onChange={(e) => {
                                  let model_: any = { ...model };
                                  model_.numberColumnsRules = parseInt(
                                    e.target.value
                                  );
                                  dispatch(setModel(model_));
                                }}
                                value={model.numberColumnsRules}
                              >
                                <option value={1}>1 coluna</option>
                                <option value={2}>2 colunas</option>
                              </select>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-12 col-md-6 margin-bottom">
                              <Form.Label>
                                Número de colunas da Avaliação
                              </Form.Label>

                              <select
                                disabled={
                                  loading_page || examSelect?.type == "text"
                                }
                                className="form-select "
                                onChange={(e) => {
                                  let model_: any = { ...model };
                                  model_.numberColumns = parseInt(
                                    e.target.value
                                  );
                                  dispatch(setModel(model_));
                                }}
                                value={model.numberColumns}
                              >
                                <option value={1}>1 coluna</option>
                                <option value={2}>2 colunas</option>
                              </select>
                            </div>
                            <div className="col-12 col-md-6 margin-bottom">
                              <Form.Label>
                                Tamanho da fonte das questões
                              </Form.Label>

                              <select
                                disabled={loading_page}
                                className="form-select "
                                onChange={(e) => {
                                  let model_: any = { ...model };
                                  model_.fontSizeQuestions =
                                    e.target.value == "default"
                                      ? "default"
                                      : parseInt(e.target.value);
                                  dispatch(setModel(model_));
                                }}
                                value={model.fontSizeQuestions}
                              >
                                <option value="default">
                                  Tamanho escolhido no cadastro da questão
                                </option>
                                {new Array(21).fill(0).map((value, index) => (
                                  <option key={index} value={index + 9}>
                                    {index + 9}px
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-12 col-md-6 margin-bottom">
                              <Form.Label>
                                Tamanho da fonte das alternativas
                              </Form.Label>

                              <select
                                disabled={loading_page}
                                className="form-select "
                                onChange={(e) => {
                                  let model_: any = { ...model };
                                  model_.fontSizeAlternatives =
                                    e.target.value == "default"
                                      ? "default"
                                      : parseInt(e.target.value);
                                  dispatch(setModel(model_));
                                }}
                                value={model.fontSizeAlternatives}
                              >
                                <option value="default">
                                  Tamanho escolhido no cadastro da alternativa
                                </option>
                                {new Array(21).fill(0).map((value, index) => (
                                  <option key={index} value={index + 9}>
                                    {index + 9}px
                                  </option>
                                ))}
                              </select>
                            </div>
                            <div className="col-12 col-md-6 margin-bottom">
                              <Form.Label>Mostrar disciplina?</Form.Label>
                              <select
                                disabled={loading_page}
                                className="form-select "
                                onChange={(e) => {
                                  let model_: any = { ...model };
                                  model_.showDiscipline =
                                    e.target.value == "true" ? true : false;
                                  dispatch(setModel(model_));
                                }}
                                value={model.showDiscipline.toString()}
                              >
                                <option value={"true"}>Sim</option>
                                <option value={"false"}>Não</option>
                              </select>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-12 col-md-6 margin-bottom">
                              <Form.Label>Margem Superior</Form.Label>

                              <select
                                disabled={loading_page}
                                className="form-select "
                                onChange={(e) => {
                                  let model_: any = { ...model };
                                  model_.marginInternalUp = parseInt(
                                    e.target.value
                                  );
                                  dispatch(setModel(model_));
                                }}
                                value={model.marginInternalUp}
                              >
                                {new Array(81).fill(0).map((value, index) => (
                                  <option key={index} value={index}>
                                    {index}px
                                  </option>
                                ))}
                              </select>
                            </div>
                            <div className="col-12 col-md-6 margin-bottom">
                              <Form.Label>Margem Inferior</Form.Label>

                              <select
                                disabled={loading_page}
                                className="form-select "
                                onChange={(e) => {
                                  let model_: any = { ...model };
                                  model_.marginInternalDown = parseInt(
                                    e.target.value
                                  );
                                  dispatch(setModel(model_));
                                }}
                                value={model.marginInternalDown}
                              >
                                {new Array(81).fill(0).map((value, index) => (
                                  <option key={index} value={index}>
                                    {index}px
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>

                          <div className="row ">
                            <div className="col-12 col-md-6 margin-bottom">
                              <Form.Label>Margem Esquerda</Form.Label>

                              <select
                                disabled={loading_page}
                                className="form-select "
                                onChange={(e) => {
                                  let model_: any = { ...model };
                                  model_.marginInternalLeft = parseInt(
                                    e.target.value
                                  );
                                  dispatch(setModel(model_));
                                }}
                                value={model.marginInternalLeft}
                              >
                                {new Array(81).fill(0).map((value, index) => (
                                  <option key={index} value={index}>
                                    {index}px
                                  </option>
                                ))}
                              </select>
                            </div>
                            <div className="col-12 col-md-6 margin-bottom">
                              <Form.Label>Margem Direita</Form.Label>

                              <select
                                disabled={loading_page}
                                className="form-select "
                                onChange={(e) => {
                                  let model_: any = { ...model };
                                  model_.marginInternalRight = parseInt(
                                    e.target.value
                                  );
                                  dispatch(setModel(model_));
                                }}
                                value={model.marginInternalRight}
                              >
                                {new Array(81).fill(0).map((value, index) => (
                                  <option key={index} value={index}>
                                    {index}px
                                  </option>
                                ))}
                              </select>
                            </div>

                            <div className="col-12 col-md-6 margin-bottom">
                              <Form.Label>Borda visível?</Form.Label>

                              <select
                                disabled={loading_page}
                                className="form-select "
                                onChange={(e) => {
                                  let model_: any = { ...model };
                                  model_.border_visible =
                                    e.target.value == "true" ? true : false;
                                  dispatch(setModel(model_));
                                }}
                                value={model.border_visible.toString()}
                              >
                                <option value={"true"}>Sim</option>
                                <option value={"false"}>Não</option>
                              </select>
                            </div>

                            {(me.role === UserRole.SUPER_USER ||
                              me.role === UserRole.ADMIN_USER ||
                              me.role === UserRole.COLABORATOR_USER) && (
                                <div className="col-12 col-md-6 margin-bottom">
                                  <Form.Label>
                                    Salvar diagramação como padrão?
                                  </Form.Label>

                                  <select
                                    className="form-select "
                                    onChange={(e) => {
                                      let model_: any = { ...model };
                                      model_.default_layout =
                                        e.target.value == "true" ? true : false;
                                      dispatch(setModel(model_));
                                    }}
                                    value={model.default_layout.toString()}
                                  >
                                    <option value={"true"}>Sim</option>
                                    <option value={"false"}>Não</option>
                                  </select>
                                </div>
                              )}

                            {model.default_layout == true &&
                              (me.role === UserRole.SUPER_USER ||
                                me.role === UserRole.ADMIN_USER ||
                                me.role === UserRole.COLABORATOR_USER) && (
                                <div className="col-12 col-md-6 margin-bottom">
                                  <Form.Label>Nome do padrão</Form.Label>

                                  <Input
                                    placeholder="Nome do padrão"
                                    value={model.name_default}
                                    onChange={(e) => {
                                      let model_: any = { ...model };
                                      model_.name_default = e.target.value;
                                      dispatch(setModel(model_));
                                    }}
                                    required
                                  // title="Coloque o nome completo do usuário"
                                  />
                                </div>
                              )}
                          </div>

                          <InputGroup>
                            <div className="flex fdcolumn flex1 margin-bottom">
                              <Form.Label>
                                Ordem das questões <Attention />
                              </Form.Label>

                              <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                name="radio-buttons-group"
                                value={model.random_question}
                                onChange={(e) => {
                                  // console.log(e.target.value);
                                  // dispatch(setState({ random_question: e.target.value }));
                                }}
                              >
                                <FormControlLabel
                                  onClick={(e) => {
                                    let model_: any = { ...model };
                                    model_.random_question = false;
                                    dispatch(setModel(model_));
                                  }}
                                  control={
                                    <Radio
                                      checked={model.random_question == false}
                                      value={false}
                                    />
                                  }
                                  style={{ color: "black" }}
                                  label={"Questões na ordem de cadastro"}
                                />
                                <FormControlLabel
                                  onClick={(e) => {
                                    let model_: any = { ...model };
                                    model_.random_question = true;
                                    dispatch(setModel(model_));
                                  }}
                                  control={
                                    <Radio
                                      checked={model.random_question == true}
                                      value={true}
                                    />
                                  }
                                  style={{ color: "black" }}
                                  label={"Questões em ordem aleatória"}
                                />
                              </RadioGroup>
                            </div>
                            <div className="flex fdcolumn flex1 margin-bottom"></div>
                          </InputGroup>
                          {model.random_question == true && (
                            <InputGroup>
                              <div className="flex fdcolumn flex1 margin-bottom">
                                <Form.Label>
                                  Seleção das questões <Attention />
                                </Form.Label>
                                <RadioGroup
                                  aria-labelledby="demo-radio-buttons-group-label"
                                  name="radio-buttons-group1"
                                  value={model.select_from_group}
                                  onChange={(e) => {
                                    console.log(e.target.value);
                                  }}
                                >
                                  <FormControlLabel
                                    onClick={(e) => {
                                      let model_: any = { ...model };
                                      model_.select_from_group = false;
                                      dispatch(setModel(model_));
                                    }}
                                    control={
                                      <Radio
                                        checked={
                                          model.select_from_group == false
                                        }
                                        value={false}
                                      />
                                    }
                                    style={{ color: "black" }}
                                    label={"Usar todas questões selecionadas"}
                                  />
                                  <FormControlLabel
                                    onClick={(e) => {
                                      let model_: any = { ...model };
                                      model_.select_from_group = true;
                                      dispatch(setModel(model_));
                                    }}
                                    control={
                                      <Radio
                                        checked={
                                          model.select_from_group == true
                                        }
                                        value={true}
                                      />
                                    }
                                    style={{ color: "black" }}
                                    label={
                                      "Selecionar questões aleatórias para a prova de cada candidato"
                                    }
                                  />
                                  {/* <Form.Label>Número de questões por prova <Attention /></Form.Label> */}
                                </RadioGroup>
                              </div>
                              <div className="flex fdcolumn flex1 margin-bottom">
                                <Form.Label>
                                  Forma de aleatorização <Attention />
                                </Form.Label>
                                <RadioGroup
                                  aria-labelledby="demo-radio-buttons-group-label"
                                  name="radio-buttons-group0"
                                  value={model.random_per_category}
                                  onChange={(e) => {
                                    console.log(e.target.value);
                                  }}
                                >
                                  <FormControlLabel
                                    onClick={(e) => {
                                      let model_: any = { ...model };
                                      model_.random_per_category = false;
                                      dispatch(setModel(model_));
                                    }}
                                    control={
                                      <Radio
                                        checked={
                                          model.random_per_category == false
                                        }
                                        value={false}
                                      />
                                    }
                                    style={{ color: "black" }}
                                    label={"Seguindo a ordem das disciplinas"}
                                  />
                                  <FormControlLabel
                                    onClick={(e) => {
                                      let model_: any = { ...model };
                                      model_.random_per_category = true;
                                      dispatch(setModel(model_));
                                    }}
                                    control={
                                      <Radio
                                        checked={
                                          model.random_per_category == true
                                        }
                                        value={true}
                                      />
                                    }
                                    style={{ color: "black" }}
                                    label={
                                      "Questões de forma aleatória independente da disciplina"
                                    }
                                  />
                                </RadioGroup>
                                {model.random_per_category == false && (
                                  <>
                                    <Form.Label className="mt-1">
                                      Ordem das disciplinas:{" "}
                                    </Form.Label>
                                    {[...model.selected_disciplines]
                                      .sort(
                                        (a: any, b: any) => a.order - b.order
                                      )
                                      .map((item: any, id: any) => (
                                        <div
                                          key={id}
                                          className="d-flex justify-content-between mt-2"
                                        >
                                          <p>{item.name}</p>
                                          <div
                                            style={{
                                              marginRight: 15,
                                              display: "flex",
                                            }}
                                          >
                                            {model.select_from_group ==
                                              true && (
                                                <Input
                                                  onWheel={(e: any) =>
                                                    e.target.blur()
                                                  }
                                                  placeholder="Número de questões que devem ser selecionadas"
                                                  value={item.number}
                                                  type="number"
                                                  min={1}
                                                  onChange={(e: any) => {
                                                    let model_: any = {
                                                      ...model,
                                                    };
                                                    let disciplines = [
                                                      ...model_.selected_disciplines,
                                                    ];
                                                    model_.selected_disciplines =
                                                      disciplines.map(
                                                        (
                                                          item2: any,
                                                          id2: any
                                                        ) => {
                                                          let item3 = {
                                                            ...item2,
                                                          };
                                                          if (
                                                            item2._id == item._id
                                                          ) {
                                                            item3.number =
                                                              e.target.value;
                                                          }
                                                          return item3;
                                                        }
                                                      );
                                                    dispatch(setModel(model_));
                                                  }}
                                                  required
                                                  style={{ maxWidth: 80 }}
                                                // max={Object.values(this.state.questoes_selecionadas).filter((item2,id2)=>{
                                                //     if(item2.categoria_id==item.id){
                                                //         return true;
                                                //     }
                                                //     return false;
                                                // }).map((item2, id2) => {
                                                //     if (item2.type_questao == 'conjunto') {
                                                //         return item2.questoes.length;
                                                //     }
                                                //     else {
                                                //         return 1;
                                                //     }
                                                // }).reduce((a, b) => a + b, 0)}

                                                // title="Coloque o nome completo do usuário"
                                                />
                                              )}
                                            <MdArrowDropUp
                                              style={{
                                                cursor: "pointer",
                                                color:
                                                  id == 0 ? "grey" : "black",
                                              }}
                                              className="click_input"
                                              onClick={() => {
                                                // if(item.order)
                                                let first = true;
                                                let model_: any = { ...model };

                                                let disciplines = [
                                                  ...model_.selected_disciplines,
                                                ].sort(
                                                  (a: any, b: any) =>
                                                    a.order - b.order
                                                );
                                                let item4 = { ...item };

                                                let categorias_selecionadas =
                                                  disciplines.map(
                                                    (item2: any, id2: any) => {
                                                      let item3 = { ...item2 };
                                                      if (
                                                        id2 + 1 == id &&
                                                        first == true
                                                      ) {
                                                        let order = item4.order;
                                                        console.log(
                                                          order,
                                                          item3.order
                                                        );

                                                        item4.order =
                                                          item3.order;
                                                        item3.order = order;
                                                        first = false;
                                                        console.log(
                                                          "order changed"
                                                        );
                                                      }
                                                      return item3;
                                                    }
                                                  );
                                                console.log(
                                                  categorias_selecionadas
                                                );

                                                categorias_selecionadas =
                                                  categorias_selecionadas.map(
                                                    (item2: any, id2: any) => {
                                                      if (
                                                        item2._id == item4._id
                                                      ) {
                                                        return item4;
                                                      }
                                                      return item2;
                                                    }
                                                  );
                                                model_.selected_disciplines =
                                                  categorias_selecionadas;
                                                console.log(
                                                  categorias_selecionadas
                                                );

                                                dispatch(setModel(model_));
                                              }}
                                              size={30}
                                            />
                                            <MdArrowDropDown
                                              className="click_input"
                                              style={{
                                                cursor: "pointer",
                                                color:
                                                  id ==
                                                    model.selected_disciplines
                                                      .length -
                                                    1
                                                    ? "grey"
                                                    : "black",
                                              }}
                                              onClick={() => {
                                                let first = true;
                                                let model_: any = { ...model };
                                                let disciplines = [
                                                  ...model_.selected_disciplines,
                                                ].sort(
                                                  (a: any, b: any) =>
                                                    a.order - b.order
                                                );
                                                let item4 = { ...item };

                                                let categorias_selecionadas =
                                                  disciplines.map(
                                                    (item2: any, id2: any) => {
                                                      let item3 = { ...item2 };

                                                      if (
                                                        id2 - 1 == id &&
                                                        first == true
                                                      ) {
                                                        let order = item4.order;
                                                        item4.order =
                                                          item3.order;
                                                        item3.order = order;
                                                        console.log(item4);
                                                        console.log(item3);

                                                        first = false;
                                                        console.log("entrou");
                                                      }
                                                      return item3;
                                                    }
                                                  );
                                                console.log([
                                                  ...categorias_selecionadas,
                                                ]);

                                                categorias_selecionadas =
                                                  categorias_selecionadas.map(
                                                    (item2: any, id2: any) => {
                                                      if (
                                                        item2._id == item4._id
                                                      ) {
                                                        return item4;
                                                      }
                                                      return item2;
                                                    }
                                                  );
                                                console.log(
                                                  categorias_selecionadas
                                                );
                                                model_.selected_disciplines =
                                                  categorias_selecionadas;
                                                dispatch(setModel(model_));
                                              }}
                                              size={30}
                                            />
                                          </div>
                                        </div>
                                      ))}
                                    {model.selected_disciplines.length == 0 && (
                                      <div>
                                        <p>Nenhuma questão selecionada</p>
                                      </div>
                                    )}
                                  </>
                                )}

                                {model.select_from_group == true &&
                                  model.random_per_category == true && (
                                    <>
                                      <Form.Label className="mt-1">
                                        Número de questões que devem ser
                                        selecionadas:{" "}
                                      </Form.Label>

                                      <Input
                                        onWheel={(e: any) => e.target.blur()}
                                        placeholder="Número de questões que devem ser selecionadas"
                                        value={model.number_select_questions}
                                        type="number"
                                        onChange={(e) => {
                                          let model_: any = { ...model };
                                          model_.number_select_questions =
                                            e.target.value;
                                          dispatch(setModel(model_));
                                        }}
                                        required
                                        min={1}
                                        max={examSelect.questions
                                          .map((item: any, id: any) => {
                                            if (item.type == "set") {
                                              return item.questions.length;
                                            } else {
                                              return 1;
                                            }
                                          })
                                          .reduce((a: any, b: any) => a + b, 0)}
                                      // title="Coloque o nome completo do usuário"
                                      />
                                    </>
                                  )}
                              </div>
                            </InputGroup>
                          )}
                          <InputGroup>
                            <div className="flex fdcolumn flex1 margin-bottom">
                              <Form.Label>
                                Ordem das alternativas <Attention />
                              </Form.Label>
                              <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                name="radio-buttons-group2"
                                value={model.random_alternatives}
                                onChange={(e) => {
                                  console.log(e.target.value);
                                  let model_: any = { ...model };
                                  model_.random_alternatives = e.target.value;
                                  dispatch(setModel(model_));
                                }}
                              >
                                <FormControlLabel
                                  onClick={(e) => {
                                    let model_: any = { ...model };
                                    model_.random_alternatives = false;
                                    dispatch(setModel(model_));
                                  }}
                                  control={
                                    <Radio
                                      checked={
                                        model.random_alternatives == false
                                      }
                                      value={false}
                                    />
                                  }
                                  style={{ color: "black" }}
                                  label={"Alternativas na ordem de cadastro"}
                                />
                                <FormControlLabel
                                  onClick={(e) => {
                                    let model_: any = { ...model };
                                    model_.random_alternatives = true;
                                    dispatch(setModel(model_));
                                  }}
                                  control={
                                    <Radio
                                      checked={
                                        model.random_alternatives == true
                                      }
                                      value={true}
                                    />
                                  }
                                  style={{ color: "black" }}
                                  label={"Alternativas em ordem aleatória"}
                                />
                              </RadioGroup>
                            </div>
                          </InputGroup>
                          <br />
                        </>
                      )}

                      <div>
                        <hr />

                        <Form.Label
                          style={{
                            fontSize: 18,
                            marginBottom: 0,
                            fontWeight: 600,
                            textDecoration: "underline",
                          }}
                          className="config_layout"
                          onClick={() => {
                            dispatch(
                              setState({ showAnswerConfig: !showAnswerConfig })
                            );
                          }}
                        >
                          Configurações da folha de resposta (
                          {showAnswerConfig == false ? (
                            <span>
                              <MdArrowUpward /> Mostrar
                            </span>
                          ) : (
                            <>
                              <MdArrowDownward /> Esconder
                            </>
                          )}
                          ){" "}
                        </Form.Label>
                      </div>
                      {showAnswerConfig == true && (
                        <>
                          <br />
                          <InputGroup>
                            <div className="flex fdcolumn flex1 margin-bottom">
                              <Form.Label>
                                Impressão da folha de resposta?
                              </Form.Label>

                              <select
                                disabled={
                                  loading_page || examSelect?.type == "text"
                                }
                                className="form-select "
                                onChange={(e) => {
                                  let model_: any = { ...model };
                                  model_.answer_page_visible =
                                    e.target.value == "true" ? true : false;
                                  dispatch(setModel(model_));
                                }}
                                value={model.answer_page_visible.toString()}
                              >
                                <option value={"true"}>Sim</option>
                                <option value={"false"}>Não</option>
                              </select>
                            </div>
                          </InputGroup>
                          <InputGroup>
                            <div className="flex fdcolumn flex1 margin-bottom">
                              <Form.Label>
                                Título da folha de resposta{" "}
                              </Form.Label>
                              <Input
                                placeholder="Título da folha de resposta"
                                value={model.title_answer}
                                onChange={(e) => {
                                  let model_: any = { ...model };
                                  model_.title_answer = e.target.value;
                                  dispatch(setModel(model_));
                                }}
                                disabled={loading_page}
                              // title="Coloque o nome completo do usuário"
                              />
                            </div>
                          </InputGroup>
                          <InputGroup>
                            <div className="flex fdcolumn flex1 margin-bottom">
                              <Form.Label>
                                Subtítulo da folha de resposta{" "}
                              </Form.Label>
                              <Input
                                placeholder="Subtítulo da folha de resposta"
                                value={model.subtitle_answer}
                                onChange={(e) => {
                                  let model_: any = { ...model };
                                  model_.subtitle_answer = e.target.value;
                                  dispatch(setModel(model_));
                                }}
                                disabled={loading_page}
                              // title="Coloque o nome completo do usuário"
                              />
                            </div>
                          </InputGroup>
                          <InputGroup>
                            <div className="flex fdcolumn flex1 margin-bottom">
                              <Form.Label>
                                Nome da folha de resposta{" "}
                              </Form.Label>
                              <Input
                                placeholder="Nome da folha de resposta"
                                value={model.name_answer}
                                onChange={(e) => {
                                  let model_: any = { ...model };
                                  model_.name_answer = e.target.value;
                                  dispatch(setModel(model_));
                                }}
                                disabled={loading_page}
                              // title="Coloque o nome completo do usuário"
                              />
                            </div>
                          </InputGroup>
                          {/* <InputGroup>

                                <div className="flex fdcolumn flex1 margin-bottom">
                                    <Form.Label>PDF Image <Attention /></Form.Label>
                                    <FormCheck
                                        type='checkbox'
                                        disabled={this.state.loading_page}
                                        checked={this.state.generate_pdf_image}
                                        onChange={(e) => this.setState({ generate_pdf_image: e.target.checked }, () => {
                                            this.setPages();
                                        })}
                                        
                                    // title="Coloque o nome completo do usuário"
                                    />
                                </div>
                            </InputGroup> */}

                          <InputGroup>
                            <div className="flex fdcolumn flex1 margin-bottom">
                              <Form.Label>
                                Título campo 1 folha de resposta{" "}
                              </Form.Label>
                              <Input
                                placeholder="Título campo 1"
                                value={model.title_field_1_answer}
                                onChange={(e) => {
                                  let model_: any = { ...model };
                                  model_.title_field_1_answer = e.target.value;
                                  dispatch(setModel(model_));
                                }}
                                disabled={loading_page}
                              // title="Coloque o nome completo do usuário"
                              />
                            </div>
                          </InputGroup>

                          <InputGroup>
                            <div className="flex fdcolumn flex1 margin-bottom">
                              <Form.Label>
                                Campo 1 folha de resposta{" "}
                              </Form.Label>
                              <Input
                                placeholder="Campo 1"
                                value={model.field_1_answer}
                                onChange={(e) => {
                                  let model_: any = { ...model };
                                  model_.field_1_answer = e.target.value;
                                  dispatch(setModel(model_));
                                }}
                                disabled={loading_page}
                              // title="Coloque o nome completo do usuário"
                              />
                            </div>
                          </InputGroup>

                          <InputGroup>
                            <div className="flex fdcolumn flex1 margin-bottom">
                              <Form.Label>
                                Título campo 2 folha de resposta{" "}
                              </Form.Label>
                              <Input
                                placeholder="Título campo 2"
                                value={model.title_field_2_answer}
                                onChange={(e) => {
                                  let model_: any = { ...model };
                                  model_.title_field_2_answer = e.target.value;
                                  dispatch(setModel(model_));
                                }}
                                disabled={loading_page}
                              // title="Coloque o nome completo do usuário"
                              />
                            </div>
                          </InputGroup>

                          <InputGroup>
                            <div className="flex fdcolumn flex1 margin-bottom">
                              <Form.Label>
                                Campo 2 folha de resposta{" "}
                              </Form.Label>
                              <Input
                                placeholder="Campo 2"
                                value={model.field_2_answer}
                                onChange={(e) => {
                                  let model_: any = { ...model };
                                  model_.field_2_answer = e.target.value;
                                  dispatch(setModel(model_));
                                }}
                                disabled={loading_page}
                              // title="Coloque o nome completo do usuário"
                              />
                            </div>
                          </InputGroup>

                          <InputGroup>
                            <div className="flex fdcolumn flex1 margin-bottom">
                              <Form.Label>
                                Título campo 3 folha de resposta{" "}
                              </Form.Label>
                              <Input
                                placeholder="Título campo 3"
                                value={model.title_field_3_answer}
                                onChange={(e) => {
                                  let model_: any = { ...model };
                                  model_.title_field_3_answer = e.target.value;
                                  dispatch(setModel(model_));
                                }}
                                disabled={loading_page}
                              // title="Coloque o nome completo do usuário"
                              />
                            </div>
                          </InputGroup>

                          <InputGroup>
                            <div className="flex fdcolumn flex1 margin-bottom">
                              <Form.Label>
                                Campo 3 folha de resposta{" "}
                              </Form.Label>
                              <Input
                                placeholder="Campo 3"
                                value={model.field_3_answer}
                                onChange={(e) => {
                                  let model_: any = { ...model };
                                  model_.field_3_answer = e.target.value;
                                  dispatch(setModel(model_));
                                }}
                                disabled={loading_page}
                              // title="Coloque o nome completo do usuário"
                              />
                            </div>
                          </InputGroup>

                          <InputGroup>
                            <div className="flex fdcolumn flex1 margin-bottom">
                              <Form.Label>
                                Título campo 4 folha de resposta{" "}
                              </Form.Label>
                              <Input
                                placeholder="Título campo 4"
                                value={model.title_field_4_answer}
                                onChange={(e) => {
                                  let model_: any = { ...model };
                                  model_.title_field_4_answer = e.target.value;
                                  dispatch(setModel(model_));
                                }}
                                disabled={loading_page}
                              // title="Coloque o nome completo do usuário"
                              />
                            </div>
                          </InputGroup>

                          <InputGroup>
                            <div className="flex fdcolumn flex1 margin-bottom">
                              <Form.Label>
                                Campo 4 folha de resposta{" "}
                              </Form.Label>
                              <Input
                                placeholder="Campo 4"
                                value={model.field_4_answer}
                                onChange={(e) => {
                                  let model_: any = { ...model };
                                  model_.field_4_answer = e.target.value;
                                  dispatch(setModel(model_));
                                }}
                                disabled={loading_page}
                              // title="Coloque o nome completo do usuário"
                              />
                            </div>
                          </InputGroup>

                          <InputGroup>
                            <div className="flex fdcolumn flex1 margin-bottom">
                              <Form.Label>
                                Título campo 5 folha de resposta{" "}
                              </Form.Label>
                              <Input
                                placeholder="Título campo 2"
                                value={model.title_field_5_answer}
                                onChange={(e) => {
                                  let model_: any = { ...model };
                                  model_.title_field_5_answer = e.target.value;
                                  dispatch(setModel(model_));
                                }}
                                disabled={loading_page}
                              // title="Coloque o nome completo do usuário"
                              />
                            </div>
                          </InputGroup>

                          <InputGroup>
                            <div className="flex fdcolumn flex1 margin-bottom">
                              <Form.Label>
                                Campo 5 folha de resposta{" "}
                              </Form.Label>
                              <Input
                                placeholder="Campo 5"
                                value={model.field_5_answer}
                                onChange={(e) => {
                                  let model_: any = { ...model };
                                  model_.field_5_answer = e.target.value;
                                  dispatch(setModel(model_));
                                }}
                                disabled={loading_page}
                              // title="Coloque o nome completo do usuário"
                              />
                            </div>
                          </InputGroup>

                          <InputGroup>
                            <div className="flex fdcolumn flex1 margin-bottom">
                              <Form.Label>
                                Instruções folha de resposta{" "}
                              </Form.Label>

                              {configs_array.current != undefined &&
                                configs_array.current[0] != undefined && (
                                  <JoditEditor
                                    ref={editor_rules_answer}
                                    value={rules_answer}
                                    config={configs_array.current[0]}
                                    onChange={(newContent: any) => {
                                      dispatch(
                                        setState({ rules_answer: newContent })
                                      );
                                    }}
                                    // height={400}
                                    // tabIndex={1} // tabIndex of textarea
                                    onBlur={(newContent: any) => {
                                      // let model_: any = { ...model };
                                      // model_.question = newContent;
                                      // dispatch(setModel(model_));
                                      dispatch(
                                        setState({ rules_answer: newContent })
                                      );
                                    }} // preferred to use only this option to update the content for performance reasons
                                  // onChange={newContent => {this.setState({enunciado:newContent}) }}
                                  />
                                )}
                              {/* <Input
                                                    placeholder="Instruções"
                                                    value={model.rules}
                                                    onChange={(e) => {
                                                        let model_: any = { ...model };
                                                        model_.rules = e.target.value;
                                                        dispatch(setModel(model_));
                                                    }}
                                                    as="textarea"
                                                    style={{ minHeight: 200 }}
                                                    disabled={loading_page}
                                                // title="Coloque o nome completo do usuário"
                                                /> */}
                            </div>
                          </InputGroup>
                        </>
                      )}
                    </div>
                  )}

                  {!props.justView && (
                    <div
                      className="hidethis"
                      style={{ width: "100%", paddingBottom: 20 }}
                    >
                      <br />
                      <br />

                      {loading_save == false && (
                        <div className="w100 inline-flex jcsb hidethis">
                          {/* <Link to={`/accounts/list`}>
                            <Button variant="secondary"><RiArrowGoBackLine/> Voltar</Button>
                        </Link> */}
                          {/* <DefaultButton
                            bg="secondary"
                            text="Voltar"
                            icon={<RiArrowGoBackLine />}
                            to={`/layouts_exams`}
                          /> */}
                          <Button
                            variant="secondary"
                            onClick={() => history("/tests/view/"+(edit==true?model.application_id: params.test_id)+"?tab=3")}
                            className="flex align-center jcc"
                          >
                            <RiArrowGoBackLine style={{ marginRight: 10 }} />{" "}
                            Voltar
                          </Button>
                          {/* <Button variant="success" type="submit" disabled={this.loading}>
                            {this.state.loading ? <Spinner /> : ""}
                            Registrar usuário
                        </Button> */}
                          <div style={{ display: "flex" }}>
                            {loading_page == false && (
                              //   <DefaultButton
                              //     type="button"
                              //     bg="info"
                              //     style={{ marginRight: 10 }}
                              //     onClick={() => {
                              //       // this.generatePDF();
                              //       window.print();
                              //       return undefined;
                              //     }}
                              //     text="Imprimir"
                              //     disabled={loading_pdf == true}
                              //     loadingtext="Imprindo..."
                              //     loading={loading_pdf}
                              //   />
                              <Button
                                variant="secondary"
                                style={{ marginRight: 10 }}
                                onClick={() => {
                                  // this.generatePDF();
                                  window.print();
                                  return undefined;
                                }}
                                disabled={loading_pdf == true}
                                className="flex align-center jcc"
                              >
                                <PrintRounded style={{ marginRight: 10 }} />{" "}
                                Imprimir
                              </Button>
                            )}
                            {/* <DefaultButton
                                type="submit"
                                bg="confirm"
                                text="Salvar diagramação"
                                disabled={loading_pdf == true}
                                loadingtext="Salvando diagramação"
                                loading={loading_save}
                            /> */}
                            <Button
                              variant="success"
                              type="submit"
                              disabled={loading_pdf == true}
                              className="flex align-center jcc"
                            >
                              <SaveRounded style={{ marginRight: 10 }} /> Salvar
                              diagramação
                            </Button>
                          </div>
                        </div>
                      )}

                      {loading_save == true && (
                        <div className="row">
                          {" "}
                          <div className="col-12 d-flex justify-content-center align-items-center margin-top-1">
                            <div
                              className="spinner-border text-primary"
                              role="status"
                            ></div>
                          </div>
                        </div>
                      )}
                    </div>
                  )}

                  <div style={{ position: "relative" }}>
                    {loading_page && (
                      <div
                        style={{
                          zIndex: 10,
                          background: "rgba(255,255,255,0.5)",
                          position: "absolute",
                          top: 0,
                          height: 600,
                          left: 0,
                          right: 0,
                          bottom: 0,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div
                          className="spinner-border text-primary"
                          role="status"
                        ></div>
                      </div>
                    )}
                    <div
                      className="print_preview"
                      style={{
                        border: "1px solid",
                        maxHeight: loading_page ? 600 : "none",
                        overflow: loading_page ? "scroll" : "visible",
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        background: "#f2f2f2",
                        position: "relative",
                      }}
                    >
                      <div
                        style={{
                          background: "white",
                          padding: 7.5,
                          paddingTop: generate_pdf_image == false ? 0 : 15,
                          paddingBottom: generate_pdf_image == false ? 0 : 15,
                          height: "100%",
                        }}
                      >
                        <div id="pdf_diagramado_2">
                          {model.answer_page_visible == true && (
                            <div id={"page_resposta"} style={{ height: 1120 }}>
                              <div
                                style={{
                                  paddingTop:
                                    generate_pdf_image == false ? 15 : 0,
                                  paddingBottom:
                                    generate_pdf_image == false ? 15 : 0,
                                }}
                              >
                                <div
                                  style={{
                                    padding: 0,
                                    width: width_sample,
                                    background: "white",
                                    marginTop: 0,
                                    marginBottom: 0,
                                    paddingTop: 0,
                                  }}
                                >
                                  <div style={{ height: 960 + 123 }}>
                                    <div>
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                          alignItems: "center",
                                          paddingTop: 0,
                                          marginLeft: 10,
                                          marginRight: 10,
                                        }}
                                      >
                                        <div>
                                          {model.logo != "" ||
                                            model.logo_path != "" ? (
                                            <img
                                              style={{
                                                height: 70,
                                                width: 100,
                                                objectFit: "contain",
                                              }}
                                              src={
                                                model.logo == ""
                                                  ? STORAGE_URL +
                                                  "/" +
                                                  model.logo_path
                                                  : window.URL.createObjectURL(
                                                    model.logo
                                                  )
                                              }
                                            />
                                          ) : (
                                            <div
                                              style={{
                                                height: 100,
                                                width: 150,
                                                objectFit: "contain",
                                              }}
                                            ></div>
                                          )}
                                        </div>
                                        <div style={{ maxHeight: 100 }}>
                                          <p
                                            style={{
                                              textAlign: "center",
                                              fontSize: 20,
                                              marginBottom: 0,
                                              color: "black",
                                              fontWeight: "700",
                                              lineHeight: 1.2,
                                            }}
                                          >
                                            {model.title_answer}
                                          </p>
                                          <p
                                            style={{
                                              textAlign: "center",
                                              fontSize: 20,
                                              marginBottom: 0,
                                              color: "black",
                                              fontWeight: "400",
                                              lineHeight: 1.2,
                                            }}
                                          >
                                            {model.subtitle_answer}
                                          </p>
                                          <p
                                            style={{
                                              textAlign: "center",
                                              fontSize: 20,
                                              marginBottom: 0,
                                              color: "black",
                                              fontWeight: "500",
                                              lineHeight: 1.2,
                                            }}
                                          >
                                            {model.name_answer}
                                          </p>
                                        </div>
                                        <div>
                                          {model.logo_bottom != "" ||
                                            model.logo_path_bottom != "" ? (
                                            <img
                                              style={{
                                                height: 70,
                                                width: 100,
                                                objectFit: "contain",
                                              }}
                                              src={
                                                model.logo_bottom == ""
                                                  ? STORAGE_URL +
                                                  "/" +
                                                  model.logo_path_bottom
                                                  : window.URL.createObjectURL(
                                                    model.logo_bottom
                                                  )
                                              }
                                            />
                                          ) : (
                                            <div
                                              style={{
                                                height: 100,
                                                width: 150,
                                                objectFit: "contain",
                                              }}
                                            ></div>
                                          )}
                                        </div>
                                      </div>
                                      <div
                                        style={{
                                          width: "100%",
                                          height: 1,
                                          background: "black",
                                          marginTop: 5,
                                          marginBottom: 5,
                                        }}
                                      ></div>
                                      <div
                                        style={{
                                          position: "relative",
                                          marginLeft: 10,
                                          marginRight: 10,
                                        }}
                                      >
                                        {
                                          <div
                                            style={{
                                              border: "1px solid black",
                                              padding: 0,
                                              width: "100%",
                                              backgroundColor: "#E3E3E3",
                                              paddingBottom: 1,
                                              height: 32,
                                            }}
                                          >
                                            <p
                                              style={{
                                                marginBottom: 0,
                                                fontSize: 9,
                                                marginLeft: 2,
                                                lineHeight: 1.2,
                                                color: "black",
                                              }}
                                            >
                                              {model.title_field_1_answer}
                                            </p>

                                            <p
                                              style={{
                                                marginBottom: 0,
                                                fontSize: 15,
                                                marginLeft: 10,
                                                fontStyle: "italic",
                                                lineHeight: 1.2,
                                                color: "black",
                                              }}
                                            >
                                              <b>{model.field_1_answer}</b>
                                            </p>
                                          </div>
                                        }
                                        {
                                          <div
                                            style={{
                                              border: "1px solid black",
                                              padding: 0,
                                              width: "100%",
                                              marginTop: 3,
                                              backgroundColor: "#E3E3E3",
                                              paddingBottom: 1,
                                              height: 32,
                                            }}
                                          >
                                            <p
                                              style={{
                                                marginBottom: 0,
                                                fontSize: 9,
                                                marginLeft: 2,
                                                lineHeight: 1.2,
                                                color: "black",
                                              }}
                                            >
                                              {model.title_field_2_answer}
                                            </p>

                                            <p
                                              style={{
                                                marginBottom: 0,
                                                fontSize: 15,
                                                marginLeft: 10,
                                                fontStyle: "italic",
                                                lineHeight: 1.2,
                                                color: "black",
                                              }}
                                            >
                                              <b>{model.field_2_answer}</b>
                                            </p>
                                          </div>
                                        }

                                        {
                                          <div style={{ display: "flex" }}>
                                            {
                                              <div
                                                style={{
                                                  border: "1px solid black",
                                                  padding: 0,
                                                  width: "60%",
                                                  marginTop: 3,
                                                  backgroundColor: "#E3E3E3",
                                                  height: 32,
                                                  paddingBottom: 1,
                                                  marginRight: 3,
                                                }}
                                              >
                                                <p
                                                  style={{
                                                    marginBottom: 0,
                                                    fontSize: 9,
                                                    marginLeft: 2,
                                                    lineHeight: 1.2,
                                                    color: "black",
                                                  }}
                                                >
                                                  {model.title_field_3_answer}
                                                </p>

                                                <p
                                                  style={{
                                                    marginBottom: 0,
                                                    fontSize: 15,
                                                    marginLeft: 10,
                                                    fontStyle: "italic",
                                                    lineHeight: 1.2,
                                                    color: "black",
                                                  }}
                                                >
                                                  <b>{model.field_3_answer}</b>
                                                </p>
                                              </div>
                                            }
                                            {
                                              <div
                                                style={{
                                                  border: "1px solid black",
                                                  padding: 0,
                                                  width: "25%",
                                                  marginTop: 3,
                                                  backgroundColor: "#E3E3E3",
                                                  height: 32,
                                                  paddingBottom: 1,
                                                  marginRight: 3,
                                                }}
                                              >
                                                <p
                                                  style={{
                                                    marginBottom: 0,
                                                    fontSize: 9,
                                                    marginLeft: 2,
                                                    lineHeight: 1.2,
                                                    color: "black",
                                                  }}
                                                >
                                                  {model.title_field_4_answer}
                                                </p>

                                                <p
                                                  style={{
                                                    marginBottom: 0,
                                                    fontSize: 15,
                                                    marginLeft: 10,
                                                    fontStyle: "italic",
                                                    lineHeight: 1.2,
                                                    color: "black",
                                                  }}
                                                >
                                                  <b>{model.field_4_answer}</b>
                                                </p>
                                              </div>
                                            }
                                            {
                                              <div
                                                style={{
                                                  border: "1px solid black",
                                                  padding: 0,
                                                  width: "15%",
                                                  marginTop: 3,
                                                  backgroundColor: "#E3E3E3",
                                                  height: 32,
                                                  paddingBottom: 1,
                                                }}
                                              >
                                                <p
                                                  style={{
                                                    marginBottom: 0,
                                                    fontSize: 9,
                                                    marginLeft: 2,
                                                    lineHeight: 1.2,
                                                    color: "black",
                                                  }}
                                                >
                                                  {model.title_field_5_answer}
                                                </p>

                                                <p
                                                  style={{
                                                    marginBottom: 0,
                                                    fontSize: 15,
                                                    marginLeft: 10,
                                                    fontStyle: "italic",
                                                    lineHeight: 1.2,
                                                    color: "black",
                                                  }}
                                                >
                                                  <b>{model.field_5_answer}</b>
                                                </p>
                                              </div>
                                            }
                                          </div>
                                        }

                                        {/* <p style={{ textAlign: 'left', fontSize: 15, marginBottom: 5, marginLeft: 20, color: 'black', fontWeight: 'normal' }}>Matheus Carreira Andrade</p>
                                                                        <p style={{ textAlign: 'left', fontSize: 15, marginBottom: 5, marginLeft: 20, color: 'black', fontWeight: 'normal' }}>UNESP - São José do Rio Preto</p>
                                                                        <p style={{ textAlign: 'left', fontSize: 15, marginBottom: 5, marginLeft: 20, color: 'black', fontWeight: 'normal' }}>Ciência da Computação - 2º ano</p>
                                                                    <img src="https://ps.w.org/doqrcode/assets/icon-256x256.png?rev=2143781" height={60} width={60} style={{ position: 'absolute', right: 25, top: 5 }} /> */}
                                      </div>

                                      <div
                                        style={{
                                          width: "100%",
                                          height: 1,
                                          background: "black",
                                          marginTop: 5,
                                          marginBottom: 5,
                                        }}
                                      ></div>

                                      <div
                                        style={{
                                          position: "relative",
                                          marginLeft: 10,
                                          marginRight: 10,
                                        }}
                                      >
                                        <div
                                          style={{
                                            padding: 10,
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            paddingBottom: 0,
                                          }}
                                        >
                                          <p
                                            style={{
                                              marginBottom: 0,
                                              fontSize: 16,
                                              color: "black",
                                              textAlign: "center",
                                              lineHeight: 1.2,
                                            }}
                                          >
                                            <b>
                                              LEIA ATENTAMENTE AS INSTRUÇÕES
                                              ABAIXO
                                            </b>
                                          </p>
                                        </div>
                                        <div
                                          style={{ padding: 10, paddingTop: 0 }}
                                        >
                                          <div
                                            style={{
                                              fontSize: 11,
                                              color: "black",
                                              height: 224,
                                            }}
                                            dangerouslySetInnerHTML={{
                                              __html: rules_answer
                                                ? changeFontSize(rules_answer)
                                                : rules_answer,
                                            }}
                                          ></div>
                                        </div>
                                      </div>

                                      <div
                                        style={{
                                          width: "100%",
                                          height: 1,
                                          background: "black",
                                          marginTop: 5,
                                          marginBottom: 5,
                                        }}
                                      ></div>

                                      <div
                                        style={{
                                          display: "flex",
                                          margin: 10,
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        <div
                                          style={{
                                            width: "50%",
                                            display: "flex",
                                            justifyContent: "space-between",
                                          }}
                                        >
                                          <div style={{ paddingRight: 5 }}>
                                            <p
                                              style={{
                                                fontSize: 13,
                                                marginBottom: 0,
                                                color: "black",
                                                letterSpacing: 0
                                              }}
                                            >
                                              "NÃO RASURE ESTE LOCAL"
                                            </p>
                                            <p
                                              style={{
                                                fontSize: 13,
                                                marginBottom: 0,
                                                color: "black",
                                                letterSpacing: 0

                                              }}
                                            >
                                              Esta área está reservada ao qrcode
                                              que irá identificar o candidato no
                                              momento da correção.
                                            </p>
                                          </div>
                                          <img src={Arrow} height={65} />
                                        </div>
                                        <div
                                          style={{
                                            width: "50%",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                          }}
                                        >
                                          <img
                                            src={
                                              props.justView
                                                ? class_student.qrcode
                                                : "https://ps.w.org/doqrcode/assets/icon-256x256.png?rev=2143781"
                                            }
                                            height={60}
                                            width={60}
                                          />
                                        </div>
                                      </div>
                                      <div
                                        style={{
                                          width: "100%",
                                          height: 1,
                                          background: "black",
                                          marginTop: 5,
                                          marginBottom: 5,
                                        }}
                                      ></div>
                                      <div
                                        style={{
                                          marginLeft: 10,
                                          marginRight: 10,
                                        }}
                                      >
                                        <p
                                          style={{
                                            fontStyle: "italic",
                                            color: "#000001",
                                            fontSize: 13,
                                          }}
                                        >
                                          ÁREA DO CANDIDATO
                                        </p>
                                        <div
                                          style={{
                                            border: "1px solid #000001",
                                            padding: 0,
                                            width: "100%",
                                            backgroundColor: "white",
                                            paddingBottom: 1,
                                            height: 32,
                                          }}
                                        >
                                          <p
                                            style={{
                                              marginBottom: 0,
                                              fontSize: 9,
                                              marginLeft: 2,
                                              lineHeight: 1.2,
                                              color: "black",
                                            }}
                                          >
                                            Nome do candidato
                                          </p>

                                          <p
                                            style={{
                                              marginBottom: 0,
                                              fontSize: 15,
                                              marginLeft: 10,
                                              fontStyle: "italic",
                                              lineHeight: 1.2,
                                              color: "black",
                                            }}
                                          >
                                            <b>
                                              {props.justView
                                                ? class_student?.student?.name
                                                : "Nome do candidato"}
                                            </b>
                                          </p>
                                        </div>
                                        <div style={{ display: "flex" }}>
                                          {
                                            <div
                                              style={{
                                                border: "1px solid #000001",
                                                padding: 0,
                                                width: "70%",
                                                marginTop: 3,
                                                backgroundColor: "white",
                                                height: 40,
                                                paddingBottom: 1,
                                                marginRight: 3,
                                              }}
                                            >
                                              <p
                                                style={{
                                                  marginBottom: 0,
                                                  fontSize: 12,
                                                  marginLeft: 2,
                                                  lineHeight: 1.2,
                                                  color: "black",
                                                }}
                                              >
                                                Assinatura do candidato
                                              </p>
                                            </div>
                                          }
                                          {
                                            <div
                                              style={{
                                                border: "1px solid #000001",
                                                padding: 0,
                                                width: "30%",
                                                marginTop: 3,
                                                backgroundColor: "white",
                                                height: 40,
                                                paddingBottom: 1,
                                              }}
                                            >
                                              <p
                                                style={{
                                                  marginBottom: 0,
                                                  fontSize: 9,
                                                  marginLeft: 2,
                                                  lineHeight: 1.2,
                                                  color: "black",
                                                }}
                                              >
                                                N de identidade
                                              </p>

                                              <p
                                                style={{
                                                  marginBottom: 0,
                                                  fontSize: 15,
                                                  marginLeft: 10,
                                                  fontStyle: "italic",
                                                  lineHeight: 1.2,
                                                  color: "black",
                                                }}
                                              >
                                                <b>
                                                  {props.justView
                                                    ? class_student?.student
                                                      ?.identity
                                                    : "N de identidade"}
                                                </b>
                                              </p>
                                            </div>
                                          }
                                        </div>
                                        <p
                                          style={{
                                            fontWeight: "bold",
                                            textAlign: "center",
                                            color: "#000001",
                                            fontSize: 13,
                                          }}
                                        >
                                          CARTÃO-RESPOSTA
                                        </p>
                                        <div
                                          className="row justify-content-center"
                                          style={{ position: "relative" }}
                                        >
                                          <img
                                            height={12}
                                            src={TriangleImage}
                                            style={{
                                              objectFit: "contain",
                                              position: "absolute",
                                              right: 0,
                                              width: "auto",
                                              top: -12,
                                            }}
                                          />

                                          <img
                                            height={12}
                                            src={TriangleImage}
                                            style={{
                                              objectFit: "contain",
                                              position: "absolute",
                                              left: 0,
                                              width: "auto",
                                              top: -12,
                                            }}
                                          />

                                          <img
                                            height={12}
                                            src={TriangleImage}
                                            style={{
                                              objectFit: "contain",
                                              position: "absolute",
                                              right: 0,
                                              width: "auto",
                                              bottom: -12,
                                            }}
                                          />

                                          <img
                                            height={12}
                                            src={TriangleImage}
                                            style={{
                                              objectFit: "contain",
                                              position: "absolute",
                                              left: 0,
                                              width: "auto",
                                              bottom: -12,
                                            }}
                                          />
                                          {questions_parsed.map(
                                            (item2: any, id2: any) => (
                                              <div key={id2} className="col-2">
                                                <div
                                                  style={{
                                                    width: "100%",
                                                    border: "1px solid #000001",
                                                    paddingBottom: 2,
                                                    paddingTop: 2,
                                                    marginBottom: 1,
                                                    height: 21,
                                                  }}
                                                >
                                                  <p
                                                    style={{
                                                      color: "#000001",
                                                      marginBottom: 0,
                                                      textAlign: "center",
                                                      fontSize: 8,
                                                    }}
                                                  >
                                                    RESPOSTAS{" "}
                                                    {number_questions_column *
                                                      id2 +
                                                      1}{" "}
                                                    a{" "}
                                                    {id2 *
                                                      number_questions_column +
                                                      item2.length}
                                                  </p>
                                                </div>

                                                {item2.map(
                                                  (item: any, id: any) => (
                                                    <div
                                                      key={id}
                                                      style={{
                                                        display: "flex",
                                                        marginBottom: 1,
                                                        justifyContent:
                                                          "space-between",
                                                      }}
                                                    >
                                                      <div
                                                        style={{
                                                          width: "18%",
                                                          maxWidth: "18%",
                                                          backgroundColor:
                                                            "#000001",
                                                          height: 15,
                                                          marginRight: 1.5,
                                                        }}
                                                      >
                                                        <p
                                                          style={{
                                                            color: "white",
                                                            marginBottom: 0,
                                                            textAlign: "center",
                                                            fontSize: 12,
                                                            lineHeight: 1,
                                                          }}
                                                        >
                                                          {number_questions_column *
                                                            id2 +
                                                            (id + 1)}
                                                        </p>
                                                      </div>
                                                      <div
                                                        style={{
                                                          width: "18%",
                                                          maxWidth: "18%",
                                                          border:
                                                            "1px solid #000001",
                                                          height: 15,
                                                          borderRadius: 100,
                                                          marginRight: 1.5,
                                                        }}
                                                      >
                                                        <p
                                                          style={{
                                                            color: "#000001",
                                                            marginBottom: 0,
                                                            textAlign: "center",
                                                            fontSize: 12,
                                                            lineHeight: 1,
                                                          }}
                                                        >
                                                          A
                                                        </p>
                                                      </div>
                                                      <div
                                                        style={{
                                                          width: "18%",
                                                          maxWidth: "18%",
                                                          border:
                                                            "1px solid #000001",
                                                          height: 15,
                                                          borderRadius: 100,
                                                          marginRight: 1.5,
                                                        }}
                                                      >
                                                        <p
                                                          style={{
                                                            color: "#000001",
                                                            marginBottom: 0,
                                                            textAlign: "center",
                                                            fontSize: 12,
                                                            lineHeight: 1,
                                                          }}
                                                        >
                                                          B
                                                        </p>
                                                      </div>
                                                      <div
                                                        style={{
                                                          width: "18%",
                                                          maxWidth: "18%",
                                                          border:
                                                            "1px solid #000001",
                                                          height: 15,
                                                          borderRadius: 100,
                                                          marginRight: 1.5,
                                                        }}
                                                      >
                                                        <p
                                                          style={{
                                                            color: "#000001",
                                                            marginBottom: 0,
                                                            textAlign: "center",
                                                            fontSize: 12,
                                                            lineHeight: 1,
                                                          }}
                                                        >
                                                          C
                                                        </p>
                                                      </div>
                                                      <div
                                                        style={{
                                                          width: "18%",
                                                          maxWidth: "18%",
                                                          border:
                                                            "1px solid #000001",
                                                          height: 15,
                                                          borderRadius: 100,
                                                          marginRight: 1.5,
                                                        }}
                                                      >
                                                        <p
                                                          style={{
                                                            color: "#000001",
                                                            marginBottom: 0,
                                                            textAlign: "center",
                                                            fontSize: 12,
                                                            lineHeight: 1,
                                                          }}
                                                        >
                                                          D
                                                        </p>
                                                      </div>
                                                      <div
                                                        style={{
                                                          width: "18%",
                                                          maxWidth: "18%",
                                                          border:
                                                            "1px solid #000001",
                                                          height: 15,
                                                          borderRadius: 100,
                                                        }}
                                                      >
                                                        <p
                                                          style={{
                                                            color: "#000001",
                                                            marginBottom: 0,
                                                            textAlign: "center",
                                                            fontSize: 12,
                                                            lineHeight: 1,
                                                          }}
                                                        >
                                                          E
                                                        </p>
                                                      </div>
                                                    </div>
                                                  )
                                                )}
                                              </div>
                                            )
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                          <div id={"page_0"} style={{ height: 1120 }}>
                            <div
                              style={{
                                paddingTop:
                                  generate_pdf_image == false ? 15 : 0,
                                paddingBottom:
                                  generate_pdf_image == false ? 15 : 0,
                              }}
                            >
                              <div
                                style={{
                                  padding: 0,
                                  width: width_sample,
                                  background: "white",
                                  marginTop: 0,
                                  marginBottom: 0,
                                  paddingTop: 0,
                                }}
                              >
                                <div style={{ height: 960 + 123 }}>
                                  <div id="header_page">
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        paddingTop: 0,
                                      }}
                                    >
                                      <div>
                                        {model.logo != "" ||
                                          model.logo_path != "" ? (
                                          <img
                                            style={{
                                              height: 100,
                                              width: 150,
                                              objectFit: "contain",
                                            }}
                                            src={
                                              model.logo == ""
                                                ? STORAGE_URL +
                                                "/" +
                                                model.logo_path
                                                : window.URL.createObjectURL(
                                                  model.logo
                                                )
                                            }
                                          />
                                        ) : (
                                          <div
                                            style={{
                                              height: 100,
                                              width: 150,
                                              objectFit: "contain",
                                            }}
                                          ></div>
                                        )}
                                      </div>

                                      <div
                                        style={{
                                          maxWidth: 350,
                                          maxHeight: 100,
                                        }}
                                      >
                                        <p
                                          style={{
                                            textAlign: "right",
                                            fontSize: 30,
                                            marginBottom: 0,
                                            color: "black",
                                            fontWeight: "bold",
                                          }}
                                        >
                                          {model.codeExam}
                                        </p>
                                      </div>
                                    </div>
                                    <div
                                      style={{
                                        width: "100%",
                                        height: 1,
                                        background: "black",
                                        marginTop: 5,
                                        marginBottom: 5,
                                      }}
                                    ></div>
                                    <div style={{ position: "relative" }}>
                                      <p
                                        style={{
                                          textAlign: "left",
                                          fontSize: 15,
                                          marginBottom: 5,
                                          marginLeft: 20,
                                          color: "black",
                                          fontWeight: "normal",
                                        }}
                                      >
                                        {props.justView
                                          ? class_student?.student?.name
                                          : "Nome do Candidato"}
                                      </p>
                                      <p
                                        style={{
                                          textAlign: "left",
                                          fontSize: 15,
                                          marginBottom: 5,
                                          marginLeft: 20,
                                          color: "black",
                                          fontWeight: "normal",
                                        }}
                                      >
                                        {props.justView
                                          ? class_student?.class?.unit?.name
                                          : "Unidade"}
                                      </p>
                                      <p
                                        style={{
                                          textAlign: "left",
                                          fontSize: 15,
                                          marginBottom: 5,
                                          marginLeft: 20,
                                          color: "black",
                                          fontWeight: "normal",
                                        }}
                                      >
                                        {props.justView
                                          ? class_student?.class?.course?.name +
                                          " - " +
                                          class_student.class?.period
                                          : "Curso/Grau - Ano"}
                                      </p>
                                      <img
                                        src={
                                          props.justView
                                            ? class_student.qrcode
                                            : "https://ps.w.org/doqrcode/assets/icon-256x256.png?rev=2143781"
                                        }
                                        height={60}
                                        width={60}
                                        style={{
                                          position: "absolute",
                                          right: 25,
                                          top: 5,
                                        }}
                                      />

                                      <p
                                        style={{
                                          textAlign: "center",
                                          fontSize: 30,
                                          marginBottom: 5,
                                          color: "black",
                                          fontWeight: "700",
                                        }}
                                      >
                                        {model.title}
                                      </p>
                                      <p
                                        style={{
                                          textAlign: "center",
                                          fontSize: 20,
                                          marginBottom: 5,
                                          color: "black",
                                          fontWeight: "400",
                                        }}
                                      >
                                        {model.subtitle}
                                      </p>
                                      <p
                                        style={{
                                          textAlign: "center",
                                          fontSize: 25,
                                          marginBottom: 0,
                                          color: "black",
                                          fontWeight: "500",
                                        }}
                                      >
                                        {model.name}
                                      </p>
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        marginTop: 20,
                                        marginBottom: 20,
                                      }}
                                    >
                                      {model.field_1.trim().length != 0 && (
                                        <div
                                          style={{
                                            border: "1px solid black",
                                            borderRightWidth:
                                              model.field_2.trim().length == 0
                                                ? 1
                                                : 0,
                                            padding: 5,
                                            width:
                                              model.field_2.trim().length == 0
                                                ? "100%"
                                                : "50%",
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          <p
                                            style={{
                                              marginBottom: 0,
                                              fontSize: 17,
                                            }}
                                          >
                                            <b>{model.title_field_1}: </b>
                                            {model.field_1}
                                          </p>
                                        </div>
                                      )}
                                      {model.field_2.trim().length != 0 && (
                                        <div
                                          style={{
                                            border: "1px solid black",
                                            padding: 5,
                                            width:
                                              model.field_1.trim().length == 0
                                                ? "100%"
                                                : "50%",
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          <p
                                            style={{
                                              marginBottom: 0,
                                              fontSize: 17,
                                            }}
                                          >
                                            <b>{model.title_field_2}: </b>
                                            {model.field_2}
                                          </p>
                                        </div>
                                      )}
                                    </div>
                                  </div>

                                  <div
                                    style={{
                                      height: 960 + 67 - 20 - offsetHeight,
                                      border: "1px solid black",
                                    }}
                                  >
                                    <div
                                      style={{
                                        padding: 10,
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        background: "black",
                                      }}
                                    >
                                      <p
                                        style={{
                                          marginBottom: 0,
                                          fontSize: 25,
                                          color: "white",
                                          textAlign: "center",
                                        }}
                                      >
                                        <b>INSTRUÇÕES GERAIS</b>
                                      </p>
                                    </div>
                                    <div
                                      style={{
                                        height: 960 + 67 - 78 - offsetHeight,
                                        padding: 10,
                                        columnCount: model.numberColumnsRules,
                                        columnGap: 20,
                                        columnFill: "auto",
                                        columnRule: "double",
                                      }}
                                    >
                                      <p
                                        style={{
                                          fontSize: parseInt(
                                            model.fontSizeRules
                                          ),
                                          whiteSpace: "pre-line",
                                          textAlign: "justify",
                                          color: "black",
                                        }}
                                      >
                                        {model.rules}
                                      </p>
                                    </div>
                                  </div>
                                  <div>
                                    <p
                                      style={{
                                        marginBottom: 0,
                                        fontSize: 14,
                                        color: "black",
                                        textAlign: "center",
                                      }}
                                    >
                                      Realização
                                    </p>

                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                      }}
                                    >
                                      {model.logo_bottom != "" ||
                                        model.logo_path_bottom != "" ? (
                                        <img
                                          style={{
                                            height: 50,
                                            width: 100,
                                            objectFit: "contain",
                                          }}
                                          src={
                                            model.logo_bottom == ""
                                              ? STORAGE_URL +
                                              "/" +
                                              model.logo_path_bottom
                                              : window.URL.createObjectURL(
                                                model.logo_bottom
                                              )
                                          }
                                        />
                                      ) : (
                                        <div
                                          style={{
                                            height: 100,
                                            width: 100,
                                            objectFit: "contain",
                                          }}
                                        ></div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {pages.map((item: any, id: any) => (
                            <div key={id} id={"page_" + (id + 1)}>
                              <div
                                style={{
                                  paddingTop:
                                    generate_pdf_image == false ? 10 : 0,
                                  paddingBottom:
                                    generate_pdf_image == false ? 0 : 0,
                                  height: 1113,
                                }}
                              >
                                <div
                                  style={{
                                    padding: 0,
                                    marginLeft: model.marginInternalLeft - 40,
                                    marginRight: model.marginInternalRight - 40,
                                    position: "relative",
                                    width:
                                      model.numberColumns == 1
                                        ? width_one_column -
                                        (model.marginInternalRight - 40) -
                                        (model.marginInternalLeft - 40)
                                        : width_two_column,
                                    background: "white",
                                    marginTop: 0,
                                    marginBottom: 0,
                                    border: "0px double black",
                                    paddingTop: model.marginInternalUp,
                                    height: 1103,
                                  }}
                                >
                                  <div
                                    style={{ height: 67, position: "relative" }}
                                  >
                                    {model.title_header.trim().length > 0 && (
                                      <p
                                        style={{
                                          marginBottom: 5,
                                          textAlign: "center",
                                          fontSize: 14,
                                        }}
                                      >
                                        {model.title_header}
                                      </p>
                                    )}
                                    {model.subtitle_header.trim().length >
                                      0 && (
                                        <p
                                          style={{
                                            marginBottom: 20,
                                            textAlign: "center",
                                            fontSize: 14,
                                            fontWeight: "bold",
                                          }}
                                        >
                                          {model.subtitle_header}
                                        </p>
                                      )}
                                    <img
                                      src={
                                        props.justView
                                          ? class_student.qrcode
                                          : "https://ps.w.org/doqrcode/assets/icon-256x256.png?rev=2143781"
                                      }
                                      height={50}
                                      width={50}
                                      style={{
                                        position: "absolute",
                                        right: 0,
                                        top: 0,
                                      }}
                                    />
                                  </div>
                                  <div
                                    ref={(ref) => {
                                      if (ref != null) {
                                        ref.innerHTML = "";
                                        item.elements.map(
                                          (elem: any, index: any) => {
                                            if (
                                              elem.id.includes("categoria_")
                                            ) {
                                              elem.className = "categoria";
                                            } else if (
                                              elem.id.includes("dividor_")
                                            ) {
                                              elem.className = "dividor";
                                            }
                                            elem.id =
                                              0 + "_" + id + "_" + index;
                                            if (ref != null) {
                                              ref.appendChild(elem);
                                            }
                                            pages_all.push(
                                              0 + "_" + id + "_" + index
                                            );
                                          }
                                        );
                                      }
                                    }}
                                    style={{
                                      columnCount: 1,
                                      columnFill: "auto",
                                    }}
                                  ></div>
                                  <div
                                    style={{
                                      position: "absolute",
                                      bottom: 10,
                                      right: 0,
                                      left: 0,
                                    }}
                                  >
                                    <p
                                      style={{
                                        marginBottom: 0,
                                        textAlign: "center",
                                        fontSize: 12,
                                      }}
                                    >
                                      Página {id + 1} de {pages.length}
                                    </p>
                                  </div>
                                </div>
                              </div>

                              {/* <div className='nextpage1'></div> */}
                            </div>
                          ))}

                          {pages_new.map((item: any, id: any) => (
                            <div
                              key={id}
                              style={{ height: 1120 }}
                              id={"page_" + (id + 1)}
                            >
                              <div
                                style={{
                                  paddingTop:
                                    generate_pdf_image == false ? 10 : 0,
                                  paddingBottom:
                                    generate_pdf_image == false ? 0 : 0,
                                  height: 1113,
                                }}
                              >
                                <div
                                  style={{
                                    paddingLeft: model.marginInternalLeft,
                                    paddingBottom: model.marginInternalDown,
                                    paddingRight: model.marginInternalRight,
                                    position: "relative",
                                    width: width_sample,
                                    background: "white",
                                    marginTop: 0,
                                    marginBottom: 0,
                                    border:
                                      "3px double " +
                                      (model.border_visible == true
                                        ? "black"
                                        : "white"),
                                    paddingTop: model.marginInternalUp,
                                    height: 1103,
                                  }}
                                >
                                  <div
                                    style={{ height: 67, position: "relative" }}
                                  >
                                    {model.title_header.trim().length > 0 && (
                                      <p
                                        style={{
                                          marginBottom: 5,
                                          textAlign: "center",
                                          fontSize: 14,
                                        }}
                                      >
                                        {model.title_header}
                                      </p>
                                    )}
                                    {model.subtitle_header.trim().length >
                                      0 && (
                                        <p
                                          style={{
                                            marginBottom: 20,
                                            textAlign: "center",
                                            fontSize: 14,
                                            fontWeight: "bold",
                                          }}
                                        >
                                          {model.subtitle_header}
                                        </p>
                                      )}
                                    <img
                                      src={
                                        props.justView
                                          ? class_student.qrcode
                                          : "https://ps.w.org/doqrcode/assets/icon-256x256.png?rev=2143781"
                                      }
                                      height={50}
                                      width={50}
                                      style={{
                                        position: "absolute",
                                        right: 0,
                                        top: 0,
                                      }}
                                    />
                                  </div>
                                  <div
                                    ref={(ref) => {
                                      if (ref != null) {
                                        ref.innerHTML = "";
                                        item.elements.map(
                                          (elem: any, index: any) => {
                                            elem.id =
                                              1 + "_" + id + "_" + index;
                                            if (ref != null) {
                                              ref.appendChild(elem);
                                            }
                                            pages_all.push(
                                              1 + "_" + id + "_" + index
                                            );
                                          }
                                        );
                                      }
                                    }}
                                    style={{
                                      columnCount: model.numberColumns,
                                      height:
                                        height_sample -
                                        model.marginInternalUp -
                                        model.marginInternalDown -
                                        67,
                                      columnFill: "auto",
                                    }}
                                  ></div>
                                  <div
                                    style={{
                                      position: "absolute",
                                      bottom: 10,
                                      right: 0,
                                      left: 0,
                                    }}
                                  >
                                    <p
                                      style={{
                                        marginBottom: 0,
                                        textAlign: "center",
                                        fontSize: 12,
                                      }}
                                    >
                                      Página {id + 1} de {pages_new.length}
                                    </p>
                                  </div>
                                </div>
                              </div>

                              {/* <div className='nextpage1'></div> */}
                            </div>
                          ))}
                        </div>

                        {/* <div style={{position:'absolute',top:-10000000}} id="pdf_diagramado">

                                    {this.state.pages.map((item, id) => (
                                        <div key={id} style={{width:794,background:'white'}}>
                                            <div ref={(ref) => {
                                                item.elements.map((elem, index) => {
                                                    elem=elem.cloneNode();
                                                    elem.id = 'a'+Math.random();
                                                    if (ref != null) {
                                                        ref.appendChild(elem.cloneNode());
                                                    }
                                                })
                                            }} style={{ columnCount: 2, height: 1123, columnFill: 'auto' }} >

                                            </div>

                                        </div>
                                    ))}
                                    </div> */}
                      </div>
                    </div>
                  </div>

                  {/* <iframe id="iframe"></iframe> */}

                  <div
                    style={{
                      width:
                        model.numberColumns == 1
                          ? width_one_column -
                          (model.marginInternalRight - 40) -
                          (model.marginInternalLeft - 40)
                          : width_two_column,
                      overflow: "scroll",
                      position: "absolute",
                      top: -100000,
                    }}
                  >
                    <div style={{ columnCount: 1 }}>
                      {Object.values(questions).length > 0 &&
                        examSelect.questions != undefined &&
                        examSelect.questions.map((item: any, id: any) => {
                          if (item.type == "objective" || item.type == "text") {
                            number_questao = number_questao + 1;
                          }
                          if (item.question.includes("Um circuito")) {
                            console.log(item.alternatives[2].alternative);
                            console.log(
                              changeFontSizeAlts(
                                item.alternatives[2].alternative.substring(
                                  item.alternatives[2].alternative.indexOf(
                                    ">"
                                  ) + 1,
                                  item.alternatives[2].alternative.length - 4
                                )
                              )
                            );
                            console.log(
                              changeFontSizeAlts(
                                item.alternatives[2].alternative.substring(
                                  0,
                                  item.alternatives[2].alternative.indexOf(">")
                                )
                              ) +
                              '><span style="font-weight:bold">(' +
                              "C)  </span>" +
                              changeFontSizeAlts(
                                item.alternatives[2].alternative.substring(
                                  item.alternatives[2].alternative.indexOf(
                                    ">"
                                  ) + 1,
                                  item.alternatives[2].alternative.length - 4
                                )
                              ) +
                              "</p>"
                            );
                          }
                          return (
                            <div
                              key={id}
                              style={{ marginTop: id == 0 ? 0 : 20 }}
                              id={"container_" + item._id}
                            >
                              {/* <h6 className="mt-0 header-title" style={{ textAlign: 'right', marginBottom: 10, fontSize: 15 }}>
                                                {item.discipline.name}</h6> */}
                              {model.showDiscipline == true &&
                                (id == 0 ||
                                  item.discipline._id !=
                                  examSelect.questions[id - 1].discipline
                                    ._id) && (
                                  <div
                                    id={"categoria_" + item._id}
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <div
                                      style={{
                                        padding: 5,
                                        width: "100%",
                                        borderRadius: 0,
                                        border: "1px solid black",
                                        marginBottom: 15,
                                        marginTop: id != 0 ? 0 : 0,
                                        background: "#f2f2f2",
                                      }}
                                    >
                                      <p
                                        style={{
                                          textTransform: "uppercase",
                                          textAlign: "center",
                                          color: "black",
                                          fontSize: 18,
                                          fontWeight: "500",
                                          marginBottom: 0,
                                        }}
                                      >
                                        {item.discipline.name}
                                      </p>
                                    </div>
                                  </div>
                                )}

                              {item.type == "text" && (
                                <div style={{ fontWeight: "normal" }}>
                                  <div className="">
                                    <h5
                                      style={{
                                        textAlign: "justify",
                                        color: "black",
                                        marginTop: 0,
                                      }}
                                    >
                                      <b>{number_questao})</b>
                                    </h5>
                                  </div>
                                  <div
                                    className=""
                                    id={"enunciado_" + item._id}
                                  >
                                    <div>
                                      <div
                                        style={
                                          model.fontSizeQuestions == "default"
                                            ? {}
                                            : {
                                              fontSize: parseInt(
                                                model.fontSizeQuestions
                                              ),
                                            }
                                        }
                                        className="jodit-wysiwyg"
                                        dangerouslySetInnerHTML={{
                                          __html:
                                            changeFontSizeQuestoes(
                                              item.question.substring(
                                                0,
                                                item.question.indexOf(">")
                                              )
                                            ) +
                                            '><span style="font-weight:bold">' +
                                            number_questao +
                                            ")  </span>" +
                                            changeFontSizeQuestoes(
                                              item.question.substring(
                                                item.question.indexOf(">") + 1,
                                                item.question.length - 4
                                              )
                                            ) +
                                            "</p>",
                                        }}
                                      ></div>
                                      <p
                                        style={{
                                          fontWeight: "600",
                                          marginTop: 5,
                                          fontSize: parseInt(
                                            model.fontSizeQuestions
                                          ),
                                        }}
                                      >
                                        Resposta:
                                      </p>
                                      {/* <div style={{ position: 'relative', display: 'flex', justifyContent: 'space-between' }}>
                                                                    <img height={10} src={TriangleImage} style={{
                                                                        objectFit: 'contain',
                                                                        width: 'auto',
                                                                    }} />
                                                                    <img height={10} src={TriangleImage} style={{
                                                                        objectFit: 'contain',
                                                                        width: 'auto',
                                                                    }} />
                                                                </div> */}

                                      <div
                                        style={{
                                          height: 250,
                                          border: "0.5px solid grey",
                                          borderRadius: 2,
                                          marginTop: 5,
                                        }}
                                      ></div>
                                      {/* <div style={{ position: 'relative', display: 'flex', justifyContent: 'space-between' }}>
                                                                    <img height={10} src={TriangleImage} style={{
                                                                        objectFit: 'contain',
                                                                        width: 'auto',
                                                                    }} />
                                                                    <img height={10} src={TriangleImage} style={{
                                                                        objectFit: 'contain',
                                                                        width: 'auto',
                                                                    }} />
                                                                </div> */}
                                    </div>
                                  </div>
                                  {!(id == examSelect.questions.length - 1) && (
                                    <div
                                      id={"dividor_" + item._id}
                                      style={{
                                        paddingTop: 10,
                                        paddingBottom: 10,
                                      }}
                                    >
                                      <div
                                        style={{
                                          width: "100%",
                                          height: 3,
                                          background: "black",
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      ></div>
                                    </div>
                                  )}
                                </div>
                              )}

                              {item.type == "objective" && (
                                <div
                                  id={"questao_" + item._id}
                                  style={{ fontWeight: "normal" }}
                                >
                                  <div>
                                    {/* <h5 style={{ textAlign: 'justify', color: 'black', marginTop: 0 }}>
                                                        <b>{number_questao})</b></h5> */}
                                    <div className="col-12 col-sm-12">
                                      <div
                                        style={{ marginTop: 0 }}
                                        id={"enunciado_" + item._id}
                                      >
                                        <div>
                                          <div
                                            style={
                                              model.fontSizeQuestions ==
                                                "default"
                                                ? {}
                                                : {
                                                  fontSize: parseInt(
                                                    model.fontSizeQuestions
                                                  ),
                                                }
                                            }
                                            className="jodit-wysiwyg"
                                            dangerouslySetInnerHTML={{
                                              __html:
                                                changeFontSizeQuestoes(
                                                  item.question.substring(
                                                    0,
                                                    item.question.indexOf(">")
                                                  )
                                                ) +
                                                '><span style="font-weight:bold">' +
                                                number_questao +
                                                ")  </span>" +
                                                changeFontSizeQuestoes(
                                                  item.question.substring(
                                                    item.question.indexOf(">") +
                                                    1,
                                                    item.question.length - 4
                                                  )
                                                ) +
                                                "</p>",
                                            }}
                                          ></div>
                                        </div>
                                      </div>

                                      <div className="">
                                        <div
                                          style={{ marginTop: 0 }}
                                          id={
                                            "alternativa_" +
                                            item.alternatives[0]._id
                                          }
                                        >
                                          {/* <div style={{ width: '10%', display: 'flex', alignItems: 'center' }}>
                                                            <p style={{ color: item.correta == 'a' ? 'green' : 'black', fontWeight: item.correta == 'a' ? 'bold' : 'normal' }}>a)</p>
                                                        </div> */}
                                          <div>
                                            <div
                                              style={
                                                model.fontSizeAlternatives ==
                                                  "default"
                                                  ? {}
                                                  : {
                                                    fontSize: parseInt(
                                                      model.fontSizeAlternatives
                                                    ),
                                                  }
                                              }
                                              className="jodit-wysiwyg"
                                              dangerouslySetInnerHTML={{
                                                __html:
                                                  changeFontSizeAlts(
                                                    item.alternatives[0].alternative.substring(
                                                      0,
                                                      item.alternatives[0].alternative.indexOf(
                                                        ">"
                                                      )
                                                    )
                                                  ) +
                                                  '><span style="font-weight:bold">(' +
                                                  "A)  </span>" +
                                                  changeFontSizeAlts(
                                                    item.alternatives[0].alternative.substring(
                                                      item.alternatives[0].alternative.indexOf(
                                                        ">"
                                                      ) + 1,
                                                      item.alternatives[0]
                                                        .alternative.length - 4
                                                    )
                                                  ) +
                                                  "</p>",
                                              }}
                                            ></div>
                                          </div>
                                        </div>
                                        {item.alternatives[1] != undefined && (
                                          <div
                                            style={{ marginTop: 0 }}
                                            id={
                                              "alternativa_" +
                                              item.alternatives[1]._id
                                            }
                                          >
                                            <div>
                                              <div
                                                style={
                                                  model.fontSizeAlternatives ==
                                                    "default"
                                                    ? {}
                                                    : {
                                                      fontSize: parseInt(
                                                        model.fontSizeAlternatives
                                                      ),
                                                    }
                                                }
                                                className="jodit-wysiwyg"
                                                dangerouslySetInnerHTML={{
                                                  __html:
                                                    changeFontSizeAlts(
                                                      item.alternatives[1].alternative.substring(
                                                        0,
                                                        item.alternatives[1].alternative.indexOf(
                                                          ">"
                                                        )
                                                      )
                                                    ) +
                                                    '><span style="font-weight:bold">(' +
                                                    "B)  </span>" +
                                                    changeFontSizeAlts(
                                                      item.alternatives[1].alternative.substring(
                                                        item.alternatives[1].alternative.indexOf(
                                                          ">"
                                                        ) + 1,
                                                        item.alternatives[1]
                                                          .alternative.length -
                                                        4
                                                      )
                                                    ) +
                                                    "</p>",
                                                }}
                                              ></div>
                                            </div>
                                          </div>
                                        )}
                                        {item.alternatives[2] != undefined && (
                                          <div
                                            style={{ marginTop: 0 }}
                                            id={
                                              "alternativa_" +
                                              item.alternatives[2]._id
                                            }
                                          >
                                            <div>
                                              <div
                                                style={
                                                  model.fontSizeAlternatives ==
                                                    "default"
                                                    ? {}
                                                    : {
                                                      fontSize: parseInt(
                                                        model.fontSizeAlternatives
                                                      ),
                                                    }
                                                }
                                                className="jodit-wysiwyg"
                                                dangerouslySetInnerHTML={{
                                                  __html:
                                                    changeFontSizeAlts(
                                                      item.alternatives[2].alternative.substring(
                                                        0,
                                                        item.alternatives[2].alternative.indexOf(
                                                          ">"
                                                        )
                                                      )
                                                    ) +
                                                    '><span style="font-weight:bold">(' +
                                                    "C)  </span>" +
                                                    changeFontSizeAlts(
                                                      item.alternatives[2].alternative.substring(
                                                        item.alternatives[2].alternative.indexOf(
                                                          ">"
                                                        ) + 1,
                                                        item.alternatives[2]
                                                          .alternative.length -
                                                        4
                                                      )
                                                    ) +
                                                    "</p>",
                                                }}
                                              ></div>
                                            </div>
                                          </div>
                                        )}
                                        {item.alternatives[3] != undefined && (
                                          <div
                                            style={{ marginTop: 0 }}
                                            id={
                                              "alternativa_" +
                                              item.alternatives[3]._id
                                            }
                                          >
                                            <div>
                                              <div
                                                style={
                                                  model.fontSizeAlternatives ==
                                                    "default"
                                                    ? {}
                                                    : {
                                                      fontSize: parseInt(
                                                        model.fontSizeAlternatives
                                                      ),
                                                    }
                                                }
                                                className="jodit-wysiwyg"
                                                dangerouslySetInnerHTML={{
                                                  __html:
                                                    changeFontSizeAlts(
                                                      item.alternatives[3].alternative.substring(
                                                        0,
                                                        item.alternatives[3].alternative.indexOf(
                                                          ">"
                                                        )
                                                      )
                                                    ) +
                                                    '><span style="font-weight:bold">(' +
                                                    "D)  </span>" +
                                                    changeFontSizeAlts(
                                                      item.alternatives[3].alternative.substring(
                                                        item.alternatives[3].alternative.indexOf(
                                                          ">"
                                                        ) + 1,
                                                        item.alternatives[3]
                                                          .alternative.length -
                                                        4
                                                      )
                                                    ) +
                                                    "</p>",
                                                }}
                                              ></div>
                                            </div>
                                          </div>
                                        )}

                                        {item.alternatives[4] != undefined && (
                                          <div
                                            style={{ marginTop: 0 }}
                                            id={
                                              "alternativa_" +
                                              item.alternatives[4]._id
                                            }
                                          >
                                            <div>
                                              <div
                                                style={
                                                  model.fontSizeAlternatives ==
                                                    "default"
                                                    ? {}
                                                    : {
                                                      fontSize: parseInt(
                                                        model.fontSizeAlternatives
                                                      ),
                                                    }
                                                }
                                                className="jodit-wysiwyg"
                                                dangerouslySetInnerHTML={{
                                                  __html:
                                                    changeFontSizeAlts(
                                                      item.alternatives[4].alternative.substring(
                                                        0,
                                                        item.alternatives[4].alternative.indexOf(
                                                          ">"
                                                        )
                                                      )
                                                    ) +
                                                    '><span style="font-weight:bold">(' +
                                                    "E)  </span>" +
                                                    changeFontSizeAlts(
                                                      item.alternatives[4].alternative.substring(
                                                        item.alternatives[4].alternative.indexOf(
                                                          ">"
                                                        ) + 1,
                                                        item.alternatives[4]
                                                          .alternative.length -
                                                        4
                                                      )
                                                    ) +
                                                    "</p>",
                                                }}
                                              ></div>
                                            </div>
                                          </div>
                                        )}
                                      </div>

                                      {!(
                                        id ==
                                        examSelect.questions.length - 1
                                      ) && (
                                          <div
                                            id={"dividor_" + item._id}
                                            style={{
                                              paddingTop: 10,
                                              paddingBottom: 10,
                                            }}
                                          >
                                            <div
                                              style={{
                                                width: "100%",
                                                height: 3,
                                                background: "black",
                                                marginTop: 0,
                                                marginBottom: 0,
                                              }}
                                            ></div>
                                          </div>
                                        )}
                                    </div>
                                  </div>
                                </div>
                              )}

                              {item.type == "set" && (
                                <div
                                  id={"questao_" + item._id}
                                  style={{ fontWeight: "normal" }}
                                >
                                  <div className="col-12 col-sm-12">
                                    <div
                                      style={{ marginTop: 0 }}
                                      id={"enunciado_" + item._id}
                                    >
                                      <div>
                                        <div
                                          style={
                                            model.fontSizeQuestions == "default"
                                              ? {}
                                              : {
                                                fontSize: parseInt(
                                                  model.fontSizeQuestions
                                                ),
                                              }
                                          }
                                          className="jodit-wysiwyg"
                                          dangerouslySetInnerHTML={{
                                            __html: changeFontSizeQuestoes(
                                              item.question
                                            ),
                                          }}
                                        ></div>
                                      </div>
                                    </div>

                                    <div
                                      id={"dividor_" + item._id}
                                      style={{
                                        paddingTop: 10,
                                        paddingBottom: 10,
                                      }}
                                    >
                                      <div
                                        style={{
                                          width: "100%",
                                          height: 3,
                                          background: "black",
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      ></div>
                                    </div>

                                    {item.questions.map(
                                      (item2: any, id2: any) => {
                                        number_questao = number_questao + 1;

                                        return (
                                          <div
                                            key={id2}
                                            id={"questao_" + item2._id}
                                          >
                                            <div
                                              style={{ fontWeight: "normal" }}
                                            >
                                              <div className="">
                                                <div
                                                  style={{ marginTop: 0 }}
                                                  id={"enunciado_" + item2._id}
                                                >
                                                  <div>
                                                    <div
                                                      style={
                                                        model.fontSizeQuestions ==
                                                          "default"
                                                          ? {}
                                                          : {
                                                            fontSize:
                                                              parseInt(
                                                                model.fontSizeQuestions
                                                              ),
                                                          }
                                                      }
                                                      className="jodit-wysiwyg"
                                                      dangerouslySetInnerHTML={{
                                                        __html:
                                                          changeFontSizeQuestoes(
                                                            item2.question.substring(
                                                              0,
                                                              item2.question.indexOf(
                                                                ">"
                                                              )
                                                            )
                                                          ) +
                                                          '><span style="font-weight:bold">' +
                                                          number_questao +
                                                          ")  </span>" +
                                                          changeFontSizeQuestoes(
                                                            item2.question.substring(
                                                              item2.question.indexOf(
                                                                ">"
                                                              ) + 1,
                                                              item2.question
                                                                .length - 4
                                                            )
                                                          ) +
                                                          "</p>",
                                                      }}
                                                    ></div>
                                                  </div>
                                                </div>

                                                <div
                                                  style={{ marginTop: 0 }}
                                                  id={
                                                    "alternativa_" +
                                                    item2.alternatives[0]._id
                                                  }
                                                >
                                                  {/* <div style={{ width: '10%', display: 'flex', alignItems: 'center' }}>
                                                            <p style={{ color: item2.correta == 'a' ? 'green' : 'black', fontWeight: item2.correta == 'a' ? 'bold' : 'normal' }}>a)</p>
                                                        </div> */}
                                                  <div>
                                                    <div
                                                      style={
                                                        model.fontSizeAlternatives ==
                                                          "default"
                                                          ? {}
                                                          : {
                                                            fontSize:
                                                              parseInt(
                                                                model.fontSizeAlternatives
                                                              ),
                                                          }
                                                      }
                                                      className="jodit-wysiwyg"
                                                      dangerouslySetInnerHTML={{
                                                        __html:
                                                          changeFontSizeAlts(
                                                            item2.alternatives[0].alternative.substring(
                                                              0,
                                                              item2.alternatives[0].alternative.indexOf(
                                                                ">"
                                                              )
                                                            )
                                                          ) +
                                                          '><span style="font-weight:bold">(' +
                                                          "A)  </span>" +
                                                          changeFontSizeAlts(
                                                            item2.alternatives[0].alternative.substring(
                                                              item2.alternatives[0].alternative.indexOf(
                                                                ">"
                                                              ) + 1,
                                                              item2
                                                                .alternatives[0]
                                                                .alternative
                                                                .length - 4
                                                            )
                                                          ) +
                                                          "</p>",
                                                      }}
                                                    ></div>
                                                  </div>
                                                </div>
                                                {item2.alternatives[1] !=
                                                  undefined && (
                                                    <div
                                                      id={
                                                        "alternativa_" +
                                                        item2.alternatives[1]._id
                                                      }
                                                      style={{ marginTop: 0 }}
                                                    >
                                                      <div>
                                                        <div
                                                          style={
                                                            model.fontSizeAlternatives ==
                                                              "default"
                                                              ? {}
                                                              : {
                                                                fontSize:
                                                                  parseInt(
                                                                    model.fontSizeAlternatives
                                                                  ),
                                                              }
                                                          }
                                                          className="jodit-wysiwyg"
                                                          dangerouslySetInnerHTML={{
                                                            __html:
                                                              changeFontSizeAlts(
                                                                item2.alternatives[1].alternative.substring(
                                                                  0,
                                                                  item2.alternatives[1].alternative.indexOf(
                                                                    ">"
                                                                  )
                                                                )
                                                              ) +
                                                              '><span style="font-weight:bold">(' +
                                                              "B)  </span>" +
                                                              changeFontSizeAlts(
                                                                item2.alternatives[1].alternative.substring(
                                                                  item2.alternatives[1].alternative.indexOf(
                                                                    ">"
                                                                  ) + 1,
                                                                  item2
                                                                    .alternatives[1]
                                                                    .alternative
                                                                    .length - 4
                                                                )
                                                              ) +
                                                              "</p>",
                                                          }}
                                                        ></div>
                                                      </div>
                                                    </div>
                                                  )}
                                                {item2.alternatives[2] !=
                                                  undefined && (
                                                    <div
                                                      id={
                                                        "alternativa_" +
                                                        item2.alternatives[2]._id
                                                      }
                                                      style={{ marginTop: 0 }}
                                                    >
                                                      <div>
                                                        <div
                                                          style={
                                                            model.fontSizeAlternatives ==
                                                              "default"
                                                              ? {}
                                                              : {
                                                                fontSize:
                                                                  parseInt(
                                                                    model.fontSizeAlternatives
                                                                  ),
                                                              }
                                                          }
                                                          className="jodit-wysiwyg"
                                                          dangerouslySetInnerHTML={{
                                                            __html:
                                                              changeFontSizeAlts(
                                                                item2.alternatives[2].alternative.substring(
                                                                  0,
                                                                  item2.alternatives[2].alternative.indexOf(
                                                                    ">"
                                                                  )
                                                                )
                                                              ) +
                                                              '><span style="font-weight:bold">(' +
                                                              "C)  </span>" +
                                                              changeFontSizeAlts(
                                                                item2.alternatives[2].alternative.substring(
                                                                  item2.alternatives[2].alternative.indexOf(
                                                                    ">"
                                                                  ) + 1,
                                                                  item2
                                                                    .alternatives[2]
                                                                    .alternative
                                                                    .length - 4
                                                                )
                                                              ) +
                                                              "</p>",
                                                          }}
                                                        ></div>
                                                      </div>
                                                    </div>
                                                  )}
                                                {item2.alternatives[3] !=
                                                  undefined && (
                                                    <div
                                                      id={
                                                        "alternativa_" +
                                                        item2.alternatives[3]._id
                                                      }
                                                      style={{ marginTop: 0 }}
                                                    >
                                                      <div>
                                                        <div
                                                          style={
                                                            model.fontSizeAlternatives ==
                                                              "default"
                                                              ? {}
                                                              : {
                                                                fontSize:
                                                                  parseInt(
                                                                    model.fontSizeAlternatives
                                                                  ),
                                                              }
                                                          }
                                                          className="jodit-wysiwyg"
                                                          dangerouslySetInnerHTML={{
                                                            __html:
                                                              changeFontSizeAlts(
                                                                item2.alternatives[3].alternative.substring(
                                                                  0,
                                                                  item2.alternatives[3].alternative.indexOf(
                                                                    ">"
                                                                  )
                                                                )
                                                              ) +
                                                              '><span style="font-weight:bold">(' +
                                                              "D)  </span>" +
                                                              changeFontSizeAlts(
                                                                item2.alternatives[3].alternative.substring(
                                                                  item2.alternatives[3].alternative.indexOf(
                                                                    ">"
                                                                  ) + 1,
                                                                  item2
                                                                    .alternatives[3]
                                                                    .alternative
                                                                    .length - 4
                                                                )
                                                              ) +
                                                              "</p>",
                                                          }}
                                                        ></div>
                                                      </div>
                                                    </div>
                                                  )}

                                                {item2.alternatives[4] !=
                                                  undefined && (
                                                    <div
                                                      id={
                                                        "alternativa_" +
                                                        item2.alternatives[4]._id
                                                      }
                                                      style={{ marginTop: 0 }}
                                                    >
                                                      <div>
                                                        <div
                                                          style={
                                                            model.fontSizeAlternatives ==
                                                              "default"
                                                              ? {}
                                                              : {
                                                                fontSize:
                                                                  parseInt(
                                                                    model.fontSizeAlternatives
                                                                  ),
                                                              }
                                                          }
                                                          className="jodit-wysiwyg"
                                                          dangerouslySetInnerHTML={{
                                                            __html:
                                                              changeFontSizeAlts(
                                                                item2.alternatives[4].alternative.substring(
                                                                  0,
                                                                  item2.alternatives[4].alternative.indexOf(
                                                                    ">"
                                                                  )
                                                                )
                                                              ) +
                                                              '><span style="font-weight:bold">(' +
                                                              "E)  </span>" +
                                                              changeFontSizeAlts(
                                                                item2.alternatives[4].alternative.substring(
                                                                  item2.alternatives[4].alternative.indexOf(
                                                                    ">"
                                                                  ) + 1,
                                                                  item2
                                                                    .alternatives[4]
                                                                    .alternative
                                                                    .length - 4
                                                                )
                                                              ) +
                                                              "</p>",
                                                          }}
                                                        ></div>
                                                      </div>
                                                    </div>
                                                  )}
                                              </div>
                                            </div>
                                            {!(
                                              id2 ==
                                              item.questions.length - 1 &&
                                              id ==
                                              examSelect.questions.length - 1
                                            ) && (
                                                <div
                                                  id={"dividor_" + item2._id}
                                                  style={{
                                                    paddingTop: 10,
                                                    paddingBottom: 10,
                                                  }}
                                                >
                                                  <div
                                                    style={{
                                                      width: "100%",
                                                      height: 3,
                                                      background: "black",
                                                      marginTop: 0,
                                                      marginBottom: 0,
                                                    }}
                                                  ></div>
                                                </div>
                                              )}
                                          </div>
                                        );
                                      }
                                    )}
                                  </div>
                                </div>
                              )}
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </Form>
                {/* {!props.justView && examSelect._id == "" && (
                  //   <DefaultButton
                  //     bg="secondary"
                  //     text="Voltar"
                  //     icon={<RiArrowGoBackLine />}
                  //     search={"?tab=2"}
                  //     to={`/layouts_exams`}
                  //   />
                  <Button
                    variant="secondary"
                    onClick={() => history("/tests/view/"+(edit==true?model.application_id: params.test_id)+"?tab=3")}
                    className="flex align-center jcc mt-3"
                  >
                    <RiArrowGoBackLine style={{ marginRight: 10 }} /> Voltar
                  </Button>
                )} */}
              </div>
            )}

            {loading_screen == true && (
              <div className="row">
                {" "}
                <div
                  className="col-12 d-flex justify-content-center align-items-center"
                  style={{ marginTop: "2rem" }}
                >
                  <div
                    className="spinner-border text-primary"
                    role="status"
                  ></div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
