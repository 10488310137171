// src/apis/productAPI.js

import { API_URI } from "../../constants/consts";
import { api } from "./configs/axiosConfigs";

const API_PATH = API_URI + "/exams";

export const ExamsAPI = {
  uploadFile: async function (
    token: string,
    data: any,
    ref: any,
    callbackFn: (ref: any, a: any) => void
  ) {
    var formData = new FormData();
    const f: File = data.file;
    formData.append("image", f);
    formData.append("ref", ref);
    return api
      .request({
        url: `${API_PATH}/upload-photo`,
        method: "POST",
        data: formData,
        headers: {
          Authorization: token,
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (event: any) => {
          if (!event) return;
          const progress: number = Math.round(
            (event.loaded * 100) / event?.total
          );
          console.log(`A imagem está ${progress}% carregada... `);
          callbackFn(ref, progress);
        },
      })
      .then((response) => {
        const payload = response.data;
        return payload;
      });
  },
  getAllFromStudent: async function (
    token = "",
    page: any,
    search: any,
    cancel = false
  ) {
    return api
      .request({
        url: `${API_PATH}/all-student/?page=${page}&search=${search ?? ""}`,
        method: "GET",
        headers: {
          Authorization: token,
        },
        // signal: cancel
        //   ? cancelApiObject[this.getAll.name].handleRequestCancellation().signal
        //   : undefined,
      })
      .then((response) => {
        const payload = response.data.data;
        return payload;
      });
  },

  getAllFromStudentAsOther: async function (
    token = "",
    page: any,
    search: any,
    cancel = false,
    id: string
  ) {
    return api
      .request({
        url: `${API_PATH}/all-student-as-other/${id}?page=${page}&search=${
          search ?? ""
        }`,
        method: "GET",
        headers: {
          Authorization: token,
        },
        // signal: cancel
        //   ? cancelApiObject[this.getAll.name].handleRequestCancellation().signal
        //   : undefined,
      })
      .then((response) => {
        const payload = response.data.data;
        return payload;
      });
  },
  getAll: async function (token = "", page: any, search: any,test_id:any,all:any, cancel = false) {
    return api
      .request({
        url: `${API_PATH}?page=${page}&test_id=${test_id}&all=${all}&search=${search ?? ""}`,
        method: "GET",
        headers: {
          Authorization: token,
        },
        // signal: cancel
        //   ? cancelApiObject[this.getAll.name].handleRequestCancellation().signal
        //   : undefined,
      })
      .then((response) => {
        const payload = response.data.data;
        return payload;
      });
  },
  getFiles: async function (
    token = "",
    id: any,
    page: any,
    search: any,
    cancel = false
  ) {
    return api
      .request({
        url: `${API_PATH}/files/${id}?page=${page}&search=${search ?? ""}`,
        method: "GET",
        headers: {
          Authorization: token,
        },
        // signal: cancel
        //   ? cancelApiObject[this.getAll.name].handleRequestCancellation().signal
        //   : undefined,
      })
      .then((response) => {
        const payload = response.data.data;
        return payload;
      });
  },
  getJobs: async function (token = "", id: any, cancel = false) {
    return api
      .request({
        url: `${API_PATH}/jobs/${id}`,
        method: "GET",
        headers: {
          Authorization: token,
        },
        // signal: cancel
        //   ? cancelApiObject[this.getAll.name].handleRequestCancellation().signal
        //   : undefined,
      })
      .then((response) => {
        const payload = response.data.data;
        return payload;
      });
  },
  getById: async function (token = "", id: any, cancel = false) {
    return api
      .request({
        url: `${API_PATH}/${id}`,
        method: "GET",
        headers: {
          Authorization: token,
        },
        // signal: cancel
        //   ? cancelApiObject[this.getAll.name].handleRequestCancellation().signal
        //   : undefined,
      })
      .then((response) => {
        const payload = response.data.data;
        return payload;
      });
  },
  getByIdAsOther: async function (
    token = "",
    id: any,
    cancel = false,
    sid: any
  ) {
    return api
      .request({
        url: `${API_PATH}/student-as-other/${sid}/${id}`,
        method: "GET",
        headers: {
          Authorization: token,
        },
        // signal: cancel
        //   ? cancelApiObject[this.getAll.name].handleRequestCancellation().signal
        //   : undefined,
      })
      .then((response) => {
        const payload = response.data.data;
        return payload;
      });
  },
  getByIdAsStudent: async function (token = "", id: any, cancel = false) {
    return api
      .request({
        url: `${API_PATH}/student/${id}`,
        method: "GET",
        headers: {
          Authorization: token,
        },
        // signal: cancel
        //   ? cancelApiObject[this.getAll.name].handleRequestCancellation().signal
        //   : undefined,
      })
      .then((response) => {
        const payload = response.data.data;
        return payload;
      });
  },
  save: async function (token: string, data: any, cancel = false) {
    console.log(data);
    return api
      .request({
        url: `${API_PATH}`,
        method: "POST",
        data: data,
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
        // signal: cancel
        //   ? cancelApiObject[this.getAll.name].handleRequestCancellation().signal
        //   : undefined,
      })
      .then((response) => {
        const payload = response.data;
        return payload;
      });
  },
  saveZip: async function (
    token: string,
    data: any,
    callback: any,
    cancel = false
  ) {
    console.log(data);
    return api
      .request({
        url: `${API_PATH}/save_zip`,
        method: "POST",
        data: data,
        headers: {
          Authorization: token,
        },
        onUploadProgress: (e) => {
          callback(e);
        },
        // signal: cancel
        //   ? cancelApiObject[this.getAll.name].handleRequestCancellation().signal
        //   : undefined,
      })
      .then((response) => {
        const payload = response.data;
        return payload;
      });
  },
  update: async function (token: string, data: any, cancel = false) {
    return api
      .request({
        url: `${API_PATH}/${data._id}`,
        method: "PUT",
        data: data,
        headers: {
          Authorization: token,
        },
        // signal: cancel
        //   ? cancelApiObject[this.getAll.name].handleRequestCancellation().signal
        //   : undefined,
      })
      .then((response) => {
        const payload = response.data;
        return payload;
      });
  },
  delete: async function (token = "", id: any,test_id:any, cancel = false) {
    return api
      .request({
        url: `${API_PATH}/${id}?test_id=${test_id}`,
        method: "DELETE",
        headers: {
          Authorization: token,
        },
        // signal: cancel
        //   ? cancelApiObject[this.getAll.name].handleRequestCancellation().signal
        //   : undefined,
      })
      .then((response) => {
        const payload = response.data;
        return payload;
      });
  },
};

// defining the cancel API object for ProductAPI
// const cancelApiObject = defineCancelApiObject(QuestionsAPI);
