import { useEffect, useRef, useState } from "react";
import { connect, useSelector } from "react-redux";

// unidades
import { fetchList } from "../../../services/stores/disciplineStore/async/fetchListThunk";
import { useAppSelector } from "../../../services/stores/hooks/useAppSelector";
import { useAppDispatch } from "../../../services/stores/hooks/useAppDispatch";
import {
  Form,
  FormControl as Input,
  InputGroup,
  Button,
  Spinner,
} from "react-bootstrap";
import { Attention } from "../../../component/base/Attention";
import { Close, SaveRounded } from "@material-ui/icons";
import {
  DatePicker,
  TimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import { RiArrowGoBackLine } from "react-icons/ri";
import DefaultButton from "../../../component/base/DefaultButton";
import { save } from "../../../services/stores/examStore/async/saveThunk";
import { useNavigate, useParams } from "react-router-dom";
import {
  setLoading,
  setModel,
  setErrorMessage,
  clearState,
  setId,
  setLoadingScreen,
  setState,
} from "../../../services/stores/examStore/actions";
import { setState as setStateQuestion } from "../../../services/stores/questionStore/actions";

import { update } from "../../../services/stores/examStore/async/updateThunk";
import { fetchId } from "../../../services/stores/examStore/async/fetchIdThunk";
import { TableDrag } from "../TableDrag";
import { fetchListFiltered } from "../../../services/stores/examStore/async/fetchListFilteredThunk";
import { QuestionModalView } from "../../QuestionModalView";
import { ExamModalTag } from "../../ExamModalTag";

export function ExamsCreate() {
  const {
    error_message,
    model,
    loading_save,
    loading_screen,
    questionsArray,
    loading_input,
    disciplineSelect,
    questionContent,
    questionsSearch,
    showAutoCompleteQuestion,
    loading_search,
    showModalTag,
    questions,
  } = useAppSelector((store) => store.exams);
  const dispatch = useAppDispatch();
  const { data } = useAppSelector((store) => store.disciplines);
  const [show_warning, setShowWarning] = useState(false);
  // const [loading_screen, setLoadingScreen] = useState(false);
  const timeout_: any = useRef();
  const timeout_search: any = useRef();

  const [search, setSearch] = useState("");
  const [edit, setEdit] = useState(false);

  const history = useNavigate();
  const params = useParams();

  const saveContent = () => {
    let model_: any = { ...model };
    model_.questions = questionsArray.map((item: any, id) => item._id);
    console.log("entrou");
    dispatch(setModel(model_));
    if (edit == true) {
      dispatch(update()).then((result) => {
        console.log(result);
        if (result?.payload?.status == "success") {
          history("/exams");
        }
      });
    } else {
      dispatch(save()).then((result) => {
        console.log(result);
        if (result?.payload?.status == "success") {
          history("/exams");
        }
      });
    }
  };

  useEffect(() => {
    // const exam_id = history.pa
    dispatch(setLoadingScreen(true));
    dispatch(fetchList({ force: true })).then((data) => {
      console.log(data);
      if (params.id == undefined) {
        dispatch(setLoadingScreen(false));
      }
    });
    if (params.id != undefined) {
      dispatch(setId(params.id));
      setEdit(true);
      dispatch(fetchId({ force: true }));
    }

    return () => {
      dispatch(clearState());
    };
  }, []);

  const parseQuestoesDragDrop = () => {
    let questoes: any = [...questionsArray];
    let ids = questoes.map((item: any, id: any) => item._id);
    let ids_exists: any = [];

    for (let i = 0; i < Object.values(questions).length; i++) {
      let item: any = Object.values(questions)[i];
      ids_exists.push(item._id);

      if (!ids.includes(item._id)) {
        questoes.push({
          numero: i + 1,
          _id: item._id,
          id: item._id,
          index: i,
          enunciado: <p className="text_overflow">{item.question_content}</p>,
          categoria: item.discipline.name,
          tipo_questao: (
            <p style={{ whiteSpace: "pre" }}>
              {item.type == "set"
                ? "Conjunto de questões\n" +
                  item.questions.length +
                  (item.questions.length == 1 ? " questão" : " questões")
                : item.type == "objective"
                ? "Questão única"
                : "Questão dissertativa"}
            </p>
          ),
          acoes: i,
        });
      }
    }
    questoes = questoes.filter((item: any, id: any) => {
      if (ids_exists.includes(item._id)) {
        return true;
      }
      return false;
    });

    dispatch(setState({ questionsArray: questoes }));
  };

  useEffect(() => {
    parseQuestoesDragDrop();
  }, [questions]);
  console.log(edit);
  return (
    <div className="row">
      <div className="col-12">
        <div className="py-5 px-3">
          <div className="card-body" id="card" style={{ overflow: "visible" }}>
            <h4
              className="mt-0 header-title"
              style={{ fontSize: "1.5rem", color: "black" }}
            >
              {edit == true ? "Editar" : "Cadastrar"} Prova
            </h4>
            <p style={{ fontSize: "1rem", color: "black" }}>
              {edit == false
                ? "Cadastre as provas do seu sistema"
                : "Edite uma prova do seu sistema"}
            </p>
            <br />

            {/* <hr /> */}
            {loading_screen == false && (
              <div>
                <QuestionModalView />
                <ExamModalTag />

                {error_message != null && (
                  <div
                    style={{ display: "flex", marginBottom: 15, width: "100%" }}
                  >
                    <div
                      className="alert alert-danger alert-dismissible fade show mb-0 w-100"
                      role="alert"
                    >
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="alert"
                        aria-label="Close"
                        onClick={() => dispatch(setErrorMessage(null))}
                      ></button>

                      <p style={{ marginBottom: 0, whiteSpace: "pre-line" }}>
                        {error_message}
                      </p>
                    </div>
                    <br />
                  </div>
                )}

                <Form
                  onSubmit={(e) => {
                    e.preventDefault();
                    saveContent();
                  }}
                  style={{ position: "relative" }}
                >
                  <InputGroup>
                    <div className="flex fdcolumn flex1 margin-bottom">
                      <Form.Label>
                        Nome <Attention />
                      </Form.Label>
                      <Input
                        placeholder="Nome da prova"
                        value={model.name}
                        onChange={(e) => {
                          let model_ = { ...model };
                          model_.name = e.target.value;
                          dispatch(setModel(model_));
                        }}
                        required
                        // title="Coloque o nome completo do usuário"
                      />
                    </div>
                  </InputGroup>

                  <InputGroup>
                    <div className="flex fdcolumn flex1 margin-bottom">
                      <Form.Label>Descrição </Form.Label>
                      <Input
                        placeholder="Descrição da prova"
                        value={model.description}
                        onChange={(e) => {
                          let model_ = { ...model };
                          model_.description = e.target.value;
                          dispatch(setModel(model_));
                        }}
                        as="textarea"
                        // title="Coloque o nome completo do usuário"
                      />
                    </div>
                  </InputGroup>
                  <InputGroup>
                    <div className="flex fdcolumn flex1 margin-bottom">
                      <Form.Label>
                        Tipo de prova <Attention />
                      </Form.Label>

                      <select
                        disabled={loading_input || params?.id != undefined}
                        className="form-select "
                        onChange={(e) => {
                          let model_ = { ...model };
                          model_.type = e.target.value;
                          model_.questions = [];
                          dispatch(
                            setState({
                              model: model_,
                              questionContent: "",
                              questionsSearch: [],
                              questions: {},
                              questionsArray: [],
                              disciplineSelect: { _id: "", name: "" },
                            })
                          );
                        }}
                        value={model.type}
                      >
                        <option value="objective">Prova Objetiva</option>
                        <option value="text">Prova Dissertativa</option>
                      </select>
                    </div>
                  </InputGroup>

                  <h5 style={{ fontWeight: "bold" }}>Questões</h5>
                  <br />
                  <InputGroup>
                    <div className="flex fdcolumn flex1 margin-bottom">
                      <Form.Label>Selecione uma disciplina</Form.Label>

                      <select
                        disabled={loading_input}
                        className="form-select "
                        onChange={(e) => {
                          if (e.target.value != "") {
                            let categoria = data.filter(
                              (item: any, id: any) => item._id == e.target.value
                            )[0];
                            dispatch(
                              setState({
                                disciplineSelect: categoria,
                                questionContent: "",
                                questionsSearch: [],
                              })
                            );
                          } else {
                            dispatch(
                              setState({
                                disciplineSelect: { _id: "", name: "" },
                                questionContent: "",
                                questionsSearch: [],
                              })
                            );
                          }
                        }}
                        value={disciplineSelect._id}
                      >
                        <option value="">Selecione uma disciplina</option>
                        {data &&
                          data.length != 0 &&
                          data.map((value: any, index: any) => (
                            <option key={index} value={value["_id"]}>
                              {value["name"]}
                            </option>
                          ))}
                        -
                      </select>
                      {disciplineSelect._id != "" && (
                        <div style={{ display: "flex", flexWrap: "wrap" }}>
                          <a
                            onClick={() => {
                              // this.get_questoes_categoria(this.state.categoria._id);
                              dispatch(setState({ questionContent: "" }));

                              dispatch(fetchListFiltered({ force: true })).then(
                                (data) => {
                                  console.log(data);
                                }
                              );
                            }}
                            style={{
                              textAlign: "left",
                              fontSize: 15,
                              marginTop: 10,
                              marginRight: 20,
                              cursor: "pointer",
                              textDecoration: "none",
                            }}
                            className="link-success"
                          >
                            Selecionar todas as questões desta disciplina
                          </a>

                          <a
                            onClick={() => {
                              dispatch(setState({ showModalTag: true }));
                              // this.setState({ showModalPalavraChave: true });
                            }}
                            style={{
                              textAlign: "left",
                              fontSize: 15,
                              marginTop: 10,
                              cursor: "pointer",
                              textDecoration: "none",
                            }}
                            className="link-success"
                          >
                            Selecionar questões por uma tag
                          </a>
                        </div>
                      )}
                    </div>
                  </InputGroup>

                  {disciplineSelect._id != "" && (
                    <InputGroup>
                      <div className="flex fdcolumn flex1 margin-bottom">
                        <Form.Label>
                          Busque questões da disciplina {disciplineSelect.name}{" "}
                        </Form.Label>
                        <Input
                          disabled={loading_input}
                          placeholder="Pesquise a questão pelo enunciado"
                          value={questionContent}
                          // onChange={(e) => this.setState({ questao_enunciado: e.target.value })}
                          onBlur={() => {
                            timeout_.current = setTimeout(() => {
                              // this.setState({ show_autocomplete_questao: false });
                              dispatch(
                                setState({ showAutoCompleteQuestion: false })
                              );
                            }, 500);
                          }}
                          onFocus={() => {
                            clearTimeout(timeout_.current);
                            dispatch(
                              setState({ showAutoCompleteQuestion: true })
                            );

                            // this.setState({ show_autocomplete_questao: true })
                          }}
                          onChange={(e) => {
                            clearTimeout(timeout_search.current);

                            dispatch(
                              setState({ questionContent: e.target.value })
                            );
                            if (e.target.value.length >= 1) {
                              timeout_search.current = setTimeout(() => {
                                if (e.target.value.length >= 1) {
                                  dispatch(
                                    fetchListFiltered({ force: true })
                                  ).then((data) => {
                                    console.log(data);
                                  });
                                }
                              }, 700);
                            } else {
                              dispatch(setState({ questionsSearch: [] }));
                            }

                            // this.get_questao_search(e.target.value, this.state.categoria._id);
                            // this.setState({ questao_enunciado: e.target.value })
                          }}
                          // title="Coloque o nome completo do usuário"
                        />
                        {showAutoCompleteQuestion == true &&
                          questionContent != "" && (
                            <div
                              className="overflow-auto"
                              style={{
                                width: "100%",
                                position: "absolute",
                                zIndex: 20,
                                backgroundColor: "white",
                                border: "1px solid lightgrey",
                                borderRadius: "5px",
                                maxHeight: "50vh",
                                top: 70,
                              }}
                            >
                              {loading_search == false &&
                                questionsSearch.map((item: any, id: any) => (
                                  <div
                                    key={id}
                                    onClick={() => {
                                      // this.select_questao(item)
                                      let questoes: any = { ...questions };
                                      if (questoes[item._id] == undefined) {
                                        questoes[item._id] = item;
                                      }
                                      dispatch(
                                        setState({
                                          questionContent: "",
                                          questionsSearch: [],
                                          showAutoCompleteQuestion: false,
                                          questions: questoes,
                                        })
                                      );
                                      // this.setState({ questao_enunciado: '', show_autocomplete_questao: false, questoes_selecionadas: questoes })
                                    }}
                                    className="opcao_autocomplete"
                                  >
                                    <b>
                                      {item.discipline.name} -{" "}
                                      {item.type == "set"
                                        ? "Conjunto de questões: " +
                                          item.questions.length +
                                          (item.questions.length == 1
                                            ? " questão"
                                            : " questões")
                                        : item.type == "objective"
                                        ? "Questão única"
                                        : "Questão dissertativa"}
                                    </b>{" "}
                                    -{" "}
                                    {item.question_content.length < 200
                                      ? item.question_content
                                      : item.question_content.substring(
                                          0,
                                          200
                                        ) + "..."}
                                  </div>
                                ))}
                              {loading_search == false &&
                                questionsSearch.length == 0 && (
                                  <div className="opcao_autocomplete">
                                    {questionContent.length == 0
                                      ? "Digite o enunciado da questão que deseja buscar"
                                      : " Nenhuma questão encontrada com este termo"}
                                  </div>
                                )}
                              {loading_search == true && (
                                <div
                                  style={{
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  <div
                                    className="spinner-border text-primary"
                                    role="status"
                                  ></div>
                                </div>
                              )}
                            </div>
                          )}
                      </div>
                    </InputGroup>
                  )}

                  <Form.Label>Questões selecionadas</Form.Label>
                  <br />
                  <Form.Label>
                    Total:{" "}
                    <span style={{ fontWeight: "normal" }}>
                      {Object.values(questions)
                        .map((item: any, id: any) => {
                          if (item.type == "set") {
                            return item.questions.length;
                          } else {
                            return 1;
                          }
                        })
                        .reduce((a, b) => a + b, 0)}{" "}
                      {Object.values(questions)
                        .map((item: any, id: any) => {
                          if (item.type == "set") {
                            return item.questions.length;
                          } else {
                            return 1;
                          }
                        })
                        .reduce((a, b) => a + b, 0) == 1
                        ? "questão"
                        : "questões"}
                    </span>
                  </Form.Label>

                  <InputGroup>
                    <TableDrag
                      draggable={true}
                      data={questionsArray}
                      showData={(index: any) => {
                        let questions_array: any = questionsArray;
                        let questao_id = questions_array[index]!._id;
                        let questions_: any = questions;
                        dispatch(
                          setStateQuestion({
                            showModalView: true,
                            questionSelect: { ...questions_[questao_id] },
                          })
                        );
                      }}
                      deleteData={(index: any) => {
                        let questions_array: any = questionsArray;

                        let questao_id = questions_array[index]._id;
                        let questions_: any = questions;

                        let questoes_selecionadas = { ...questions_ };
                        delete questoes_selecionadas[questao_id];
                        let questoes_array = questions_array.filter(
                          (item: any, id: any) => item._id != questao_id
                        );
                        dispatch(
                          setState({
                            questions: questoes_selecionadas,
                            questionsArray: questoes_array,
                          })
                        );
                        // this.setState({ questoes_selecionadas, questoes_array });
                      }}
                      setData={(update_: any) => {
                        if (update_ != undefined) {
                          dispatch(setState({ questionsArray: update_ }));
                        } else {
                          console.log(update_);
                        }
                      }}
                    />
                  </InputGroup>

                  {loading_save == false && (
                    <div className="w100 inline-flex jcsb mt-3">
                      {/* <DefaultButton
                        bg="secondary"
                        text="Voltar"
                        icon={<RiArrowGoBackLine />}
                        to="/exams"
                      /> */}
                      <Button
                        variant="secondary"
                        className="flex align-center jcc"
                        onClick={() => history("/exams")}
                      >
                        <RiArrowGoBackLine style={{ marginRight: 10 }} /> Voltar
                      </Button>
                      {/* <Button variant="success" type="submit" disabled={this.loading}>
                        {this.state.loading ? <Spinner /> : ""}
                        Registrar usuário
                    </Button> */}
                      {/* <DefaultButton
                        type="submit"
                        bg="confirm"
                        text="Salvar prova"
                        disabled={loading_save}
                        loadingtext="Salvando prova"
                        loading={loading_save}
                      /> */}
                      <Button
                        variant="success"
                        type="submit"
                        disabled={loading_save}
                        className="flex align-center jcc"
                      >
                        <SaveRounded style={{ marginRight: 10 }} /> Salvar
                      </Button>
                    </div>
                  )}

                  {loading_save == true && (
                    <div className="row">
                      {" "}
                      <div className="col-12 d-flex justify-content-center align-items-center margin-top-1">
                        <div
                          className="spinner-border text-primary"
                          role="status"
                        ></div>
                      </div>
                    </div>
                  )}
                </Form>
              </div>
            )}

            {loading_screen == true && (
              <div className="row">
                {" "}
                <div
                  className="col-12 d-flex justify-content-center align-items-center"
                  style={{ marginTop: "2rem" }}
                >
                  <div
                    className="spinner-border text-primary"
                    role="status"
                  ></div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
