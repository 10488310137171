import React, { useEffect, useRef, useState } from "react";
// unidades
import { useAppSelector } from "../../../services/stores/hooks/useAppSelector";
import { useAppDispatch } from "../../../services/stores/hooks/useAppDispatch";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import DefaultButton from "../../../component/base/DefaultButton";
import { RiArrowGoBackLine } from "react-icons/ri";
import { TextReadMore } from "../../../component/base/TextReadMore";
import { CircularProgress, Tab, Tabs } from "@material-ui/core";

import TabPanel from "../../../component/base/TabPanel";
import { Grid } from "../../../component/list/grid";
import { fetchList } from "../../../services/stores/demandManagerStore/async/fetchListThunk";
import { Alert } from "../../../component/list/alert";
import { fetchId } from "../../../services/stores/testStore/async/fetchIdThunk";
import {
  setId,
  setState,
  setWarning,
} from "../../../services/stores/testStore/actions";
import { deleteClassItem } from "../../../services/stores/testStore/async/deleteClassThunk";
import { Button } from "react-bootstrap";
import { MdFileDownload } from "react-icons/md";
import { fetchDownload } from "../../../services/stores/layoutExamStore/async/fetchDownload";
import { STORAGE_URL } from "../../../constants/consts";
import { fetchLayoutCorrectors } from "../../../services/stores/layoutExamStore/async/fetchTeacherThunk";
import { removeCorrector } from "../../../services/stores/layoutExamStore/async/removeCorrectorThunk";
import { fetchIdRegistration } from "../../../services/stores/testStore/async/fetchRegistrationsIdThunk";
import { List } from "../../../component/list/list";
import { ExamsView } from "../../ExamsView/list";
import { LayoutsExamsView } from "../../LayoutsExamsView/list";
function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export function ExamApplicationManagerView() {
  const classes = useAppSelector((store) => store.tests.classes);
  const correctors = useAppSelector((store) => store.tests.correctors);
  const corrector_id = useAppSelector((store) => store.tests.corrector_id);

  const model = useAppSelector((store) => store.tests.model);
  const { search_student, loading_download, message_download,show_warning,loading_search } = useAppSelector(
    (store) => store.tests
  );
  const { loading_screen } = useAppSelector((store) => store.tests);
  const { showWarning } = useAppSelector((store) => store.tests);
  const { show_corrector_warning } = useAppSelector((store) => store.tests);
  console.log(loading_screen);
  const timeout_job = useRef<any>();

  const [activeTab, setActiveTab] = useState(0);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  let query :any= useQuery();

  const history: any = useNavigate();
  const params = useParams();

  const getDownload = (job_id = "") => {
    dispatch(fetchDownload({ layout_id: params.id, job_id: job_id })).then(
      (data: any) => {
        console.log(data);
        if (data.payload?.job) {
          let job: any = data.payload?.job;
          if (job.status == "Success") {
            dispatch(
              setState({
                loading_download: false,
                message_download: data.payload.message,
              })
            );
            let element: any = document!.getElementById("download_link");
            element!.href = STORAGE_URL + "/" + data.payload.job.path;
            element!.click();
            return;
          } else if (job.status == "Pending") {
            dispatch(setState({ message_download: data.payload.message }));
          } else if (job.status == "Execution") {
            dispatch(setState({ message_download: data.payload.message }));
          } else if (job.status == "Error") {
            dispatch(setState({ message_download: data.payload.message }));
          }
          timeout_job.current = setTimeout(() => {
            getDownload(job._id);
          }, 15000);
        } else {
          dispatch(setState({ loading_download: false, message_download: "" }));
        }
      }
    );
  };

  useEffect(() => {
    dispatch(setId(params.id));
    // dispatch(fetchLayoutCorrectors({ id: params.id, force: true }));
    dispatch(fetchId({ id: params.id, force: true }));
    dispatch(fetchIdRegistration({ id: params.id, force: true }));
    if(query.get("tab")){
      setActiveTab(parseInt(query.get("tab")));
    }
    return () => {
      clearTimeout(timeout_job.current);
    };
  }, []);
  return (
    <div style={{ position: "relative" }}>
      <div className="card">
        <div className="card-body">
          <Alert
            title="Atenção"
            text={
              "Ao desvincular este aluno, ele não terá mais acesso a esta prova"
            }
            show_warning={showWarning}
            onCancel={() => dispatch(setState({ showWarning: false }))}
            onConfirm={() =>
              dispatch(deleteClassItem()).then(() => {
                dispatch(fetchIdRegistration({ id: params.id, force: true }));
              })
            }
          ></Alert>
          <Alert
            title="Atenção"
            text={
              "Ao desvincular este corretor, todas as suas correções serão apagadas"
            }
            show_warning={show_corrector_warning}
            onCancel={() =>
              dispatch(setState({ show_corrector_warning: false }))
            }
            onConfirm={() => {
              console.log(params.id);
              dispatch(removeCorrector({ id: params.id })).then(() => {
                dispatch(setState({ showWarning: false }));
                dispatch(fetchId({ id: params.id, force: true }));
                dispatch(fetchLayoutCorrectors({ id: params.id, force: true }));
              });
            }}
          ></Alert>
          <div>
            <div style={{ float: "right" }}>
              <DefaultButton
                bg="secondary"
                text="Voltar"
                icon={<RiArrowGoBackLine />}
                to={`/tests`}
              />
            </div>
            <div className="flex jcsb">
              <h4 className="header">{model.name}</h4>
            </div>

            {model.description != undefined && (
              <TextReadMore
                title="Código: "
                element="div"
                limit={300}
                style={{ width: "100%", marginBottom: 10, whiteSpace: "pre" }}
              >
                {model.description ?? ""}
              </TextReadMore>
            )}
            <a id="download_link" target="_blank"></a>

            <Tabs
              value={activeTab}
              onChange={(_: any, value: any) => {
                setActiveTab(value);
                history({ search: `?tab=${value}` });
              }}
              style={{ backgroundColor: "#ececf1" }}
              indicatorColor="primary"
              textColor="primary"
              variant={"scrollable"}
              scrollButtons={"on"}
            >
              <Tab label="Alunos" />
              <Tab label="Corretores" />
              <Tab label="Provas" />
              <Tab label="Diagramações" />

            </Tabs>
            <TabPanel value={activeTab} index={0}>
              <Grid
                onChangeSearchInput={(value: any) => {
                  dispatch(setState({ search_student: value }));
                }}
                component={
                  <>
                   
                  </>
                }
                search_input_value={search_student}
                loading_search={false}
                fields={[
                  {
                    name: "student_name",
                    type: "text",
                    header: "Nome",
                  },
                  {
                    name: "student_email",
                    type: "text",
                    header: "E-mail",
                  },
                  {
                    name: "unit_name",
                    type: "text",
                    header: "Unidade",
                  },
                  {
                    name: "course_name",
                    type: "text",
                    header: "Curso",
                  },
                  {
                    name: "period",
                    type: "text",
                    header: "Período",
                  },
                ]}
                items={classes
                  .filter((item: any, id: any) =>
                    item.student.name
                      .toLowerCase()
                      .includes(search_student.toLowerCase())
                  )
                  ?.map((x: any) => {
                    return {
                      _id: x._id,
                      student_name: x.student?.name,
                      student_email: x.student?.email,
                      unit_name: x.class?.unit?.name,
                      course_name: x.class?.course?.name,
                      period: x.class?.period,
                    };
                  })}
                onManagerActionClick={(id) => {
                  // navigate(`/layouts_exams/view/${id}`);
                  console.log(id);
                  window.open(
                    `${window.location.origin}/tests/view/${params.id}/${id}`
                  );
                }}
                hideManager
                onDeleteActionClick={function (id: any): void {
                  console.log("delete clicked");
                  dispatch(setState({ class_id: id, showWarning: true }));
                }}
                hideEdit
                onEditActionClick={(id) => {
                  navigate(`/tests/show/${params.id}/${id}`);
                }}
                not_found_message={"Nenhum aluno encontrado."}
                loading_screen={loading_screen}
                button_text={"Adicionar alunos"}
                button_link={`/tests/students/${params.id}/create`}
                search_text={"Pesquisar pelo nome do aluno"}
                pagination={{
                  last_page: 1,
                  current_page: 1,
                }}
                onChangePagination={function (page: number): void {
                  // dispatch(setPage(page));
                }}
                top_text={`Aqui ficam os alunos vinculados a diagramação ${model.name}`}
                onSearchButtonClick={function (): void {
                  // dispatch(setPage(1));
                  // dispatch(fetchList({ id: params.id, force: true }));
                }}
              />
            </TabPanel>
            <TabPanel value={activeTab} index={1}>
              <Grid
                hideManager
                onChangeSearchInput={(value: any) => {
                  dispatch(setState({ search_student: value }));
                }}
                component={
                  <>

                    {loading_download == true && (
                      <div
                        style={{
                          flexBasis: "50%",
                          paddingLeft: 10,
                          paddingRight: 10,
                        }}
                      >
                        {" "}
                        <div className="col-12 d-flex justify-content-center align-items-center">
                          {/* <div className="spinner-border text-primary" role="status"  >
                                    </div> */}
                          <CircularProgress size={15} />
                        </div>
                        <div
                          className="col-12 d-flex justify-content-center align-items-center"
                          style={{ maxWidth: 160 }}
                        >
                          <p
                            style={{
                              marginBottom: 0,
                              textAlign: "center",
                              fontSize: 13,
                              color: "darkgoldenrod",
                            }}
                          >
                            {message_download}
                          </p>
                        </div>
                      </div>
                    )}
                  </>
                }
                search_input_value={search_student}
                loading_search={false}
                fields={[
                  {
                    name: "name",
                    type: "text",
                    header: "Nome",
                  },
                  {
                    name: "email",
                    type: "text",
                    header: "E-mail",
                  },
                ]}
                items={correctors}
                onManagerActionClick={(id) => {
                  // navigate(`/layouts_exams/view/${id}`);
                  console.log(id);
                  window.open(
                    `${window.location.origin}/tests/view/${params.id}/${id}`
                  );
                }}
                onDeleteActionClick={function (id: any): void {
                  console.log(`delete clicked -${id}-`);
                  dispatch(
                    setState({ corrector_id: id, show_corrector_warning: true })
                  );
                }}
                hideEdit
                onEditActionClick={(id) => {
                  navigate(`/tests/show/${params.id}/${id}`);
                }}
                not_found_message={"Nenhum corretor encontrado."}
                loading_screen={loading_screen}
                button_text={"Adicionar corretores"}
                button_link={`/tests/${params.id}/correctors`}
                search_text={"Pesquisar pelo nome do corretor"}
                pagination={{
                  last_page: 1,
                  current_page: 1,
                }}
                onChangePagination={function (page: number): void {
                  // dispatch(setPage(page));
                }}
                top_text={`Aqui ficam os corretores vinculados a diagramação ${model.name}`}
                onSearchButtonClick={function (): void {
                  // dispatch(setPage(1));
                  // dispatch(fetchList({ id: params.id, force: true }));
                }}
              />
            </TabPanel>

            <TabPanel value={activeTab} index={2}>
              <ExamsView inTest test_id={params.id} />
            </TabPanel>

            <TabPanel value={activeTab} index={3}>
              <LayoutsExamsView inTest test_id={params.id} />
            </TabPanel>
          </div>
        </div>
      </div>
    </div>
  );
}
