import { useEffect, useState } from "react";
// unidades
import { fetchList } from "../../../services/stores/examStore/async/fetchListThunk";
import { useAppSelector } from "../../../services/stores/hooks/useAppSelector";
import { useAppDispatch } from "../../../services/stores/hooks/useAppDispatch";
import { List } from "../../../component/list/list";
import { useNavigate } from "react-router-dom";
import {
  setId,
  setWarning,
  setSearch,
  setPage,
  setLoading,
} from "../../../services/stores/examStore/actions";
import { deleteItem } from "../../../services/stores/examStore/async/deleteThunk";
//
import { API_URI } from "../../../constants/consts";
//
export function ExamsView({inTest,test_id}:any) {
  const exams = useAppSelector((store) => store.exams.data);
  const { search, loading_screen,pagination } = useAppSelector((store) => store.exams);

  const show_warning = useAppSelector((store) => store.exams.show_warning);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // const [loading_screen, setLoadingScreen] = useState(false);
  const [loading_search, setLoadingSearch] = useState(false);
  const [last_page, setLastPage] = useState(0);
  const [current_page, setCurrentPage] = useState(0);

  const { token } = useAppSelector((store) => store.auth);

  console.log("exams", exams);

  useEffect(() => {
    dispatch(fetchList({ force: true,test_id })).then((result) => {
      console.log("result", result);
    });
  }, []);
  return (
    <div className="row">
      {/* {this.state.redirect == true && <Redirect to={this.state.path} />} */}

      <List
      hideEdit={inTest}
      hide_margin={inTest}
        showAuxiliar1={!inTest}
        onAuxiliar1ActionClick={(id) => {
          let anchor = document.createElement("a");
          document.body.appendChild(anchor);
          let file = `${API_URI}/layouts_exams/${id}/download-csv`;

          let headers = new Headers();
          headers.append("Authorization", `${token}`);
          fetch(file, { headers })
            .then((response) => response.blob())
            .then((blobby) => {
              let objectUrl = window.URL.createObjectURL(blobby);

              anchor.href = objectUrl;
              anchor.download = `${id}.csv`;
              anchor.click();
              window.URL.revokeObjectURL(objectUrl);
            });
        }}
        onChangeSearchInput={(value) => {
          dispatch(setSearch(value));
        }}
        search_input_value={search}
        title={"Provas"}
        pronoun="a"
        onManagerActionClick={(id: any) => {
          navigate("/exams/view/" + id);
        }}
        not_found_message={"Nenhuma prova encontrada"}
        button_text="Adicionar prova"
        button_link={inTest?`/tests/exams/${test_id}/create`: "/exams/create"}
        search_text="Pesquise pelo nome da prova"
        deleting_message={
          inTest?"Ao deletar esta prova, ela não estará mais disponível nessa aplicação": "Ao deletar esta prova, ela não estará mais disponível no sistema"
        }
        onDeleteCancel={() => {
          dispatch(setWarning(false));
        }}
        onSearchButtonClick={() => {
          dispatch(fetchList({ force: true,test_id }));
        }}
        onDeleteConfirm={() => {
          dispatch(deleteItem({test_id}));
          dispatch(setWarning(false));
        }}
        onDeleteActionClick={(id) => {
          dispatch(setId(id));
          dispatch(setWarning(true));
        }}
        onEditActionClick={(id) => {
          navigate("/exams/edit/" + id);
        }}
        onChangePagination={(value: number) => {
          dispatch(setPage(value));
          dispatch(setLoading(true));
          dispatch(fetchList({ force: true,test_id }));        
        }}
        show_warning={show_warning}
        loading_screen={loading_screen}
        loading_search={loading_search}
        items={exams}
        pagination={{
          last_page: pagination?.totalPages,
          current_page: pagination?.page,
        }}
        fields={[
          { name: "name", type: "text", header: "Nome" },
          { name: "owner.name", type: "text", header: "Cadastrado por" },
          { name: "created_at", type: "date", header: "Criado em" },
        ]}
      ></List>
    </div>
  );
}
