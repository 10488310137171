import { useEffect, useRef, useState } from "react";
import { connect, useSelector } from "react-redux";

// unidades
import { fetchList } from "../../../services/stores/disciplineStore/async/fetchListThunk";
import { useAppSelector } from "../../../services/stores/hooks/useAppSelector";
import { useAppDispatch } from "../../../services/stores/hooks/useAppDispatch";
import {
  Form,
  FormControl as Input,
  InputGroup,
  Button,
  Spinner,
} from "react-bootstrap";
import { Attention } from "../../../component/base/Attention";
import { Close, SaveRounded } from "@material-ui/icons";
import {
  DatePicker,
  TimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import { RiArrowGoBackLine } from "react-icons/ri";
import DefaultButton from "../../../component/base/DefaultButton";
import { save } from "../../../services/stores/testStore/async/saveThunk";
import { useNavigate, useParams } from "react-router-dom";
import {
  setLoading,
  setModel,
  setErrorMessage,
  clearState,
  setId,
  setLoadingScreen,
  setState,
} from "../../../services/stores/testStore/actions";
import { setState as setStateQuestion } from "../../../services/stores/questionStore/actions";

import { update } from "../../../services/stores/testStore/async/updateThunk";
import { fetchId } from "../../../services/stores/testStore/async/fetchIdThunk";
import { TableDrag } from "../TableDrag";
import { QuestionModalView } from "../../QuestionModalView";
import { ExamModalTag } from "../../ExamModalTag";

export function ExamsApplicationCreate() {
  const {
    error_message,
    model,
    loading_save,
    loading_screen,
    questionsArray,
    loading_input,
    disciplineSelect,
    questionContent,
    questionsSearch,
    showAutoCompleteQuestion,
    loading_search,
    showModalTag,
    questions,
  } = useAppSelector((store) => store.tests);
  const dispatch = useAppDispatch();
  const [show_warning, setShowWarning] = useState(false);
  // const [loading_screen, setLoadingScreen] = useState(false);
  const timeout_: any = useRef();
  const timeout_search: any = useRef();

  const [search, setSearch] = useState("");
  const [edit, setEdit] = useState(false);

  const history = useNavigate();
  const params = useParams();

  const saveContent = () => {
    let model_: any = { ...model };
    model_.questions = questionsArray.map((item: any, id) => item._id);
    console.log("entrou");
    dispatch(setModel(model_));
    if (edit == true) {
      dispatch(update()).then((result) => {
        console.log(result);
        if (result?.payload?.status == "success") {
          history("/tests");
        }
      });
    } else {
      let data_inicio = model.init_test.format('YYYY-MM-DD')+' '+model.time_init_test.format('HH:mm:ss');
      let data_encerramento = model.end_test.format('YYYY-MM-DD')+' '+model.time_end_test.format('HH:mm:ss');
      let data_encerramento_correcao = model.end_test_correction.format('YYYY-MM-DD')+' '+model.time_end_test_correction.format('HH:mm:ss');


      if(model.name.trim().length==0){
        dispatch(setErrorMessage(['O campo Nome é obrigatório']));
        return;
      }
      else if(model.description.trim().length==0){
        dispatch(setErrorMessage(['O campo Descrição é obrigatório']));
        return;
      }
      else if(data_inicio>=data_encerramento){
        dispatch(setErrorMessage(['A data e hora de início deve ser menor que a data e hora de encerramento da aplicação']));
        return;
      }
      else if(data_encerramento_correcao<=data_encerramento){
        dispatch(setErrorMessage(['A data e hora final para entrega das notas deve ser maior que a data e hora de encerramento da aplicação']));
        return;
      }

      dispatch(save()).then((result) => {
        console.log(result);
        if (result?.payload?.status == "success") {
          history("/tests");
        }
      });
    }
  };

  useEffect(() => {
    // const exam_id = history.pa
    // dispatch(setLoadingScreen(true));
    // dispatch(fetchList({ force: true })).then((data) => {
    // console.log(data);
    // if (params.id == undefined) {
    //   dispatch(setLoadingScreen(false));
    // }
    // });
    if (params.id != undefined) {
      dispatch(setLoadingScreen(true));
      dispatch(setId(params.id));
      setEdit(true);
      dispatch(fetchId({ force: true }));
    }

    return () => {
      dispatch(clearState());
    };
  }, []);

  const parseQuestoesDragDrop = () => {
    let questoes: any = [...questionsArray];
    let ids = questoes.map((item: any, id: any) => item._id);
    let ids_exists: any = [];

    for (let i = 0; i < Object.values(questions).length; i++) {
      let item: any = Object.values(questions)[i];
      ids_exists.push(item._id);

      if (!ids.includes(item._id)) {
        questoes.push({
          numero: i + 1,
          _id: item._id,
          id: item._id,
          index: i,
          enunciado: <p className="text_overflow">{item.question_content}</p>,
          categoria: item.discipline.name,
          tipo_questao: (
            <p style={{ whiteSpace: "pre" }}>
              {item.type == "set"
                ? "Conjunto de questões\n" +
                item.questions.length +
                (item.questions.length == 1 ? " questão" : " questões")
                : item.type == "objective"
                  ? "Questão única"
                  : "Questão dissertativa"}
            </p>
          ),
          acoes: i,
        });
      }
    }
    questoes = questoes.filter((item: any, id: any) => {
      if (ids_exists.includes(item._id)) {
        return true;
      }
      return false;
    });

    dispatch(setState({ questionsArray: questoes }));
  };

  useEffect(() => {
    parseQuestoesDragDrop();
  }, [questions]);
  console.log(error_message);
  let error_message_:any=error_message;
  return (
    <div className="row">
      <div className="col-12">
        <div className="py-5 px-3">
          <div className="card-body" id="card" style={{ overflow: "visible" }}>
            <h4
              className="mt-0 header-title"
              style={{ fontSize: "1.5rem", color: "black" }}
            >
              {edit == true ? "Editar" : "Cadastrar"} Aplicação
            </h4>
            <p style={{ fontSize: "1rem", color: "black" }}>
              {edit == false
                ? "Cadastre as aplicações do seu sistema"
                : "Edite uma aplicação do seu sistema"}
            </p>
            <br />

            {/* <hr /> */}
            {loading_screen == false && (
              <div>
                <QuestionModalView />
                <ExamModalTag />

                {error_message_ != null && (
                  <div
                    style={{ display: "flex", marginBottom: 15, width: "100%" }}
                  >
                    <div
                      className="alert alert-danger alert-dismissible fade show mb-0 w-100"
                      role="alert"
                    >
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="alert"
                        aria-label="Close"
                        onClick={() => dispatch(setErrorMessage(null))}
                      ></button>

                      <p style={{ marginBottom: 0, whiteSpace: "pre-line" }}>
                        {Array.isArray(error_message_)? error_message_!.join('\n'):error_message_}
                      </p>
                    </div>
                    <br />
                  </div>
                )}

                <Form
                  onSubmit={(e) => {
                    e.preventDefault();
                    saveContent();
                  }}
                  style={{ position: "relative" }}
                >
                  <InputGroup>
                    <div className="flex fdcolumn flex1 margin-bottom">
                      <Form.Label>
                        Nome <Attention />
                      </Form.Label>
                      <Input
                        placeholder="Nome da aplicação"
                        value={model.name}
                        onChange={(e) => {
                          let model_ = { ...model };
                          model_.name = e.target.value;
                          dispatch(setModel(model_));
                        }}
                        required
                      // title="Coloque o nome completo do usuário"
                      />
                    </div>
                  </InputGroup>

                  <InputGroup>
                    <div className="flex fdcolumn flex1 margin-bottom">
                      <Form.Label>Descrição <Attention /></Form.Label>
                      <Input
                        placeholder="Descrição da aplicação (O que é, regras, etc)"
                        value={model.description}
                        required

                        onChange={(e) => {
                          let model_ = { ...model };
                          model_.description = e.target.value;
                          dispatch(setModel(model_));
                        }}
                        as="textarea"
                      // title="Coloque o nome completo do usuário"
                      />
                    </div>
                  </InputGroup>


                  <InputGroup>
                    <div className="flex fdcolumn flex1 margin-bottom">
                      <Form.Label>
                        Tipo de aplicação <Attention />
                      </Form.Label>

                      <select
                        disabled={loading_input}
                        className="form-select "
                        onChange={(e) => {
                          let model_ = { ...model };
                          model_.type = e.target.value;
                          // model_.questions = [];
                          dispatch(
                            setState({
                              model: model_,
                            })
                          );
                        }}
                        value={model.type}
                      >
                        <option value="not_online">Prova Presencial</option>
                        <option value="online">Prova Online</option>
                      </select>
                    </div>
                  </InputGroup>


                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <InputGroup>
                      <div className="flex fdcolumn flex1 margin-bottom me-3">
                        <Form.Label>
                          Data de início <Attention />
                        </Form.Label>
                        <DatePicker
                          autoOk
                          margin="normal"
                          style={{ width: "100%" }}
                          invalidDateMessage="Data inválida"
                          minDate={moment()}
                          id="time-picker"
                          cancelLabel="Cancelar"
                          clearLabel="Limpar"
                          format="DD/MM/YYYY"
                          value={model.init_test}
                          onChange={(date) => {
                            let model_:any = { ...model };
                              model_.init_test = date;
                              // model_.questions = [];
                              dispatch(
                                setState({
                                  model: model_,
                                })
                              );
                          }}
                        />
                      </div>
                      <div className="flex fdcolumn flex1 margin-bottom">
                        <Form.Label>
                          Horário de início <Attention />
                        </Form.Label>
                        <TimePicker
                          autoOk
                          margin="normal"
                          style={{ width: "100%" }}
                          invalidDateMessage="Horário inválido"
                          ampm={false}
                          id="time-picker"
                          cancelLabel="Cancelar"
                          clearLabel="Limpar"
                          label=""
                          value={model.time_init_test}
                          onChange={(date) => {
                            let model_:any = { ...model };
                              model_.time_init_test = date;
                            // model_.questions = [];
                              dispatch(
                                setState({
                                  model: model_,
                                })
                              );
                          }}
                        />
                      </div>
                    </InputGroup>
                  </MuiPickersUtilsProvider>


                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <InputGroup>
                      <div className="flex fdcolumn flex1 margin-bottom me-3">
                        <Form.Label>
                          Data de encerramento <Attention />
                        </Form.Label>
                        <DatePicker
                          autoOk
                          margin="normal"
                          style={{ width: "100%" }}
                          invalidDateMessage="Data inválida"
                          minDate={moment()}
                          id="time-picker"
                          cancelLabel="Cancelar"
                          clearLabel="Limpar"
                          format="DD/MM/YYYY"
                          value={model.end_test}
                          onChange={(date) => {
                            let model_:any = { ...model };
                              model_.end_test = date;
                              // model_.questions = [];
                              dispatch(
                                setState({
                                  model: model_,
                                })
                              );
                          }}
                        />
                      </div>
                      <div className="flex fdcolumn flex1 margin-bottom">
                        <Form.Label>
                          Horário de encerramento <Attention />
                        </Form.Label>
                        <TimePicker
                          autoOk
                          margin="normal"
                          style={{ width: "100%" }}
                          invalidDateMessage="Horário inválido"
                          ampm={false}
                          id="time-picker"
                          cancelLabel="Cancelar"
                          clearLabel="Limpar"
                          label=""
                          value={model.time_end_test}
                          onChange={(date) => {
                            let model_:any = { ...model };
                              model_.time_end_test = date;
                            // model_.questions = [];
                              dispatch(
                                setState({
                                  model: model_,
                                })
                              );
                          }}
                        />
                      </div>
                    </InputGroup>
                  </MuiPickersUtilsProvider>


                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <InputGroup>
                      <div className="flex fdcolumn flex1 margin-bottom me-3">
                        <Form.Label>
                          Data final para entrega das notas <Attention />
                        </Form.Label>
                        <DatePicker
                          autoOk
                          margin="normal"
                          style={{ width: "100%" }}
                          invalidDateMessage="Data inválida"
                          minDate={moment()}
                          id="time-picker"
                          cancelLabel="Cancelar"
                          clearLabel="Limpar"
                          format="DD/MM/YYYY"
                          value={model.end_test_correction}
                          onChange={(date) => {
                            let model_:any = { ...model };
                              model_.end_test_correction = date;
                              // model_.questions = [];
                              dispatch(
                                setState({
                                  model: model_,
                                })
                              );
                          }}
                        />
                      </div>
                      <div className="flex fdcolumn flex1 margin-bottom">
                        <Form.Label>
                          Horário final para entrega das notas <Attention />
                        </Form.Label>
                        <TimePicker
                          autoOk
                          margin="normal"
                          style={{ width: "100%" }}
                          invalidDateMessage="Horário inválido"
                          ampm={false}
                          id="time-picker"
                          cancelLabel="Cancelar"
                          clearLabel="Limpar"
                          label=""
                          value={model.time_end_test_correction}
                          onChange={(date) => {
                            let model_:any = { ...model };
                              model_.time_end_test_correction = date;
                            // model_.questions = [];
                              dispatch(
                                setState({
                                  model: model_,
                                })
                              );
                          }}
                        />
                      </div>
                    </InputGroup>
                  </MuiPickersUtilsProvider>





                  {loading_save == false && (
                    <div className="w100 inline-flex jcsb mt-3">
                      {/* <DefaultButton
                        bg="secondary"
                        text="Voltar"
                        icon={<RiArrowGoBackLine />}
                        to="/exams"
                      /> */}
                      <Button
                        variant="secondary"
                        className="flex align-center jcc"
                        onClick={() => history("/tests")}
                      >
                        <RiArrowGoBackLine style={{ marginRight: 10 }} /> Voltar
                      </Button>
                      {/* <Button variant="success" type="submit" disabled={this.loading}>
                        {this.state.loading ? <Spinner /> : ""}
                        Registrar usuário
                    </Button> */}
                      {/* <DefaultButton
                        type="submit"
                        bg="confirm"
                        text="Salvar prova"
                        disabled={loading_save}
                        loadingtext="Salvando prova"
                        loading={loading_save}
                      /> */}
                      <Button
                        variant="success"
                        type="submit"
                        disabled={loading_save}
                        className="flex align-center jcc"
                      >
                        <SaveRounded style={{ marginRight: 10 }} /> Salvar
                      </Button>
                    </div>
                  )}

                  {loading_save == true && (
                    <div className="row">
                      {" "}
                      <div className="col-12 d-flex justify-content-center align-items-center margin-top-1">
                        <div
                          className="spinner-border text-primary"
                          role="status"
                        ></div>
                      </div>
                    </div>
                  )}
                </Form>
              </div>
            )}

            {loading_screen == true && (
              <div className="row">
                {" "}
                <div
                  className="col-12 d-flex justify-content-center align-items-center"
                  style={{ marginTop: "2rem" }}
                >
                  <div
                    className="spinner-border text-primary"
                    role="status"
                  ></div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
