
import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { LayoutsExamsAPI } from "../../../apis/LayoutsExamsAPI";
import { clearErrorState, handleThunkRejection } from "../../utils";

export const fetchDefaultList = createAsyncThunk(
  "layouts/get_default_list",
  async (params: any = { force: false }, { getState, rejectWithValue }) => {
    const state: any = getState();
    console.log('state',state);
    const { token } = state.auth;
    if (!params.force && state.layouts.data != null && state.layouts.data.length > 0)
      return state.layouts.data;
    try {
      return await LayoutsExamsAPI.getDefaultLayout(
        token,
        state.layouts.pagination.page,
        state.layouts.search,
        false
      );
    } catch (e: any) {
      console.log(`Error fetchList::createAsyncThunk ${e.status} ${e.message}`);
      return handleThunkRejection(e, rejectWithValue);
    }
  }
);

export const addFetchDefaultListReducers = (
  builder: ActionReducerMapBuilder<any>
) => {
  builder
    .addCase(fetchDefaultList.pending, (state: { loading_screen: boolean }, _action: any) => {
      clearErrorState(state);
      state.loading_screen = true;
    })
    .addCase(
      fetchDefaultList.fulfilled,
      (state: { data: any; loading_screen: boolean }, action: { payload: any }) => {
        clearErrorState(state);
        state.data = action.payload;
        state.loading_screen = false;
      }
    )
    .addCase(
      fetchDefaultList.rejected,
      (
        state: any,
        action: { payload: any }
      ) => {
        console.log(state)
        state.data = [];
        const error = action.payload.error;
        console.log(error);
        state.error_code = error?.code;
        state.loading_screen = false;
      }
    );
};
