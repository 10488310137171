import moment from 'moment';
import { paginationState } from '../../../constants/consts';

export const modelInitialState = {
    redirect: false,
    path: "/tests",
    loading_screen: false,
    data: [],
    pagination: paginationState,

    testSelect:{id:''},
    test_id:null,
    msgErro: "",
    show_warning: false,
    show_erro: false,
    search: "",
    loading_search: false,
    showModal: false,
    loading_input:false,
    showAutoCompleteQuestion:false,
    showModalTag:false,
    disciplineSelect:{_id:'',name:''},
    tagSelect:'',
    questionsArrayTag:[],
    questionsTag:{},
    questionContent:'',
    questionsSearch:[],
    questionsArray:[],
    questions:{},
    loading_student:false,
    students: {},
    classes: [],
    unit: {},
    unit_text: "",
    loading_download: false,
  message_download: "",
  showWarning: false,
  show_corrector_warning:false,
    classSelected: {},
    
    class_text: "",
    course_text: "",
    course: {},
    courses: [],
    units: [],
    class_id: "",
    search_student: "",
    correctors: [],
    corrector_id:null,

    model:{
      name:'',
      description:'',
      init_test:moment().add(10,'hours'),
      end_test:moment().add(16,'hours'),
      time_init_test:moment().add(10,'hours'),
      time_end_test:moment().add(16,'hours'),

      end_test_correction:moment().add(20,'hours'),
      time_end_test_correction:moment().add(20,'hours'),
      type:'not_online'
    },
    showAutoCompleteTag:false,
    tagsSearch:[],
    loading_search_tag:false,
    jobs:[],
    search_form:'',
    file:'',
    fileValue:'',
    search_files:'',
    loading_zip:false,
    jobId:'',
    progress:0,
    messageProgress:'',
    loading_save:false,
    error_message: null,
    loading_job:false,
    loading_files:false,
    files:[],
    search_exam:'',
    examsSelecteds:{},
    examSelected:{},
    exam_text:''

};
