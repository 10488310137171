import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { addDeleteItemReducers } from "./async/deleteThunk";
import { addSaveReducers } from "./async/saveThunk";
import { addFetchListReducers } from "./async/fetchListThunk";
import { addUpdateReducers } from "./async/updateThunk";

import { modelInitialState } from "./initialState";
import { setChildVisible } from "./sync/setChildVisible";
import moment from "moment";
import { addFetchIdReducers } from "./async/fetchIdThunk";
import { addSaveZipReducers } from "./async/saveZipThunk";
import { addFetchJobIdReducers } from "./async/fetchJobThunk";
import { paginationReducers } from "../../../constants/consts";
import { addUpdateClassesReducers } from "./async/updateClassesThunk";
import { addDeleteClassItemReducers } from "./async/deleteClassThunk";
import { addFetchStudent } from "./async/fetchStudentThunk";
import { addFetchIdRegistrationReducers } from "./async/fetchRegistrationsIdThunk";
import { addFetchListExamReducers } from "./async/fetchListExamThunk";

export const modelSlice = createSlice({
  name: "testsList",
  initialState: modelInitialState,
  reducers: {
    setChildVisible: (state, action) => setChildVisible(state, action),
    setModel: (state, action: PayloadAction<any>) => {
      return { ...state, model: action.payload };
    },
    setState: (state, action: PayloadAction<any>) => {
      return { ...state, ...action.payload };
    },
    setLoadingInput: (state, action: PayloadAction<boolean>) => {
      return { ...state, loading_input: action.payload };
    },
    setPage: paginationReducers.setPage,
    setLoadingScreen: (state, action: PayloadAction<boolean>) => {
      return { ...state, loading_screen: action.payload };
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      return { ...state, loading_save: action.payload };
    },
    setSearch: (state, action: PayloadAction<any>) => {
      return { ...state, search: action.payload };
    },
    setErrorMessage: (state, action: PayloadAction<any>) => {
      return { ...state, error_message: action.payload };
    },
    setId: (state, action: PayloadAction<any>) => {
      return { ...state, test_id: action.payload };
    },
    setWarning: (state, action: PayloadAction<any>) => {
      return { ...state, show_warning: action.payload };
    },
    clearState: (state) => {
      return {
       ...modelInitialState
      };
    },
  },
  extraReducers: (builder) => {
    addFetchListReducers(builder);
    addFetchIdReducers(builder);
    addDeleteItemReducers(builder);
    addSaveReducers(builder);
    addUpdateReducers(builder);
    addSaveZipReducers(builder);
    addFetchJobIdReducers(builder);
    addUpdateClassesReducers(builder);
    addDeleteClassItemReducers(builder);
    addFetchStudent(builder);
    addFetchIdRegistrationReducers(builder);
    addFetchListExamReducers(builder);
  },
});
