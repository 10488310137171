import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Router,
  RouterProvider,
  Routes,
  createBrowserRouter,
} from "react-router-dom";
import { useAppSelector } from "../services/stores/hooks/useAppSelector";
import AppStack from "./AppStack";
import AuthStack from "./AuthStack";
import ErrorPage from "../component/views/ErrorPage";
import Blank from "../screens/Blank";
import EditProfile from "../screens/EditProfile";
import { QuestionsView } from "../screens/QuestionsView";
import { DemandsView } from "../screens/DemandsView/list";
import { DisciplinesView } from "../screens/DisciplinesView";
import { DemandsCreate } from "../screens/DemandsView/form";
import { DemandsManagerView } from "../screens/DemandsView/manager";
import { OrganizationView } from "../screens/OrganizationsView/list";
import { OrganizationForm } from "../screens/OrganizationsView/form";
import { UsersAdminView } from "../screens/UsersAdminView/list";
import { UsersColaboratorView } from "../screens/UsersColaboratorView/list";
import { UsersTeacherView } from "../screens/UsersTeacherView/list";
import { QuestionsCreate } from "../screens/QuestionsCreate";
import { DisciplinesCreate } from "../screens/DisciplinesCreate";
import { UsersAdminForm } from "../screens/UsersAdminView/form";
import { UsersColaboratorForm } from "../screens/UsersColaboratorView/form";
import { UsersTeacherForm } from "../screens/UsersTeacherView/form";
import { ExamsView } from "../screens/ExamsView/list";
import { ExamsCreate } from "../screens/ExamsView/form";
import { DemandsManagerForm } from "../screens/DemandsView/managerForm";
import { TeacherDemandsView } from "../screens/TeacherDemandsView/list";
import { TeacherDemandsManagerView } from "../screens/TeacherDemandsView/manager";
import { TeacherDemandsManagerQuestions } from "../screens/TeacherDemandsView/managerQuestions";
import { LayoutsExamsView } from "../screens/LayoutsExamsView/list";
import { LayoutsExamsForm } from "../screens/LayoutsExamsView/form";
import { DashboardView } from "../screens/DashboardView/list";
import { UsersStudentView } from "../screens/UsersStudentView/list";
import { UsersStudentForm } from "../screens/UsersStudentView/form";
import { StudentExamView } from "../screens/StudentExamsView/list";
import { StudentExamManager } from "../screens/StudentExamsView/manager";
import { UnitView } from "../screens/UnitsView/list";
import { UnitForm } from "../screens/UnitsView/form";
import { CourseView } from "../screens/CourseView/list";
import { CourseForm } from "../screens/CourseView/form";
import { ClassView } from "../screens/ClassView/list";
import { ClassForm } from "../screens/ClassView/form";
import { LayoutManagerView } from "../screens/LayoutsExamsView/manager";
import { LayoutManagerForm } from "../screens/LayoutsExamsView/managerForm";
import { ExamManagerView } from "../screens/ExamsView/manager";
import { CorrectionExamsList } from "../screens/CorrectionView/CorrectionExamsList";
import { CorrectionExamStudentsList } from "../screens/CorrectionView/CorrectionExamStudentsList";
import { CorrectionExamStudentQuestionsList } from "../screens/CorrectionView/CorrectionExamStudentQuestionsList";
import { CorrectionForm } from "../screens/CorrectionView/CorrectionForm";
import FirstLoginStack from "./FirstLoginStack";
import { LogView } from "../screens/LogView/list";
import { RegistrationView } from "../screens/RegistrationView/list";
import { RegistrationForm } from "../screens/RegistrationView/form";
import { UserRole } from "../constants/enum/UserRole";
import { useEffect } from "react";
import { STUDENT_REDIRECT } from "../constants/consts";
import { authSlice } from "../services/stores/auth/slice";
import { useAppDispatch } from "../services/stores/hooks/useAppDispatch";
import { LayoutsExamsDefaultView } from "../screens/LayoutsExamsView/list_default";
import { LayoutCorrectorManagerForm } from "../screens/LayoutsExamsView/correctors";
import { UploadExamView } from "../screens/UploadExamView";
import { ExamsApplicationView } from "../screens/ExamApplicationView/list";
import { ExamsApplicationCreate } from "../screens/ExamApplicationView/form";
import { ExamApplicationManagerView } from "../screens/ExamApplicationView/manager";
import { ExamApplicationManagerForm } from "../screens/ExamApplicationView/managerForm";
import { ExamApplicationManagerExamForm } from "../screens/ExamApplicationView/managerExamForm";

export const { logout } = authSlice.actions;

export function RedirectPage() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    // alert(STUDENT_REDIRECT as string)

    setTimeout(() => {
      window.location.replace(STUDENT_REDIRECT as string);
      return dispatch(logout(""));
    }, 5000);
  }, []);
  return (
    <p
      style={{
        margin: "0px 0px 0.35em",
        fontFamily: "Roboto, Helvetica, Arial, sans-serif",
        fontSize: "2.125rem",
        lineHeight: 1.235,
        letterSpacing: "0.00735em",
        fontWeight: 700,
        textAlign: "center",
      }}
    >
      <br />
      <br />
      Essa plataforma é para uso exclusivo de professores e colaboradores da
      instituição.
      <br />
      <br />
      Você será redirecionado para a área do aluno.
    </p>
  );
}

export default function AppNav() {
  const { logged, blank, status, me } = useAppSelector((store) => store.auth);
  function Root() {
    return blank == true ? (
      <>
        <Outlet />
      </>
    ) : logged == undefined ? (
      <></>
    ) : logged == true ? (
      status == "CREATED" || !status ? (
        <FirstLoginStack />
      ) : me.role == UserRole.STUDENT_USER ? (
        <RedirectPage />
      ) : (
        <AppStack />
      )
    ) : (
      <AuthStack />
    );
  }

  const router = createBrowserRouter([
    {
      path: "*",
      element: <Root />,
      errorElement: <ErrorPage />,
      children:
        blank == false
          ? [
              {
                path: "",
                element: <DashboardView />,
              },
              {
                path: "blank",
                element: <Blank />,
              },
              {
                path: "edit-profile",
                element: <EditProfile />,
              },
              {
                path: "questions",
                element: <QuestionsView />,
              },
              {
                path: "questions/create",
                element: <QuestionsCreate />,
              },
              {
                path: "questions/edit/:id",
                element: <QuestionsCreate editMode />,
              },
              {
                path: "demands",
                element: <DemandsView />,
              },
              {
                path: "demands/create",
                element: <DemandsCreate />,
              },
              {
                path: "demands/edit/:id",
                element: <DemandsCreate editMode />,
              },
              {
                path: "demands/:id",
                element: <DemandsManagerView />,
              },
              {
                path: "demands/:id/create",
                element: <DemandsManagerForm />,
              },
              {
                path: "demands/:id/edit/",
                element: <DemandsManagerForm editMode />,
              },
              {
                path: "demands/questions/:id",
                element: <TeacherDemandsManagerQuestions withAdmin />,
              },
              {
                path: "disciplines",
                element: <DisciplinesView />,
              },
              {
                path: "disciplines/create",
                element: <DisciplinesCreate />,
              },
              {
                path: "disciplines/edit/:id",
                element: <DisciplinesCreate />,
              },
              {
                path: "organizations",
                element: <OrganizationView />,
              },
              {
                path: "organizations/create",
                element: <OrganizationForm />,
              },
              {
                path: "organizations/edit/:id",
                element: <OrganizationForm editMode />,
              },
              {
                path: "users-admin",
                element: <UsersAdminView />,
              },
              {
                path: "users-admin/create",
                element: <UsersAdminForm />,
              },
              {
                path: "users-admin/edit/:id",
                element: <UsersAdminForm editMode />,
              },
              {
                path: "users-colaborator",
                element: <UsersColaboratorView />,
              },
              {
                path: "users-colaborator/create",
                element: <UsersColaboratorForm />,
              },
              {
                path: "users-colaborator/edit/:id",
                element: <UsersColaboratorForm editMode />,
              },
              {
                path: "users-teacher",
                element: <UsersTeacherView />,
              },
              {
                path: "users-teacher/create",
                element: <UsersTeacherForm />,
              },
              {
                path: "users-teacher/edit/:id",
                element: <UsersTeacherForm editMode />,
              },
              {
                path: "users-student",
                element: <UsersStudentView />,
              },
              {
                path: "users-student/create",
                element: <UsersStudentForm />,
              },
              {
                path: "users-student/edit/:id",
                element: <UsersStudentForm editMode />,
              },
              {
                path: "exams",
                element: <ExamsView />,
              },
              {
                path: "exams/create",
                element: <ExamsCreate />,
              },
              {
                path: "exams/edit/:id",
                element: <ExamsCreate />,
              },
              {
                path: "exams/view/:id",
                element: <ExamManagerView />,
              },
              {
                path: "tests",
                element: <ExamsApplicationView />,
              },
              {
                path: "tests/create",
                element: <ExamsApplicationCreate />,
              },
              {
                path: "tests/edit/:id",
                element: <ExamsApplicationCreate />,
              },
              {
                path: "tests/view/:id",
                element: <ExamApplicationManagerView />,
              },
              {
                path: "tests/students/:id/create",
                element: <ExamApplicationManagerForm />,
              },
              {
                path: "tests/exams/:id/create",
                element: <ExamApplicationManagerExamForm />,
              },
              {
                path: "teacher-demands",
                element: <TeacherDemandsView />,
              },
              {
                path: "teacher-demands-manager/:id",
                element: <TeacherDemandsManagerView />,
              },
              {
                path: "teacher-demands-manager-questions/:id",
                element: <TeacherDemandsManagerQuestions />,
              },
              {
                path: "teacher-demands-manager-questions/:parentId/create",
                element: <QuestionsCreate withAssignment />,
              },
              {
                path: "layouts_exams",
                element: <LayoutsExamsView />,
              },
              {
                path: "layouts_default_exams",
                element: <LayoutsExamsDefaultView />,
              },
              {
                path: "layouts_exams/create",
                element: <LayoutsExamsForm />,
              },
              {
                path: "layouts_exams/create/:test_id",
                element: <LayoutsExamsForm />,
              },
              {
                path: "layouts_exams/create/:test_id/:default_id",
                element: <LayoutsExamsForm />,
              },
              {
                path: "layouts_exams/edit/:id",
                element: <LayoutsExamsForm />,
              },
              {
                path: "layouts_exams/view/:id",
                element: <LayoutsExamsForm justView />,
              },
              {
                path: "layouts_exams/show/:id",
                element: <LayoutManagerView />,
              },
              {
                path: "layouts_exams/:id/correctors",
                element: <LayoutCorrectorManagerForm />,
              },
              {
                path: "layouts_exams/students/:id/create",
                element: <LayoutManagerForm />,
              },
              {
                path: "teacher-demands-manager-questions/:parentId/edit/:id",
                element: <QuestionsCreate withAssignment editMode />,
              },
              {
                path: "student-exams/:id",
                element: <StudentExamView />,
              },
              {
                path: "student-exams-manager/:sid/:id",
                element: <StudentExamManager />,
              },
              {
                path: "registrations",
                element: <RegistrationView />,
              },
              {
                path: "registrations/create",
                element: <RegistrationForm />,
              },
              {
                path: "registrations/edit/:id",
                element: <RegistrationForm editMode />,
              },
              {
                path: "units",
                element: <UnitView />,
              },
              {
                path: "units/create",
                element: <UnitForm />,
              },
              {
                path: "units/edit/:id",
                element: <UnitForm editMode />,
              },
              {
                path: "units/:id/turmas",
                element: <ClassView />,
              },
              {
                path: "courses",
                element: <CourseView />,
              },
              {
                path: "courses/create",
                element: <CourseForm />,
              },
              {
                path: "courses/edit/:id",
                element: <CourseForm editMode />,
              },
              {
                path: "courses/:id/turmas",
                element: <ClassView />,
              },
              {
                path: "turmas",
                element: <ClassView />,
              },
              {
                path: "turmas/create",
                element: <ClassForm />,
              },
              {
                path: "turmas/edit/:id",
                element: <ClassForm editMode />,
              },
              {
                path: "turmas/:id/registrations",
                element: <RegistrationView title="Matrículas da Turma" />,
              },
              {
                path: "correction-exams",
                element: <CorrectionExamsList />,
              },
              {
                path: "correction-exams/:id",
                element: <CorrectionExamStudentsList />,
              },
              {
                path: "correction-exams/:id/student/:idS",
                element: <CorrectionExamStudentQuestionsList />,
              },
              {
                path: "corrections/edit/:id",
                element: <CorrectionForm editMode />,
              },
              {
                path: "logs/",
                element: <LogView />,
              },
              {
                path: "upload-exam",
                element: <UploadExamView />,
              },
            ]
          : [],
    },
  ]);

  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route
            path="layouts_exams/view/:id/:class_id"
            element={<LayoutsExamsForm justView />}
          />
        </Routes>
      </BrowserRouter>
      <RouterProvider router={router} />
    </div>
  );
}
