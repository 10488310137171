
import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { ExamsAPI } from "../../../apis/ExamsAPI";
import { clearErrorState, handleThunkRejection } from "../../utils";

export const fetchList = createAsyncThunk(
  "exams/list",
  async (params: any = { force: false,test_id:undefined,all:false,search:'' }, { getState, rejectWithValue }) => {
    const state: any = getState();
    console.log('state',state);
    const { token } = state.auth;
    if (!params.force && state.exams.data != null && state.exams.data.length > 0)
      return state.exams.data;
    try {
      return await ExamsAPI.getAll(
        token,
        state.exams.pagination.page,
        params.search?params.search: state.exams.search,
        params.test_id?params.test_id:0,
        params.all?params.all:0,
        false
      );
    } catch (e: any) {
      console.log(`Error fetchList::createAsyncThunk ${e.status} ${e.message}`);
      return handleThunkRejection(e, rejectWithValue);
    }
  }
);

export const addFetchListReducers = (
  builder: ActionReducerMapBuilder<any>
) => {
  builder
    .addCase(fetchList.pending, (state: { loading_screen: boolean }, _action: any) => {
      clearErrorState(state);
      state.loading_screen = true;
    })
    .addCase(
      fetchList.fulfilled,
      (state: { data: any; loading_screen: boolean,pagination:any,examsSelecteds:any }, action: { payload: any }) => {
        clearErrorState(state);
        state.data = action.payload.docs;
        state.loading_screen = false;
        Object.keys(action.payload)
        .filter((key) => key in state.pagination)
        .forEach((key) => {
          state.pagination[key] = action.payload[key];
        });
      }
    )
    .addCase(
      fetchList.rejected,
      (
        state: any,
        action: { payload: any }
      ) => {
        console.log(state)
        state.data = [];
        const error = action.payload.error;
        console.log(error);
        state.error_code = error?.code;
        state.loading_screen = false;
      }
    );
};
